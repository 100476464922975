import React, { useEffect, useState } from 'react'
import { default as CustomerDetails } from './check-eligibility/customer-details'
import { default as MoreCustomerDetails } from './check-eligibility/more-customer-details'
import { default as Eligible } from './check-eligibility/eligible'
import { default as NotEligible } from './check-eligibility/not-eligible'
import { CommonLoader, CommonError } from '../..'
import { useSearchParams, useNavigate } from 'react-router-dom'
import application from '../../../apis/application'
import Constants from '../../../config/constants'

const Egilibility = (props) => {
    const [currentView, changeCurrentView] = useState({ view: 9, applicationNumber: null })
    const [searchParams] = useSearchParams()
    let [error, setError] = useState(null)
    const navigate = useNavigate()

    function changeView(view, applicationNumber) {
        if (view === 0) changeCurrentView({ view: 0, applicationNumber: null })
        else if (view === 8) {
            navigate("/agent/transactions")
        } else if (applicationNumber) {
            changeCurrentView({ view, applicationNumber })
        } else {
            changeCurrentView({ view, applicationNumber: currentView.applicationNumber })
        }
    }

    useEffect(() => {
        const continueApplication = async function (data, existingApplication) {
            let continueView = 0
            if (data === Constants.APPLICATION_STATUS.INCOMPLETE) continueView = 1
            else if (data === Constants.APPLICATION_STATUS.APPROVED) continueView = 2
            else if (data === Constants.APPLICATION_STATUS.REJECTED) continueView = 3
            else {
                setError("Cannot Continue Application!")
                return
            }
            changeCurrentView({ view: continueView, applicationNumber: existingApplication })
        }

        const checkStatus = async function () {
            const existingApplication = searchParams.get("application_number")
            if (existingApplication) {
                const response = await application.getStatus(existingApplication)
                if (response.status === 200) {
                    if (response.data.data) {
                        await continueApplication(response.data.data, existingApplication)
                    } else {
                        setError("Application Not Found!")
                    }
                } else if (response.status === 401) {
                    navigate("/agent/auth")
                } else {
                    setError("API Error!")
                }
            } else {
                changeCurrentView({ view: 0, applicationNumber: null })
            }
        }
        checkStatus()
    }, [navigate, searchParams])

    return (
        <div>
            {currentView.view === 0 && <CustomerDetails next={changeView} />}
            {currentView.view === 1 && <MoreCustomerDetails next={changeView} applicationNumber={currentView.applicationNumber} />}
            {currentView.view === 2 && <Eligible next={changeView} applicationNumber={currentView.applicationNumber} />}
            {currentView.view === 3 && <NotEligible next={changeView} applicationNumber={currentView.applicationNumber} />}
            {currentView.view === 9 && !error && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
            {currentView.view === 9 && error && <div className="mt-4 flex items-center justify-center"><CommonError error={error} className="mt-4" /></div>}
        </div>
    )
}

export default Egilibility