import React, { useEffect } from 'react'
import { CommonLoader } from '../../../index.js'
import MixPanel from '../../../../config/mixpanel.js';

const Redirection = (props) => {
    useEffect(() => {
        setTimeout(() => {
            if (props.data.url) window.location.replace(props.data.url)
        }, 2000)
        MixPanel.track(MixPanel.types.customer.application.redirect_to_lender)
    }, [props])

    return (
        <div className="flex-1 flex flex-col items-center justify-center">
            <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />You will be redirected to</p>
            <img src={props.data.logo} alt="lender logo" className="h-8 mt-4" />
        </div>
    )
}

export default Redirection