import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Transactions = {
    application: {
        getCount: async function ({ search, status }) {
            return await callapi({
                method: "get",
                url: endpoints.transaction.application.count,
                params: { search, status }
            })
        },
        getData: async function ({ search, status }, pageno) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.transaction.application.list,
                params: { search, status, pageno }
            })
        },
        getDetail: async function ({ id }) {
            return await callapi({
                method: "get",
                url: endpoints.transaction.application.detail,
                params: { id }
            })
        }
    },
    insurance: {
        getCount: async function ({ search, status }) {
            return await callapi({
                method: "get",
                url: endpoints.transaction.insurance.count,
                params: { search, status }
            })
        },
        getData: async function ({ search, status }, pageno) {
            if (!pageno) pageno = 1
            return await callapi({
                method: "get",
                url: endpoints.transaction.insurance.list,
                params: { search, status, pageno }
            })
        },
        getDetail: async function ({ id }) {
            return await callapi({
                method: "get",
                url: endpoints.transaction.insurance.detail,
                params: { id }
            })
        }
    },
    wallet: {
        getCount: async function ({ search }) {
            return callapi({
                method: "get",
                url: endpoints.transaction.wallet.count,
                params: { search }
            })
        },
        getData: async function ({ search }, pageno, download = false) {
            if (!pageno) pageno = 1
            return callapi({
                method: "get",
                url: endpoints.transaction.wallet.list,
                params: { pageno, search, download }
            })
        }
    },
    paymentCollection: {
        getCount: async function () {
            return callapi({
                method: "get",
                url: endpoints.transaction.paymentCollection.count,
                params: {}
            })
        },
        getData: async function (pageno) {
            if (!pageno) pageno = 1
            return callapi({
                method: "get",
                url: endpoints.transaction.paymentCollection.list,
                params: { pageno }
            })
        },
        getDetail: async function (id) {
            return callapi({
                method: "get",
                url: endpoints.transaction.paymentCollection.detail,
                params: id
            })
        }
    }
}

export default Transactions