import React, { useState, useCallback, useEffect } from 'react'
import { CommonError, CommonLoader, CommonLinkButton, CommonTable, CommonPagination } from '../..'
import { useNavigate } from 'react-router-dom'
import API from '../../../apis'

const Manage = () => {
    let [error, setError] = useState(null)
    let [count, setCount] = useState(null)
    let [numPages, setNumPages] = useState(1)
    let [currentPage, setCurrentPage] = useState(1)
    let [data, setData] = useState(null)
    let navigate = useNavigate()

    const fetchCount = useCallback(async function () {
        const response = await API.backoffice.supervisor.count()
        if (response.status === 200 && response.data?.data) {
            setCount(response.data.data.count)
            setNumPages(response.data.data.num_pages)
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate])

    const fetchData = useCallback(async function () {
        const response = await API.backoffice.supervisor.list(currentPage)
        if (response.status === 200 && response.data?.data) {
            setData(response.data.data)
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate, currentPage])

    useEffect(() => {
        fetchCount().then(fetchData)
    }, [fetchCount, fetchData])

    useEffect(() => {
        setError(null)
        setData(null)
        fetchData()
    }, [currentPage, fetchData])

    return (
        <div className="flex flex-col px-12">
            <div className="flex items-center space-x-4">
                <h6 className="font-semibold inline-block">Manage Supervisors</h6>
                <CommonLinkButton to="/backoffice/supervisors/add" text="Add New" short={true} isOutline={true} className="border-0 px-2" />
            </div>
            <hr className="mt-1 text-gray" />
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {count && count > 0 && data && <CommonTable className="mt-4" data={data} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
        </div>
    )
}

export default Manage