import React, { useEffect, useState } from 'react'
import { default as CashDeposit } from './pages/cash-deposit'
import { default as PaymentGateway } from './pages/payment-gateway'
import { default as Netbanking } from './pages/netbanking'
import { useNavigate } from 'react-router-dom'
import Wallet from '../../../apis/wallet'
import { CommonPopup } from '../..'
import MixPanel from '../../../config/mixpanel'

const Add = () => {
    const navigate = useNavigate()
    const [type, setType] = useState("pg")
    const [alert, setAlert] = useState(null)
    const [success, setSuccess] = useState(false)

    function onCancel() {
        navigate("/agent/wallet")
    }

    async function onSubmit(_data) {
        const data = { ..._data }
        const remarks = data.remarks
        delete data.remarks
        const amount = data.amount
        delete data.amount
        const response = await Wallet.newRequest({ amount, data, remarks })
        if (response.status === 200) {
            setSuccess(true)
            MixPanel.track(MixPanel.types.agent.wallet.add_success)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setAlert(response.error)
        }
    }

    useEffect(() => {
        MixPanel.track(MixPanel.types.agent.wallet.add_open, { type })
    }, [type])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Wallet</h6>
            <hr className="mt-3 text-gray" />
            <div className="mt-8 flex flex-col lg:flex-row gap-4 text-center">
                {/* <span className={"px-4 py-2 text-md font-semibold rounded-full cursor-pointer " + (type === "cash" ? "text-white bg-sk-blue" : "text-gray-700 bg-gray-100")} onClick={() => setType("cash")}>Cash Deposit</span> */}
                <span className={"px-4 py-2 text-md font-semibold rounded-full cursor-pointer inline-block " + (type === "pg" ? "text-white bg-sk-blue" : "text-gray-700 bg-gray-100")} onClick={() => setType("pg")}>Pay with Payment Gateway</span>
                <span className={"px-4 py-2 text-md font-semibold rounded-full cursor-pointer inline-block " + (type === "netbanking" ? "text-white bg-sk-blue" : "text-gray-700 bg-gray-100")} onClick={() => setType("netbanking")}>Bank Transfer / Cash Deposit</span>
            </div>
            <p className="mt-8"></p>
            {type === "cash" && <CashDeposit onCancel={onCancel} onSubmit={onSubmit} onError={setAlert} />}
            {type === "pg" && <PaymentGateway onCancel={onCancel} onSubmit={setSuccess} onError={setAlert} />}
            {type === "netbanking" && <Netbanking onCancel={onCancel} onSubmit={onSubmit} onError={setAlert} />}
            {alert && <CommonPopup onCancel={() => setAlert(null)} onOk={() => setAlert(null)}><div>{alert}</div></CommonPopup>}
            {success && <CommonPopup onCancel={onCancel} onOk={onCancel} icon="/assets/icons/green-tick.svg"><div>Your request has been submitted. Funds will be added to your wallet based on realisation of funds in our bank account.</div></CommonPopup>}
        </div>
    )
}

export default Add