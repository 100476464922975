import React, { useEffect, useState } from 'react'
import { CommonPagination, CommonTable, CommonError, CommonLoader, CommonSearch } from '../../../index'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const Wallet = () => {
    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {
        MixPanel.track(MixPanel.types.agent.transactions.wallet)
    }, [])

    return (
        <div className="mt-4 mb-10">
            <CommonSearch context="wallet" hideStatus={true} defaultFilter={({ search: null, status: null })} setError={setError} setData={setData} getCount={API.transactions.wallet.getCount} getList={API.transactions.wallet.getData} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} showExport={true} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {numPages > 0 && data && <CommonTable className="mt-4" data={data} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
        </div>
    )
}

export default Wallet