import React, { useState } from "react"
import { CommonFormCheckboxV2, CommonFormRadio, CommonFormTextInput } from "../../.."
import { HiMagnifyingGlass } from "react-icons/hi2"
import { MdOutlineKeyboardDoubleArrowDown } from "react-icons/md"
import MixPanel from "../../../../config/mixpanel"

const DisplayPriceOptions = [
    { key: "customer_price", text: "Show Published Fare" },
    { key: "net_price", text: "Show Offer Fare" }
]
const TimeOptions = [
    { key: "all", text: "All" },
    { key: "morning", text: "6AM - 12PM" },
    { key: "afternoon", text: "12PM - 6PM" },
    { key: "evening", text: "6PM - 12AM" },
    { key: "night", text: "12AM - 6AM" }
]

const Filters = ({ filters, setFilters, airlines }) => {
    const [showAllAirlines, setShowAllAirlines] = useState(false)
    const updateFilters = (k, v, typeIndex) => {
        setFilters(prev => ({ ...prev, [k]: v }))

        if (!typeIndex) return
        switch (typeIndex) {
            case 0:
                MixPanel.track(MixPanel.types.agent.flight.filter_number); break;
            case 1:
                MixPanel.track(MixPanel.types.agent.flight.filter_price); break;
            case 2:
                v && MixPanel.track(MixPanel.types.agent.flight.filter_popular); break;
            case 3:
                MixPanel.track(MixPanel.types.agent.flight.filter_duration); break;
            case 4:
                MixPanel.track(MixPanel.types.agent.flight.filter_departure); break;
            case 5:
                MixPanel.track(MixPanel.types.agent.flight.filter_return); break;
            default:
                break;
        }
    }

    const updateAirlineFilter = (airline) => {
        setFilters(prev => {
            const newFilters = { ...prev }
            const airlines = new Set(newFilters.selectedAirlines)
            if (airlines.has(airline)) airlines.delete(airline)
            else {
                airlines.add(airline)
                MixPanel.track(MixPanel.types.agent.flight.filter_airline)
            }
            newFilters.selectedAirlines = Array.from(airlines)
            return newFilters
        })
    }

    const searchFlightNumber = (value) => {
        if (!filters.searchFlightNumber && value.length < 3) return
        updateFilters("searchFlightNumber", value, 0)
    }

    return (
        <div className="flex flex-col w-full gap-y-8">
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Price</p>
                <CommonFormRadio className="ml-2 gap-y-1" name="sk-flights-search-filters-price" textClassName="font-medium text-xs" options={DisplayPriceOptions} defaultValue={filters.showNetPrice ? "net_price" : "customer_price"} onChange={(v) => updateFilters("showNetPrice", v === "net_price", 1)} />
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Popular Filters</p>
                <CommonFormCheckboxV2 className="w-full ml-2" checked={filters.popularNonStop} label="Non Stop" labelClassName="text-xs font-medium" onChange={v => updateFilters("popularNonStop", v, 2)} />
                <CommonFormCheckboxV2 className="w-full ml-2" checked={filters.popularSMEFares} label="SME Fares" labelClassName="text-xs font-medium" onChange={v => updateFilters("popularSMEFares", v, 2)} />
                <CommonFormCheckboxV2 className="w-full ml-2" checked={filters.popularRefundable} label="Refundable" labelClassName="text-xs font-medium" onChange={v => updateFilters("popularRefundable", v, 2)} />
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Flight Number</p>
                <CommonFormTextInput minLength="3" id="sk-flights-search-flight-no" className="text-xs" placeholder="eg. 111" icon={<HiMagnifyingGlass />} onChange={searchFlightNumber} regex="[^\d]" />
                <p className="text-xs text-gray-700">Min. 3 characters.</p>
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Airlines</p>
                {airlines.slice(0, showAllAirlines ? airlines.length : 10).map((x, i) => <CommonFormCheckboxV2 key={x} className="w-full ml-2" checked={filters.selectedAirlines?.indexOf(x) > -1} label={x} labelClassName="text-xs font-medium" onChange={v => updateAirlineFilter(x, v)} />)}
                {showAllAirlines === false && airlines.length > 10 && <p className="text-sk-blue text-xs font-medium cursor-pointer" onClick={() => setShowAllAirlines(true)}><MdOutlineKeyboardDoubleArrowDown className="inline-block w-4 h-4" /> Show All ({airlines.length})</p>}
                {showAllAirlines === true && <p className="text-sk-blue text-xs font-medium cursor-pointer" onClick={() => setShowAllAirlines(false)}><MdOutlineKeyboardDoubleArrowDown className="inline-block w-4 h-4 rotate-180" /> Collapse</p>}
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Duration (in mins.)</p>
                <div className="flex flex-row items-center">
                    <CommonFormTextInput id="sk-flights-search-filters-min-duration" type="number" min="0" max="9999" placeholder="Min" className="flex-1 text-xs" onChange={v => updateFilters("minDuration", v, 3)} regex="[^\d]" />
                    <span className="mx-2 text-gray-700">-</span>
                    <CommonFormTextInput id="sk-flights-search-filters-max-duration" type="number" min="0" max="9999" placeholder="Max" className="flex-1 text-xs" onChange={v => updateFilters("maxDuration", v, 3)} regex="[^\d]" />
                </div>
                <p className="text-xs text-gray-700">E.g. for 2 hrs 30 mins enter value in mins = 150.</p>
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Departure Time</p>
                <CommonFormRadio id="sk-flights-search-filters-depature-time" className="ml-2 gap-y-1" name="sk-flights-search-filters-depature-time" textClassName="font-medium text-xs" options={TimeOptions} defaultValue={TimeOptions[0].key} onChange={(v) => updateFilters("preferredDepartureTime", v, 4)} />
            </div>
            <div className="flex flex-col gap-y-2">
                <p className="text-sk-blue font-bold text-sm">Arrival Time</p>
                <CommonFormRadio id="sk-flights-search-filters-arrival-time" className="ml-2 gap-y-1" name="sk-flights-search-filters-arrival-time" textClassName="font-medium text-xs" options={TimeOptions} defaultValue={TimeOptions[0].key} onChange={(v) => updateFilters("preferredArrivalTime", v, 5)} />
            </div>
        </div>
    )
}

export default Filters