import React, { useState } from 'react'
import { default as SingleInsurance } from './single-insurance'
import { default as AnnualInsurance } from './annual-insurance'
import API from '../../../../apis'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel'
// import { CommonBadges } from '../../..'

const InsuranceSearch = (props) => {
    const navigate = useNavigate()
    const [insuranceType, setInsuranceType] = useState(props.data?.searchData?.trip_type === "2" ? "2" : "1")
    const [data, setData] = useState(props?.data?.searchData || { trip_type: "1" })
    const [otherData, setOtherData] = useState(null)

    const handleCheckboxChange = (val) => {
        const otherDataCopy = otherData ? { ...otherData } : null
        setOtherData({ ...data })
        setData(otherDataCopy || { trip_type: val })
        setInsuranceType(val)
        if (val === "3") {
            navigate("/agent/insurance/group-insurance-enquiry")
        }
    }

    const updateData = async function (k, v) {
        setData({ ...data, [k]: v })
    }

    // useEffect(() => console.log(data), [data])

    const onSubmit = async function () {
        const response = await API.insurance.search(data)
        if (response.status === 200 && Array.isArray(response.data?.data?.results) && response.data.data.results.length > 0) {
            props.changeView(null, 1, null, { searchData: { ...data, num_days: response.data?.data?.num_days }, searchResults: response.data.data.results })
            MixPanel.track(MixPanel.types.agent.insurance.search)
        } else props.changeView(response.error || "API Error!")
    }

    return (
        <>
            <div>
                {/* <div className='mx-auto my-4'><CommonBadges.General title="New" text="You can now scan customer's passport image or pdf while entering Passenger Details." /></div> */}
                <p className='text-sm'>Select Insurance Type </p>
                <div className="mt-2 flex justify-between w-full lg:w-1/2 xl:w-1/3 max-w-72 drop-shadow cursor-pointer select-none rounded-md bg-white p-1">
                    <span className={`text-center rounded py-2 w-28 text-sm font-medium ${insuranceType === "1" ? 'text-white bg-sk-blue' : 'text-body-color'}`} onClick={() => handleCheckboxChange("1")}>Single Trip</span>
                    <span className={`text-center rounded py-2 w-28 text-sm font-medium ${insuranceType === "2" ? 'text-white bg-sk-blue' : 'text-body-color'}`} onClick={() => handleCheckboxChange("2")}>Multi Trip</span>
                    <span className={`text-center rounded py-2 w-28 text-sm font-medium ${insuranceType === "3" ? 'text-white bg-sk-blue' : 'text-body-color'}`} onClick={() => handleCheckboxChange("3")}>Group Travel</span>
                </div>
            </div>
            <div className="mt-8 grid grid-cols-6">
                <div className="col-span-full md:col-span-5 lg:col-span-4 xl:col-span-3">
                    {insuranceType === "1" && <SingleInsurance updateData={updateData} onSubmit={onSubmit} data={data} />}
                    {insuranceType === "2" && <AnnualInsurance updateData={updateData} onSubmit={onSubmit} data={data} />}
                </div>
            </div>
        </>
    )
}

export default InsuranceSearch