import React, { useEffect, useState } from 'react'
import { CommonPagination, CommonTable, CommonError, CommonLoader, CommonSearch } from '../../../index'
import { default as ApplicationDetails } from './application-details'
import API from '../../../../apis'
import Constants from '../../../../config/constants'
import MixPanel from '../../../../config/mixpanel'

const Applications = () => {
    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [detail, setDetail] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)

    useEffect(() => {
        MixPanel.track(MixPanel.types.agent.transactions.applications)
    }, [])

    const actionsTrigger = async function (type) {
        MixPanel.track(MixPanel.types.agent.transactions.application_detail_action, { type })
        if (type === "RESHARE_LINK") {
            const response = await API.application.update(detail, { [type]: true })
            if (response.status === 200) return true
        }
        return false
    }

    return (
        <div className="mt-4 mb-10">
            <CommonSearch context="application" statuses={Object.values(Constants.APPLICATION_STATUS)} defaultFilter={({ search: null, status: "ALL" })} setError={setError} setData={setData} getCount={API.transactions.application.getCount} getList={API.transactions.application.getData} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {numPages > 0 && data && <CommonTable className="mt-4" data={data} showDetails={setDetail} highlighted={detail} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <ApplicationDetails id={detail} onClose={() => setDetail(null)} detailAPI={API.transactions.application.getDetail} showActions={true} actionsTrigger={actionsTrigger} />}
        </div>
    )
}

export default Applications