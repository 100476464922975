import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonError, CommonPopup } from '../..'
import Auth from '../../../apis/auth'
import { useNavigate } from 'react-router-dom'

const ChangePassword = (props) => {
    let [error, setError] = useState(null)
    let [data, setData] = useState({})
    let [complete, setComplete] = useState(false)
    let navigate = useNavigate()

    async function change() {
        setError(null)
        const response = await Auth.changePassword(data)
        if (response.status === 200) {
            setComplete(true)
        } else if (response.status === 401) {
            navigate(props.base + "/auth")
        } else {
            setError(response.error)
        }
    }

    async function redirect() {
        navigate(props.base)
    }

    return (
        <div className="flex justify-center items-center">
            {complete && <CommonPopup onOk={redirect} onCancel={redirect} icon={"/assets/icons/green-tick.svg"}>
                Your password has been changed successfully.
            </CommonPopup>}
            <div className="p-12 bg-white drop-shadow rounded-xl mt-8 w-full md:w-1/2 xl:w-1/3 space-y-4">
                <img src="/assets/icons/cross.svg" className="h-7 p-2 float-right cursor-pointer opacity-50" alt="close" onClick={redirect} />
                <p className="text-center text-sk-blue text-xl">Change Password</p>
                {error && <CommonError error={error} className="mb-4" />}
                <CommonFormTextInput type="password" id="current-password" label="Current Password" onChange={v => setData({ ...data, "current": v })} />
                <CommonFormTextInput type="password" id="new-password" label="New Password" onChange={v => setData({ ...data, "new": v })} />
                <CommonFormTextInput type="password" id="re-new-password" label="Retype New Password" onChange={v => setData({ ...data, "re": v })} />
                <CommonFormButton text="Submit" className="w-full" onClick={change} />
            </div>
        </div>
    )
}

export default ChangePassword