import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react'
import { default as ErrorScreen } from './components/404'

const root = ReactDOM.createRoot(document.getElementById('root'));

Sentry.init({
    dsn: "https://05f9bac24abadd4889bdb2f0f6d3123b@o4506507123163136.ingest.sentry.io/4506642239062016"
})

root.render(
    <React.StrictMode>
        <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
            <App />
        </Sentry.ErrorBoundary>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
