import React, { useState } from 'react'
import { CommonFormSelect, CommonFormDateInput, CommonFormButton } from '../../../index'

const DestinationTypeMap = {
    "1": "Domestic",
    "2": "Worldwide with US & Canada",
    "3": "Worldwide without US & Canada"
}

const AnnualInsurance = (props) => {
    const ageArrayMonths = Array(9).fill(0)
    const ageArrayYears = Array(75).fill(0)
    let [numPax, setNumPax] = useState(props?.data?.num_pax || [])

    async function updateNumPax(v) {
        const newNumPax = [...numPax]
        if (newNumPax.length < v) {
            for (let i = newNumPax.length; i < v; i++) newNumPax.push(0)
        } else if (newNumPax.length > v) {
            for (let i = newNumPax.length; i > v; i--) newNumPax.pop()
        }
        setNumPax(newNumPax)
        props.updateData("num_pax", newNumPax)
    }

    async function updatePax(i, v) {
        const newNumPax = [...numPax.slice(0, i), v, ...numPax.slice(i + 1)]
        setNumPax(newNumPax)
        props.updateData("num_pax", newNumPax)
    }

    return (
        <form className="w-full flex flex-col gap-y-4">
            <CommonFormSelect id="sk-ins-an-destination-type" label="Destination Type" className="w-full" onChange={(v) => props.updateData("destination_type", v)} defaultValue={[props?.data?.destination_type, DestinationTypeMap[props?.data?.destination_type]]}>
                <option value="2">Worldwide with US & Canada</option>
                <option value="3">Worldwide without US & Canada</option>
            </CommonFormSelect>
            <CommonFormDateInput id="sk-ins-an-depart-date" label="Departure Date" className="w-full" onChange={(v) => props.updateData("departure_date", v)} defaultValue={props?.data?.departure_date} />
            <div className="flex items-center gap-4">
                <CommonFormSelect id="sk-ins-an-number-pax" label="Number of Pax" onChange={updateNumPax} className="w-44" defaultValue={[props?.data?.num_pax?.length, props?.data?.num_pax?.length]}>
                    {Array(10).fill(0).map((_, num) => <option key={num + 1} value={num + 1}>{num + 1}</option>)}
                </CommonFormSelect>
                <p className="text-xs">Maximum age: 75 years</p>
            </div>
            {numPax.length > 0 && <div className="grid grid-cols-6 gap-4">
                {numPax.map((_, num) =>
                    <CommonFormSelect key={num + 1} id={"sk-ins-an-age-pax-" + (num + 1)} label={"Age of Pax " + (num + 1)} onChange={(v) => updatePax(num, v)} className="col-span-3 lg:col-span-2" defaultValue={props?.data?.num_pax?.[num] ? [props?.data?.num_pax?.[num], (props?.data?.num_pax?.[num] < 1 ? Math.round(props?.data?.num_pax?.[num] * 100).toString() : props?.data?.num_pax?.[num]) + (props?.data?.num_pax?.[num] < 1 ? " month" : " year") + (props?.data?.num_pax?.[num] === 1 || props?.data?.num_pax?.[num] === "1" ? "" : "s")] : null}>
                        {ageArrayMonths.map((_, num) => <option key={num + 3} value={"0." + (num + 3).toString().padStart(2, "0")}>{num + 3} months</option>)}
                        {ageArrayYears.map((_, num) => <option key={num + 1 + 10} value={(num + 1).toString()}>{num + 1} year{(num + 1) !== 1 ? "s" : ""}</option>)}
                    </CommonFormSelect>
                )}
            </div>}
            <p className="text-xs">For 7 or more passengers or age above 75, please send details to <a href="mailto:insurance@sankash.in" className="text-sk-blue underline font-semibold">insurance@sankash.in</a></p>
            <CommonFormButton id="sk-ins-an-submit" text="Search" className="w-full" onClick={props.onSubmit} />
        </form>
    )
}

export default AnnualInsurance