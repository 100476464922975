import React from 'react'
import utils from '../../common/utils'

const SingleOffer = (props) => {
  return (
    <div className={"rounded-xl border-2 p-3 relative " + (props.className ? props.className : "") + " " + (props.showSelection ? (props.selected ? "border-sk-blue" : "border-gray border-opacity-30") : "border-sk-blue")} onClick={props.onClick}>
      {props.offer.interestRate === 0 && <span className="absolute right-6 top-0 bg-sk-blue text-xs text-white font-semibold px-3 py-0.5 rounded-b-2xl">No Cost EMI</span>}
      <p className="text-xs text-gray font-medium mb-2">Pay per month</p>
      <div className="flex justify-between">
        <div className="inline-flex items-start">
          {props.showSelection && (
            props.selected ?
              <img className="inline w-5 h-5 mr-2 mt-1.5" src="/assets/icons/radio-selected.svg" alt="selected" /> :
              <img className="inline w-5 h-5 mr-2 mt-1.5" src="/assets/icons/radio-not-selected.svg" alt="not selected" />
          )}
          <span className="text-gray font-medium text-2xl">&#8377;</span>
          <span className="text-2xl font-bold pl-1">{utils.formatINR(props.offer.emi)}</span>
        </div>
        <div>
          <span className="text-sk-blue text-lg font-medium">{props.offer.tenure}</span>
          <span className="text-sk-blue font-medium text-sm ml-1">months</span>
          <img className={"inline w-3 h-3 ml-4 " + ((props.showSelection === false || props.selected) && "rotate-180")} src="/assets/icons/arrow-down-blue.svg" alt="expand" />
        </div>
      </div>
      {
        (props.showSelection === false || props.selected) &&
        <div>
          <hr className="text-gray opacity-30 my-3" />
          <div className="flex justify-between">
            <div className="inline-flex flex-col">
              <p className="text-xs text-gray font-medium text-center">Total Amount</p>
              <p className="text-center">
                <span className="text-gray font-medium text-2xl">&#8377;</span>
                <span className="text-lg font-bold pl-1">{utils.formatINR(props.offer.totalPayable)}</span>
              </p>
            </div>
            <div className="inline-flex flex-col mr-6">
              <p className="text-xs text-gray font-medium text-center">Interest Rate</p>
              <p className="text-center">
                <span className="text-lg font-bold pl-1">{props.offer.interestRate}% pa</span>
              </p>
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default SingleOffer