import React, { useState, useEffect } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonError, CommonFormCheckbox } from '../../index'
import Static from '../../../apis/static'
import { useNavigate } from 'react-router-dom'
import utils from '../../common/utils'
import MixPanel from '../../../config/mixpanel'

const EMI = () => {
    let [tripValue, setTripValue] = useState([100000, utils.formatINR(100000)])
    let [loanAmount, setLoanAmount] = useState([100000, utils.formatINR(100000)])
    let [tenure, setTenure] = useState(6)
    let [apiData, setApiData] = useState(null)
    let navigate = useNavigate()
    // allowed tenures is also initiated in useeffect
    const allowedTenures = [3, 6, 9, 12, 18, 24, 30, 36, 48]
    let [error, setError] = useState(null)
    let [showNoCost, setShowNoCost] = useState(false)

    function changeTripValue(value) {
        setError(null)
        if (value < 5000) value = 5000
        if (value > 500000) value = 500000
        setTripValue([value, utils.formatINR(value)])
    }

    function changeLoanAmount(value) {
        setError(null)
        if (value < 5000) value = 5000
        if (value > 500000) value = 500000
        setLoanAmount([value, utils.formatINR(value)])
    }

    function changeTenure(index, fromRangeSlider) {
        setError(null)
        if (fromRangeSlider) {
            if (index < 0 || index >= allowedTenures.length) index = 1
            index = allowedTenures[index]
        } else {
            if (!allowedTenures.includes(index)) index = 6
        }
        setTenure(index)
    }

    useEffect(function () {
        document.getElementById("sk-emi-calc-trip-value-input").value = tripValue[1]
        document.getElementById("sk-emi-calc-trip-value-range").value = tripValue[0]
        setApiData(null)
    }, [tripValue])

    useEffect(function () {
        document.getElementById("sk-emi-calc-loan-amount-input").value = loanAmount[1]
        document.getElementById("sk-emi-calc-loan-amount-range").value = loanAmount[0]
        setApiData(null)
    }, [loanAmount])

    useEffect(function () {
        setApiData(null)
        const allowedTenures = [3, 6, 12, 18, 24, 30, 36]
        document.getElementById("sk-emi-calc-tenure-input").value = tenure
        document.getElementById("sk-emi-calc-tenure-range").value = allowedTenures.indexOf(tenure)
    }, [tenure])

    async function onSubmit() {
        setError(null)
        setApiData(null)
        if (loanAmount[0] > tripValue[0]) {
            setError("Loan Amount cannot be more than Trip Value")
            return
        }
        const apiResponse = await Static.calcEMI({ amount: loanAmount[0] })
        if (apiResponse.status === 200) {
            let matchingOffer = null
            for (let offer of apiResponse.data.data) {
                if (showNoCost && [3, 6].includes(tenure) && offer.tenure === tenure && offer.type.toLowerCase().includes("no cost")) {
                    matchingOffer = offer
                    break
                } else if (!showNoCost && [3, 6, 9, 12, 18, 24, 30, 36, 48].includes(tenure) && offer.tenure === tenure && !offer.type.toLowerCase().includes("no cost")) {
                    matchingOffer = offer
                    break
                }
            }
            if (matchingOffer) {
                setApiData({
                    emi: utils.formatINR(matchingOffer.emi),
                    downPayment: utils.formatINR(matchingOffer.downPayment),
                    fee: utils.formatINR(matchingOffer.fee)
                })
                setTimeout(() => { document.getElementById("sk-emi-results").scrollIntoView({ behavior: 'smooth' }) }, 500)
                MixPanel.track(MixPanel.types.agent.emi.calculator_offers)
            } else {
                setError("No offer found.")
                MixPanel.track(MixPanel.types.agent.emi.calculator_no_offers)
            }
        } else if (apiResponse.status === 401) {
            navigate("/agent/auth")
        } else {
            setError("API Error!")
        }
    }

    const handleFocus = (id, value) => {
        document.getElementById(id).value = value
        document.getElementById(id).type = "number"
    }

    const handleBlur = (id, newValue) => {
        if (id === "sk-emi-calc-trip-value-input") {
            changeTripValue(newValue)
        } else if (id === "sk-emi-calc-loan-amount-input") {
            changeLoanAmount(newValue)
        }
        document.getElementById(id).type = "text"
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Calculate EMI</h6>
            <hr className="mt-3 text-gray" />
            <div className="mt-8 flex flex-col gap-4 lg:flex-row justify-between">
                <div className="lg:w-1/2 flex flex-col gap-y-2">
                    {error && <CommonError error={error} className="mb-4" />}
                    <div className="flex items-center gap-x-4">
                        <span className="font-medium flex-none">Total Trip Value</span>
                        <input id="sk-emi-calc-trip-value-input" type="text" className="flex-1 max-w-40 border rounded-xl border-sk-blue py-3 px-6 focus:outline-none" defaultValue={tripValue[1]} onFocus={() => handleFocus("sk-emi-calc-trip-value-input", tripValue[0])} onBlur={(e) => handleBlur("sk-emi-calc-trip-value-input", e.target.value)} />
                    </div>
                    <div className="flex items-center mt-4">
                        <span className="text-xs text-gray-500 select-none flex-none">&#8377; 5,000</span>
                        <input className="mx-3 h-1 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-sk-blue flex-1 focus:outline-none" type="range" id="sk-emi-calc-trip-value-range" defaultValue="100000" min="5000" max="500000" onChange={(e) => changeTripValue(e.target.value)} />
                        <span className="text-xs text-gray-500 select-none">&#8377; 5,00,000</span>
                    </div>

                    <div className="mt-12 flex items-center gap-x-4">
                        <span className="font-medium flex-none">Loan Amount</span>
                        <input id="sk-emi-calc-loan-amount-input" type="text" className="flex-1 max-w-40 border rounded-xl border-sk-blue py-3 px-6 focus:outline-none" defaultValue={loanAmount[1]} onFocus={() => handleFocus("sk-emi-calc-loan-amount-input", loanAmount[0])} onBlur={(e) => handleBlur("sk-emi-calc-loan-amount-input", e.target.value)} />
                    </div>
                    <div className="flex items-center mt-4">
                        <span className="text-xs text-gray-500 select-none">&#8377; 5,000</span>
                        <input className="mx-3 h-1 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-sk-blue flex-1 focus:outline-none" type="range" id="sk-emi-calc-loan-amount-range" defaultValue="100000" min="5000" max="500000" onChange={(e) => changeLoanAmount(e.target.value)} />
                        <span className="text-xs text-gray-500 select-none">&#8377; 5,00,000</span>
                    </div>

                    <div className="mt-12 flex items-center gap-x-4">
                        <span className="font-medium flex-none">Tenure (Months)</span>
                        <CommonFormTextInput id="sk-emi-calc-tenure-input" type="number" className="flex-1 max-w-40" disabled={true} value={tenure} />
                    </div>
                    <div className="flex mt-4">
                        <span className="text-xs text-gray-500 select-none flex-none -mt-1">3 months</span>
                        <div className="flex-1 mx-3 flex flex-col">
                            <input className="h-1 bg-gray-100 rounded-lg appearance-none cursor-pointer accent-sk-blue w-full" type="range" id="sk-emi-calc-tenure-range" defaultValue="100000" min="0" max="8" step="1" onChange={(e) => changeTenure(e.target.value, true)} />
                            <datalist id="tickmarks" className="flex justify-between -mx-4 *:basis-0 *:grow *:text-xs text-gray-500 mt-2 *:text-center">
                                <option label="3" value="0" className="invisible"></option>
                                <option label="6" value="13"></option>
                                <option label="9" value="25" className="invisible xl:visible"></option>
                                <option label="12" value="38"></option>
                                <option label="18" value="50" className="invisible xl:visible"></option>
                                <option label="24" value="63"></option>
                                <option label="30" value="75" className="invisible xl:visible"></option>
                                <option label="36" value="88"></option>
                                <option label="48" value="100" className="invisible"></option>
                            </datalist>
                        </div>
                        <span className="text-xs text-gray-500 select-none flex-none -mt-1">48 months</span>
                    </div>
                    <div className="flex mt-8">
                        <CommonFormCheckbox className="w-4 h-4 " checked={showNoCost} onChange={() => setShowNoCost(x => !x)} />
                        <span className="text-xs ml-1 cursor-pointer" onClick={() => setShowNoCost(x => !x)}>Show Offers with No Cost EMI?</span>
                    </div>

                    {/* <ul className="mt-16 *:text-xs list-disc">
                        <li>No cost EMI on 3 and 6 month term</li>
                        <li className="mt-2">Loan is protected with Loan Protect cover from ICICI Lombard</li>
                    </ul> */}
                    <CommonFormButton text="Calculate EMI" className="mt-4 w-full" onClick={onSubmit} />
                </div>
                {apiData &&
                    <div id="sk-emi-results" className="lg:w-1/2 max-w-96 bg-sk-yellow rounded-xl px-8 py-12 w-full self-center mt-8 lg:mt-0">
                        <p className="font-medium text-sm">EMI (Monthly Payment)</p>
                        <p className="bg-white rounded-xl py-3 px-4 mt-2 text-sm font-semibold">&#8377; <span>{apiData.emi}</span></p>
                        <p className="font-medium text-sm mt-4">Down Payment</p>
                        <p className="bg-white rounded-xl py-3 px-4 mt-2 text-sm font-semibold">&#8377; <span>{apiData.downPayment}</span></p>
                        <p className="font-medium text-sm mt-4">Processing Fees + Applicable Taxes</p>
                        <p className="bg-white rounded-xl py-3 px-4 mt-2 text-sm font-semibold">&#8377; <span>{apiData.fee}</span></p>
                        <CommonFormButton text="Check Eligibility" className="mt-12 w-full" onClick={() => navigate("/agent/application/check-eligibility")} />
                    </div>
                }
            </div>
        </div>
    )
}

export default EMI