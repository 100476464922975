import React, { useState } from 'react'
import { CommonError, CommonFormButton, CommonFormEmailInput, CommonFormTextInput } from '../../../'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const NewSignup = (props) => {
    const [data, setData] = useState({})
    const [error, setError] = useState(null)

    const onChange = (k, v) => {
        if (k === "login.password" || k === "login.confirmpassword") v = btoa(v)
        setData(prev => ({ ...prev, [k]: v }))
    }

    const submit = async () => {
        setError(null)
        const response = await API.agentOnboarding.validate(data)
        if (response.status === 200 && response.data?.data) {
            MixPanel.track(MixPanel.types.onboarding.validated)
            const otpResponse = await API.agentOnboarding.sendOTP({ mobile: data["login.mobile"], id: response.data.data })
            if (otpResponse.status === 200 && otpResponse.data?.data) {
                props.next(1, { mid: otpResponse.data.data, mobile: data["login.mobile"], ...data })
                MixPanel.track(MixPanel.types.onboarding.otp_sent)
            } else {
                setError(otpResponse.error || "API Error!")
            }
        } else {
            setError(response.error || "User Already Exist")
        }
    }

    return (
        <div className="flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-1/2 xl:w-2/5 sk-background py-20 text-white flex flex-col items-center">
                <h3 className="text-center text-4xl font-bold px-4 mb-2">Get 30% More Leads &</h3>
                <h3 className="text-center text-4xl font-bold px-4 text-sk-yellow">50% More Conversion</h3>
                <ul className="mt-8 list-disc marker:text-sk-yellow space-y-6 text-2xl font-semibold">
                    <li>
                        <p className="font-bold text-2xl">Travel Now Pay Later</p>
                        <p className="text-sm">Provide your customers with EMI options</p>
                        <p className="text-sm">through SanKash's travel now pay later,</p>
                        <p className="text-sm">enabling instant payment.</p>
                    </li>
                    <li>
                        <p className="font-bold text-2xl">Travel Insurance</p>
                        <p className="text-sm">Get cashback offers from SanKash</p>
                        <p className="text-sm">on Travel Insurance.</p>
                    </li>
                    <li>
                        <p className="font-bold text-2xl">Payment Gateway</p>
                        <p className="text-sm">Use SanKash Payment Gateway on portal to</p>
                        <p className="text-sm">collect payments from your customers at</p>
                        <p className="text-sm">lowest PG charges on debit and credit card.</p>
                    </li>
                    <li>
                        <p className="font-bold text-2xl">Working Capital</p>
                        <p className="text-sm">Get working capital for your business</p>
                        <p className="text-sm">at 0% interest.</p>
                    </li>
                </ul>
            </div>

            <div className="flex-1 flex flex-col pb-12">
                <img src="/assets/sankash-logo.svg" alt="Sankash" className="w-48 mr-5 self-end" />
                <div className="grid grid-cols-2 w-full lg:w-full xl:w-2/3 self-center px-12 gap-y-4 gap-x-4">
                    <p className="text-3xl font-medium my-4 lg:-mt-8 text-sk-blue col-span-full text-center lg:text-left">Agent Signup</p>
                    {error && <CommonError error={error} className="col-span-full" />}
                    <CommonFormTextInput className="col-span-full" id="sk-onboarding-signup-company-name" label="Company Name" onChange={v => onChange("company.name", v)} minLength="3" />
                    <CommonFormTextInput className="col-span-full" id="sk-onboarding-signup-first-name" label="Name" onChange={v => onChange("designated.name", v)} minLength="3" />
                    <CommonFormTextInput className="col-span-full" id="sk-onboarding-signup-mobile" label="Mobile" type="tel" minLength="10" maxLength="10" onChange={v => onChange("login.mobile", v)} />
                    <CommonFormEmailInput className="col-span-full" id="sk-onboarding-signup-email" label="Email" onChange={v => onChange("designated.email", v)} />
                    <CommonFormTextInput className="col-span-full" id="sk-onboarding-signup-password" label="Password" type="password" minLength="6" maxLength="20" onChange={v => onChange("login.password", v)} />
                    <CommonFormTextInput className="col-span-full" id="sk-onboarding-signup-confirm-password" label="Confirm Password" type="password" minLength="6" maxLength="20" onChange={v => onChange("login.confirmpassword", v)} />
                    <CommonFormButton className="col-span-full" text="submit" onClick={submit} />
                </div>
            </div>
        </div>
    )
}

export default NewSignup