import React, { useState } from 'react'
import './text-input.css'
import PropTypes from 'prop-types'
import { IconContext } from 'react-icons'

const TextInput = (props) => {
    const [value, setValue] = useState(props.value || null)

    function focusInput() {
        document.getElementById(props.id).focus()
    }

    function onChange(e) {
        if (props.type === "tel") {
            if (/\D/g.test(e.target.value)) e.target.value = e.target.value.replace(/\D/g, '')
        }
        if (props.regex) {
            e.target.value = e.target.value.replace(new RegExp(props.regex, 'g'), '')
        }
        if (typeof props.onChange === "function") props.onChange(e.target.value)
        setValue(e.target.value)
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue has-[:invalid]:border-red flex flex-col-reverse " + (props.className ? props.className : "") + " " + (props.disabled ? "bg-gray-100 bg-opacity-50" : "") + " " + (props.short ? "py-1 px-2" : "py-3 px-6")} onClick={focusInput}>
            <input ref={props.inputRef || null} id={props.id} type={props.type ? props.type : "text"} minLength={props.minLength || ""} maxLength={props.maxLength || ""} min={props.min || ""} max={props.max || ""} className="peer focus:outline-none font-medium inline-block w-full" onChange={onChange} autoComplete="off" disabled={props.disabled ? true : false} defaultValue={props.value || ""} placeholder={props.placeholder || ""} />
            {
                props.icon && typeof props.icon === "string" && <img className={"absolute " + props.iconClassName} src={props.icon} alt="input icon" onClick={() => props.onIconClick && props.onIconClick()} />
            }
            {
                props.icon && React.isValidElement(props.icon) && <IconContext.Provider value={{ className: "absolute right-4 top-[50%] -translate-y-1/2 text-gray-500  " + props.iconClassName }}>{props.icon}</IconContext.Provider>
            }
            {
                props.showCrossIcon && typeof value === "string" && value.length > 0 && <img className="absolute right-4 top-4 mt-0.5 w-3 h-3 opacity-30" src="/assets/icons/cross.svg" alt="cross" onClick={() => { document.getElementById(props.id).value = ""; onChange({ target: { value: "" } }); }} />
            }
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red select-none">{props.label}</label>
        </div>
    )
}

TextInput.propTypes = {
    id: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    minLength: PropTypes.string,
    maxLength: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    iconClassName: PropTypes.string,
    onIconClick: PropTypes.func,
    showCrossIcon: PropTypes.bool,
    short: PropTypes.bool,
    regex: PropTypes.string,
}

export default TextInput