import React, { useState } from 'react'
import { CommonPopup, CommonFormTextArea, CommonError } from '..'
import PropTypes from 'prop-types'

const Remarks = (props) => {
    const [remarks, setRemarks] = useState("")
    const [error, setError] = useState(null)

    const onOk = async function () {
        setError(null)
        if (remarks.length >= 5) props.callback(remarks)
        else setError("Remarks are too short.")
    }

    const onCancel = async function () {
        props.cancel()
    }

    return (
        <div>
            <CommonPopup showCancel={true} onOk={onOk} onCancel={onCancel} sticky={true}>
                {error && <CommonError error={error} className="mb-4" />}
                <CommonFormTextArea id="sk-request-remarks-popup" onChange={setRemarks} label={"Remarks for " + props.action} maxLength="100" minLength="5" numRows="3" />
            </CommonPopup>
        </div>
    )
}

Remarks.propTypes = {
    callback: PropTypes.func,
    cancel: PropTypes.func,
    action: PropTypes.string
}

export default Remarks