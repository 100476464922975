import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Util = {
    upload: async function (endpoint, { type, size, ext, file }, fileFormFieldName = 'file') {
        const formData = new FormData()
        formData.append(fileFormFieldName, file)
        return callapi({
            method: "post",
            url: endpoint,
            data: formData,
            params: {
                type, size, ext
            },
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    },
    uploadMultiple: async function (endpoint, files = [], fileFormFieldName = 'file') {
        const formData = new FormData()
        for (let file of files) {
            formData.append(fileFormFieldName, file)
        }
        return callapi({
            method: "post",
            url: endpoint,
            data: formData,
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
    },
    uploadPartnerDocuments: async function ({ type, size, ext, file }) {
        return this.upload(endpoints.util.uploadPartnerDocuments, { type, size, ext, file })
    },
    uploadBulkUpdate: async function ({ type, size, ext, file }) {
        return this.upload(endpoints.util.uploadBulkUpdate, { type, size, ext, file })
    },
    download: async function () { },
    pincode: async function (pincode) {
        return callapi({
            method: "get",
            url: endpoints.util.pincode(pincode)
        })
    },
    converter: {
        excelToJSON: async function ({ type, size, ext, file }, purpose) {
            const formData = new FormData()
            formData.append("file", file)
            return callapi({
                method: "post",
                url: endpoints.util.converter.excelToJSON,
                data: formData,
                params: {
                    type, size, ext, purpose
                },
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            })
        }
    }
}

export default Util