import React, { useEffect, useState } from 'react'
import { CommonLoader } from '../../index'
import PropTypes from 'prop-types'

const Button = (props) => {
    let [loading, setLoading] = useState(false)
    let [completed, setCompleted] = useState(null)
    let [text, setText] = useState(props.text)

    async function buttonClick() {
        if (loading) return
        if (completed !== null) {
            setText(props.text)
            return setCompleted(null)
        }
        setLoading(true)
        try {
            const response = await props.onClick()
            if (props.showResult) {
                setCompleted(response)
                if (response) setText("Success")
                else if (response === null) {
                    setText(props.text)
                    setCompleted(null)
                }
                else setText("Error")
            }
        } catch (_) { } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (props.loading === true || props.loading === false)
            setLoading(props.loading)
    }, [props.loading])

    return (
        <button disabled={props.disabled || loading} type={props.type || "button"} className={"rounded-xl font-semibold select-none focus:outline-none uppercase " + (completed !== null ? (completed ? "bg-green text-white" : "bg-red text-white") : props.disabled ? "bg-gray-300 text-white" : (props.isOutline ? "bg-white text-sk-blue border-sk-blue border-2 hover:bg-sk-blue hover:text-white" : "bg-sk-blue text-white")) + " " + (props.short ? "py-2" : "py-4") + " " + (props.className ? props.className : "")} onClick={buttonClick}>
            {loading && <CommonLoader className={"inline w-4 h-4 me-2 " + (props.isOutline ? "text-sk-blue" : "text-white")} />}
            {text}
        </button>
    )
}

Button.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    isOutline: PropTypes.bool,
    className: PropTypes.string,
    short: PropTypes.bool,
    text: PropTypes.string,
    showResult: PropTypes.bool,
    disabled: PropTypes.bool,
    loading: PropTypes.bool
}

export default Button