import React, { useState } from 'react'
import { CommonPopup, CommonFormTextInput, CommonFormSelect, CommonFormDateInput, CommonFormEmailInput, CommonFormCurrencyInput, CommonFormButton } from '../../..'
import API from '../../../../apis'
import Constants from '../../../../config/constants'
import MixPanel from '../../../../config/mixpanel'

const CustomerDetails = (props) => {
    const [error, setError] = useState(null)
    const [data, updateData] = useState({ mobile: props.data.mobile, lockMobile: props.data.lockMobile, first_name: props.data.first_name, last_name: props.data.last_name, email: props.data.email, amount: props.data.amount || null })

    const setData = function (k, v) {
        updateData(prev => ({ ...prev, [k]: v }))
    }

    async function onSubmit() {
        const response = await API.customer.new(data)
        if (response.status === 200) {
            const eligibility = response.data?.data?.eligible
            if (eligibility === true) props.changeView(Constants.CUSTOMER_VIEWS.OFFERS)
            else if (eligibility === false) props.changeView(Constants.CUSTOMER_VIEWS.REJECTED)
            else props.changeView(Constants.CUSTOMER_VIEWS.MORE_DETAILS)
            MixPanel.track(MixPanel.types.customer.application.form1)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex-1 flex flex-col space-y-4 py-4">
            {error && <CommonPopup small={true} onOk={() => setError("")} onCancel={() => setError("")}><div>{error}</div></CommonPopup>}
            <p className="font-bold text-center">Fill details to check your loan eligibility.</p>
            <CommonFormTextInput id="sk-cce-first-name" label="First Name and Middle Name (As per PAN)*" maxLength="32" minLength="3" value={props.data.first_name} onChange={(v) => setData("first_name", v)} regex="[^A-Za-z\. ]" />
            <CommonFormTextInput id="sk-cce-last-name" label="Last Name (As per PAN)*" maxLength="32" minLength="3" value={props.data.last_name} onChange={(v) => setData("last_name", v)} regex="[^A-Za-z\. ]" />
            <CommonFormTextInput id="sk-cce-mobile" label="Mobile Number*" type="tel" minLength="10" maxLength="10" value={props.data.mobile} disabled={true} />
            <CommonFormEmailInput id="sk-cce-email" type="email" label="E-mail Id*" maxLength="64" className="col-span-2" value={props.data.email} onChange={(v) => setData("email", v)} />
            <CommonFormDateInput id="sk-cce-dob" label="Date of Birth*" onChange={(v) => setData("dob", v)} />
            <CommonFormSelect id="sk-cce-gender" label="Gender*" onChange={(v) => setData("gender", v)} >
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Others">Others</option>
            </CommonFormSelect>
            <CommonFormCurrencyInput id="sk-cce-income" label="Monthly Income*" min="1000" max="999999" onChange={(v) => setData("monthly_income", v)} />
            <CommonFormCurrencyInput id="sk-cce-cost" label="Itinerary Cost*" min="5000" max="500000" className="col-span-2" onChange={(v) => setData("amount", v)} disabled={"amount" in props.data && props.data.amount} value={props.data.amount || ""} />

            <p className="col-span-3 text-xs">This eligibility check won't affect your credit score.</p>
            <CommonFormButton id="sk-cce-submit" text="CONTINUE" className="col-span-2 mt-3" onClick={onSubmit} />
        </div>
    )
}

export default CustomerDetails