import React from 'react'
import PropTypes from 'prop-types'

const Error = (props) => {
    return (
        <p className={"text-red flex items-start font-medium " + (props.className || "")}>
            <img src="/assets/icons/exclamation.svg" alt="issue" className="w-5 h-5 mr-2 mt-0.5" />
            <span className="mr-1">Error: {props.error}</span>
        </p>
    )
}

Error.propTypes = {
    error: PropTypes.string,
    className: PropTypes.string
}

export default Error