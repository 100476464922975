import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

const Button = (props) => {
    return (
        <Link to={props.to} target={props.newPage ? "_blank" : ""}>
            <button type="button" className={"rounded-xl font-semibold select-none focus:outline-none uppercase " + (props.isOutline ? "bg-white text-sk-blue border-sk-blue border-2 hover:bg-sk-blue hover:text-white" : "bg-sk-blue text-white") + " " + (props.short ? "py-2" : "py-4") + " " + (props.className ? props.className : "")}>
                {props.text}
            </button>
        </Link>
    )
}

Button.propTypes = {
    to: PropTypes.string,
    newPage: PropTypes.bool,
    isOutline: PropTypes.bool,
    text: PropTypes.string,
    className: PropTypes.string,
    short: PropTypes.bool
}

export default Button