import React, { useState } from 'react'
import { CommonPopup, CommonFormFileInput, CommonLoader, CommonTableKeyValue, CommonFormRadioWrapper, Copy } from '../../..'
import PropTypes from 'prop-types'
import API from '../../../../apis'
import utils from '../../../common/utils'
import MixPanel from '../../../../config/mixpanel'

const PassportScanner = (props) => {
    const [view, setView] = useState(0)
    const [images, setImages] = useState([null, null])
    const [error, setError] = useState(null)
    const [passenger, setPassenger] = useState({})
    const [nominees, setNominees] = useState({})

    const onOk = async function () {
        setError(null)
        if (view === 0) {
            if (!images[0] && !images[1]) return setError("Error: Please select an image.")
            setView(1)
            setPassenger({})
            const promises = []
            if (images[0] || images[1]) promises.push(API.insurance.scanPassport(images[0], images[1]))
            // if (images[1]) promises.push(API.insurance.scanPassport(images[1]))
            const results = await Promise.all(promises)
            let processed = false, newPassenger = {}
            for (let result of results) {
                if (result.status === 200 && result.data?.data) {
                    const data = result.data.data
                    for (let x of ['title', 'first_name', 'last_name', 'gender', 'dob', 'address', 'pincode', 'resident', 'passport_no', 'passport_country']) {
                        if (data[x] && !newPassenger[x]) {
                            newPassenger[x] = data[x]
                            processed = true
                        }
                    }
                    // if (data.type === "front" && data.passport_no) {
                    //     newPassenger.passport_no = data.passport_no
                    //     processed = true
                    //     if (data.passport_country) newPassenger.passport_country = data.passport_country
                    // }
                    if (data['nominee_name'] && Array.isArray(data['nominee_name'])) {
                        const newNominees = {}
                        for (let i = 0; i < data['nominee_name'].length; i++) {
                            newNominees[i] = { title: data['nominee_title'][i], name: data['nominee_name'][i], relation: data['nominee_relation'][i] }
                        }
                        setNominees(newNominees)
                    }
                }
            }
            if (processed === false) {
                setImages([null, null])
                setView(0)
                setError("Error: " + (results[0]?.error || results[1]?.error || "Unable to process this image. Kindly capture a new image of the passport and try again or fill information manually."))
            } else {
                setPassenger(newPassenger)
                setImages([null, null])
                setView(2)
                MixPanel.track(MixPanel.types.agent.insurance.passport_scan)
            }
        } else if (view === 2) {
            for (let key in passenger) {
                props.updateData(key, passenger[key])
            }
            props.hide()
        }
    }

    const updateNominee = async function (i) {
        const selectedNominee = Object.values(nominees)[i]
        setPassenger(prev => ({ ...prev, "nominee_name": selectedNominee.name, "nominee_relation": selectedNominee.relation, "nominee_title": selectedNominee.title }))
    }

    return (
        <CommonPopup wide={true} onOk={onOk} onCancel={props.hide} showCancel={view !== 1} sticky={true} disableOk={view === 1}>
            <p className='font-medium mb-4'>Upload Passport Images</p>
            {error && <p className="font-sm text-red mb-4">{error}</p>}
            {view === 0 && <div>
                <div className="flex flex-col md:flex-row gap-8">
                    <div className="flex flex-col gap-4 border border-sk-blue rounded-xl p-4 w-full md:w-1/2">
                        <img src={images[0] ? URL.createObjectURL(images[0]) : "/assets/sample-indian-passport-front.jpg"} alt="passport front" className='w-full' />
                        <CommonFormFileInput id="sk-passport-front" onChange={v => setImages(prev => ([v[0] || null, prev[1]]))} />
                        <p className="text-xs">Upload Passport <span className="font-bold">front</span> image like shown above.<br />Accepted files types: <span className="font-medium">.png</span>, <span className="font-medium">.jpg</span>, <span className="font-medium">.pdf</span>.<br />Max file size is 5MB.</p>
                    </div>
                    <div className="flex flex-col gap-4 border border-sk-blue rounded-xl p-4 w-full md:w-1/2">
                        <img src={images[1] ? URL.createObjectURL(images[1]) : "/assets/sample-indian-passport-back.jpg"} alt="passport front" className='w-full' />
                        <CommonFormFileInput id="sk-passport-back" onChange={v => setImages(prev => ([prev[0], v[0] || null]))} />
                        <p className="text-xs">Upload Passport <span className="font-bold">back</span> image like shown above.<br />Accepted files types: <span className="font-medium">.png</span>, <span className="font-medium">.jpg</span>, <span className="font-medium">.pdf</span>.<br />Max file size is 5MB.</p>
                    </div>
                </div>
                <p className="text-xs mt-4">Disclaimer: This is an experimental feature, information may or may not be read accurately from the provided image(s). Please verify the information before generating policy.</p>
            </div>}
            {view === 1 && <div>
                <div className="flex flex-col items-center justify-center">
                    <CommonLoader className="inline w-8 h-8 mb-2 text-gray-500" />
                    <p>Please wait...</p>
                </div>
            </div>}
            {view === 2 && <div>
                <CommonTableKeyValue className="px-4" heading="Extracted Data">
                    {Object.entries(passenger).map(([key, value]) => <span key={key === "dob" ? "Date of Birth" : key.split("_").map(x => x.charAt(0).toUpperCase() + x.substring(1)).join(" ")}>{key === "dob" ? utils.formatDate(value) : value}{props.showCopy && <Copy text={value} />}</span>)}
                </CommonTableKeyValue>
                {Object.keys(nominees).length > 0 && <div className='mt-6'><CommonFormRadioWrapper label="Select Nominee" name="select-nominee" options={Object.values(nominees).map((x, i) => ({ key: i, text: `${x.name} (${x.relation})` }))} onChange={updateNominee} grid={2} /></div>}
                <p className="text-sm mt-8">Click OK to fill passenger details using above information.</p>
            </div>}
        </CommonPopup>
    )
}

PassportScanner.propTypes = {
    updateData: PropTypes.func,
    hide: PropTypes.func,
    showCopy: PropTypes.bool
}

export default PassportScanner