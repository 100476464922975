import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Toggle = (props) => {
    const [isChecked, setChecked] = useState(props.checked || false)
    async function handleCheckboxChange() {
        const newChecked = !isChecked
        setChecked(newChecked)
        props.onChange(newChecked)
    }

    return (
        <>
            <label className='flex cursor-pointer select-none items-center'>
                <div className='relative'>
                    <input
                        type='checkbox'
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className='sr-only'
                    />
                    <div
                        className={`box block h-6 w-10 rounded-full ${isChecked ? 'bg-sk-blue' : 'bg-gray-300'
                            }`}
                    ></div>
                    <div
                        className={`absolute left-1 top-1 flex h-4 w-4 items-center justify-center rounded-full bg-white transition ${isChecked ? 'translate-x-full' : ''
                            }`}
                    ></div>
                </div>
            </label>
        </>
    )
}

Toggle.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func
}

export default Toggle