import React, { useEffect, useState, useCallback } from 'react'
import { CommonLoader, CommonError, CommonPagination, CommonTable, CommonDropdown } from '../..'
import API from '../../../apis'
import { useNavigate, Link } from 'react-router-dom'
import { BsThreeDotsVertical } from "react-icons/bs"

const WalletRequests = () => {
    let [error, setError] = useState(null)
    let [count, setCount] = useState(null)
    let [numPages, setNumPages] = useState(1)
    let [currentPage, setCurrentPage] = useState(1)
    let [data, setData] = useState(null)
    let navigate = useNavigate()

    const fetchCount = useCallback(async function () {
        const response = await API.backoffice.walletTransactions.count()
        if (response.status === 200 && response.data?.data) {
            if (response.data.data.count > 0) {
                setCount(response.data.data.count)
                setNumPages(response.data.data.num_pages)
            } else {
                setError("No Results Found!")
            }
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate])

    const fetchData = useCallback(async function () {
        const response = await API.backoffice.walletTransactions.list(currentPage)
        if (response.status === 200 && response.data?.data) {
            setData(response.data.data)
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate, currentPage])

    useEffect(() => {
        fetchCount().then(fetchData)
    }, [fetchCount, fetchData])

    useEffect(() => {
        setError(null)
        setData(null)
        fetchData()
    }, [currentPage, fetchData])

    const actionsHandler = async function (item, action) {
        setError(null)
        let response
        if (action === "Approve") {
            response = await API.backoffice.wallet.approve(item)
        } else if (action === "Reject") {
            response = await API.backoffice.wallet.reject(item)
        }
        if (response) {
            if (response.status === 200) fetchData()
            else setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col">
            <div className="flex">
                <h6 className="font-semibold hamburger-spacing">Wallet Transactions</h6>
                <CommonDropdown id="sk-more-options" dropdownId="sk-more-options-dropdown-menu" alignLeft={true}>
                    <BsThreeDotsVertical className="mt-1 ml-2" />
                    <Link to="/backoffice/wallet/transactions/add">
                        <span className="block px-4 py-2 hover:bg-gray-100 select-none">Add Manual Entry</span>
                    </Link>
                </CommonDropdown>
            </div>
            <hr className="mt-3 text-gray" />
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {count && count > 0 && data && <CommonTable className="mt-4" data={data} actionsHandler={actionsHandler} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
        </div>
    )
}

export default WalletRequests