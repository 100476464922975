import React, { useState } from 'react'
import { default as NewSignup } from './pages/new-signup'
import { default as VerifyOTP } from './pages/otp'
import API from '../../../apis'
import { CommonPopup } from '../..'
import MixPanel from '../../../config/mixpanel'

const Signup = () => {
    const [page, setPage] = useState(0)
    const [data, setData] = useState({})
    const [showPopup, setShowPopup] = useState(false)

    const next = async (nextPage, _data) => {
        if (nextPage === 1) {
            setData(_data)
            setPage(nextPage)
        } if (nextPage === 2) {
            setPage(nextPage)
            const response = await API.agentOnboarding.new(data)
            if (response.status === 200) {
                MixPanel.track(MixPanel.types.onboarding.onboarded)
                setShowPopup("Thank you for submitting your details. Our team will reach out to you soon.")
            } else {
                setShowPopup("Error while submitting your details. Please try again.")
            }
        }
    }

    const redirect = () => {
        window.location.replace("https://sankash.in")
    }

    return (
        <>
            {page === 0 && <NewSignup next={next} />}
            {page === 1 && <div className="flex items-center justify-center w-full px-4 sk-background">
                <div className="w-full md:w-3/4 lg:w-1/2 xl:w-1/3 bg-white py-6 px-8 rounded-xl">
                    <VerifyOTP data={data} changeView={next} API={API.agentOnboarding.verifyOTP.bind(null)} />
                </div>
            </div>}
            {page === 2 && showPopup && <CommonPopup onOk={redirect} onCancel={redirect} icon={"/assets/icons/green-tick.svg"}>{showPopup}</CommonPopup>}
        </>
    )
}

export default Signup