import React, { useEffect, useState } from 'react'
import utils from '../../common/utils.js'
import { CommonFormButton, CommonTable } from '../../index.js'
import { useNavigate } from 'react-router-dom'
import Wallet from '../../../apis/wallet.js'
import Constants from '../../../config/constants.js'

const Home = () => {
    const navigate = useNavigate()
    const [balance, setBalance] = useState(0)
    const [pending, setPending] = useState(null)

    useEffect(() => {
        async function getBalance() {
            const response = await Wallet.getBalance()
            if (response.status === 200 && response.data?.data?.balance) {
                setBalance(response.data?.data?.balance)
                window.sessionStorage.setItem(Constants.SessionVariables.WalletBalance, response.data?.data?.balance)
            }
        }
        getBalance()

        async function pendingRequests() {
            const response = await Wallet.pendingRequests()
            if (response.status === 200 && response.data?.data) {
                if (response.data?.data?.data?.length > 0) setPending(response.data?.data)
            } else if (response.status === 401) {
                navigate("/agent/auth")
            }
        }
        pendingRequests()
    }, [navigate])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Wallet</h6>
            <hr className="mt-3 text-gray" />
            <div className="mt-8 bg-sk-blue rounded-xl p-8 max-w-xl">
                <p className="text-white text-lg font-medium"><img src="/assets/icons/wallet.svg" alt="wallet" className="h-4 inline-block mr-3" />Your wallet balance is</p>
                <div className="flex justify-between mt-6 items-center flex-col lg:flex-row gap-4">
                    <span className="text-5xl text-white font-semibold">{utils.formatINR(balance, 2, true)}</span>
                    <CommonFormButton text="Add Money+" isOutline={true} short={true} className="px-6 text-lg" onClick={() => navigate("add")} />
                </div>
            </div>
            <h6 className="font-semibold mt-8">Pending Requests</h6>
            {pending && <CommonTable data={pending} className="mt-4" />}
            {!pending && <p className="text-sm mt-4">No pending requests.</p>}
        </div>
    )
}

export default Home