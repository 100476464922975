import ga4 from "react-ga4"
const GA_TRACKING_ID = 'G-6N0VT58VXT'

let isInitialized = false

const GA = {
    init: () => {
        ga4.initialize(GA_TRACKING_ID, { testMode: process.env.REACT_APP_ENV === "prod" })
        if (process.env.REACT_APP_ENV === "prod") isInitialized = true
    },
    sendEvent: (category, action, label, value) => {
        if (isInitialized) {
            const options = { category, action }
            if (label) options.label = label
            if (value) options.value = value
            ga4.event(options)
        } else {
            console.log("skipped ga event: " + category + ": " + action)
        }
    },
    sendPageView: path => {
        if (isInitialized) {
            ga4.send({
                hitType: 'pageview',
                page: path
            })
        } else {
            console.log("skipped ga pageview: " + path)
        }
    }
}

export default GA