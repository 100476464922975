import React, { useState } from 'react'
import { CommonFormButton, CommonFormEmailInput, CommonFormTextInput, CommonPopup, CommonFormSearchListInput } from '../..'
import API from '../../../apis'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const Add = () => {
    const [error, setError] = useState(null)
    const [data, changeData] = useState({})
    let [alertFunc, setAlertFunc] = useState(() => () => setError(null))
    const navigate = useNavigate()

    function setData(k = "", v) {
        changeData(prev => ({ ...prev, [k]: v }))
    }

    async function submitData() {
        setError(null)
        const response = await API.backoffice.agent.add(data)
        if (response.status === 200 && response.data?.data) {
            setError("Agent created: " + response.data.data)
            setAlertFunc(() => () => navigate("/backoffice/users/agents/manage"))
            MixPanel.track(MixPanel.types.backoffice.agent.add)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Add Agent</h6>
            <hr className="mt-3 text-gray mb-8" />
            {error && <CommonPopup onOk={alertFunc} onCancel={alertFunc}><div className="whitespace-pre-line">{error}</div></CommonPopup>}
            <div className="grid grid-cols-3 gap-x-4 gap-y-4 mb-8">
                <CommonFormTextInput id="sk-new-agent-name" label="Name*" minLength="3" onChange={(v) => setData("name", v)} />
                <p className="col-span-2"></p>
                <CommonFormTextInput id="sk-new-agent-mobile" type="tel" minLength="10" maxLength="10" label="Mobile Number*" onChange={(v) => setData("mobile", v)} />
                <p className="col-span-2"></p>
                <CommonFormEmailInput id="sk-new-agent-email" label="Email*" onChange={(v) => setData("email", v)} />
                <p className="col-span-2"></p>
                <CommonFormSearchListInput id="sk-new-agent-partner" label="Partner*" searchPlaceholder="Search PSK-XXXX or Company Name" searchAPI={API.backoffice.partner.search} onChange={(v) => setData("partner", v)} />
                <p className="col-span-2"></p>
                {/* <CommonFormSearchListInput id="sk-new-agent-supervisor" label="Supervisor" type="Supervisor" searchAPI={API.backoffice.partner.search} onChange={(v) => setData("supervisor", v)} />
                <p className="col-span-2"></p> */}
                <CommonFormTextInput id="sk-new-agent-password" type="password" minLength="6" label="Password*" onChange={(v) => setData("password", v)} />
                <p className="col-span-2"></p>
                <CommonFormTextInput id="sk-new-agent-confirm-password" type="password" minLength="6" label="Confirm Password*" onChange={(v) => setData("confirmpassword", v)} />
                <p className="col-span-2"></p>
                <CommonFormButton className="w-full" text="Submit" onClick={submitData} />
            </div>
        </div>
    )
}

export default Add