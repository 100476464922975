import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const SearchableInput = (props) => {
    let [isShown, setIsShown] = useState(false)
    let [list, setList] = useState([])
    let searchRef = useRef(null)
    let [helperText, setHelperText] = useState("Loading...")
    let [selectedValue, setSelectedValue] = useState(null)

    function showDropdown(e) {
        if (isShown === false) setIsShown(true)
    }

    function hideDropdown(e) {
        if (isShown === true) {
            setIsShown(false)
            if (searchRef?.current) searchRef.current.value = ""
            setList([])
        }
    }

    useEffect(() => {
        if (isShown === true) {
            document.getElementById(props.id + "_selection").focus()
            async function loadInitial() {
                const apiResponse = await props.searchAPI("")
                if (apiResponse.status === 200) {
                    if (apiResponse.data?.data && Array.isArray(apiResponse.data.data)) {
                        if (apiResponse.data.data.length > 0) setList(apiResponse.data.data)
                        else setHelperText("No results.")
                    }
                }
            }
            loadInitial()
        }
    }, [isShown, props.id, props])

    async function loadList() {
        const search = searchRef?.current?.value
        setList([])
        if (search && typeof search === "string" && search.length >= 3) {
            setHelperText("Loading...")
            const apiResponse = await props.searchAPI(search)
            if (searchRef?.current?.value !== search) return
            if (apiResponse.status === 200 && apiResponse.data?.data && Array.isArray(apiResponse.data.data)) {
                if (apiResponse.data.data.length > 0) setList(apiResponse.data.data)
                else setHelperText("No results.")
            } else {
                setHelperText("API Error.")
            }
        } else {
            setHelperText("No results.")
        }
    }

    function selectValue(e, val) {
        e.preventDefault()
        setSelectedValue(val)
        typeof props.onChange === "function" && props.onChange(val)
        if (searchRef?.current) searchRef.current.value = ""
        setList([])
        setIsShown(false)
    }

    return (
        <div className={"inline-block relative border rounded-xl border-sk-blue py-3 px-6 bg-white focus:outline-none " + (props.className ? props.className : "")} tabIndex="0" onClick={showDropdown} onFocus={showDropdown}>
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold">{props.label}</label>
            <p id={props.id} className={"font-medium w-full bg-white flex flex-col relative " + (!selectedValue && "text-gray-500")}>
                <span>{selectedValue ? selectedValue : (props.placeholder ? props.placeholder : "Select")}</span>
                {selectedValue && <span className="text-sm text-gray-500">{selectedValue.name}</span>}
                <img className="w-3 h-3 inline invert absolute right-0 top-2" src="/assets/icons/arrow-down.svg" alt="arrow" />
            </p>
            <div className={"flex flex-col absolute z-40 shadow top-18 mt-1 left-0 w-full rounded-xl border bg-white border-gray-100 focus:outline-none " + (isShown ? "visible" : "hidden")} onBlur={hideDropdown}>
                <input id={props.id + "_selection"} ref={searchRef} type="text" className="rounded-t-xl h-10 outline-none px-2 border-b border-b-gray-300" placeholder={props.searchPlaceholder} onChange={loadList} />
                {list.length === 0 && <p className="my-3 text-center text-sm text-gray-500">{helperText}</p>}
                {list.length > 0 &&
                    <div className={"max-h-40 " + (list.length > 3 ? "overflow-y-scroll" : "")}>
                        {list.map(x =>
                            <p id={props.id + "_select_" + x.id} key={x.id} className="px-4 py-2 text-sm font-medium w-full rounded-xl bg-white hover:bg-gray-100 cursor-pointer" onPointerDown={(e) => selectValue(e, x.id)}>
                                <span>{x.value}</span><br />
                                <span className="text-sm text-gray-500">{x.id}</span>
                                {x.id === selectedValue && <img src="/assets/icons/green-tick.svg" alt="step" className="w-4 h-4 inline float-right" />}
                            </p>
                        )}
                    </div>
                }
            </div>
        </div>
    )
}

SearchableInput.propTypes = {
    searchPlaceholder: PropTypes.string,
    id: PropTypes.string,
    searchAPI: PropTypes.func,
    onChange: PropTypes.func,
    className: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
}

export default SearchableInput