const monthLookUp = {
    0: "Jan", 1: "Feb", 2: "Mar", 3: "Apr", 4: "May", 5: "Jun", 6: "Jul", 7: "Aug", 8: "Sep", 9: "Oct", 10: "Nov", 11: "Dec"
}
const weekdayLookUp = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const utils = {
    formatINR: function (valueIn, digitsAfterDecimal = 0, includeRupeeSymbol = 0,) {
        if (valueIn === "") return ""

        if (typeof valueIn !== "string") valueIn = valueIn.toString()
        valueIn = valueIn.replace(/[,/d]/g, "")

        let valueOut = new Intl.NumberFormat('en-IN', {
            style: 'currency',
            currency: 'INR',
            maximumFractionDigits: digitsAfterDecimal
        }).format(valueIn).substring(includeRupeeSymbol ? 0 : 1)

        return valueOut
    },
    diffInDays: function (d1, d2) {
        return Math.floor(((new Date(d1)) - (new Date(d2))) / (1000 * 60 * 60 * 24))
    },
    formatDate: function (ISODateString) {
        const date = new Date(ISODateString)
        return date.getDate().toString().padStart(2, "0") + " " + monthLookUp[date.getMonth()] + ", " + date.getFullYear()
    },
    formatInsuranceAge: function (age) {
        if (age < 1) return Math.round(age * 100) + " months"
        else if (age === 1) return age + " year"
        else return age + " years"
    },
    calcFlightDuration: function (mins) {
        if (mins < 60) return `${mins} min`
        else if (mins === 60) return `1 hour`
        else if (mins < 120) return `1 hour ${mins - 60} min`
        else if (mins % 60 === 0) return `${Math.round(mins / 60)} hours`
        else {
            return `${Math.floor(mins / 60)} hours ${mins % 60} min`
        }
    },
    calcFlightDate: function (date) {
        if (!date) return ""
        const dateSplit = date.split(" ")[0].split("-")
        return `${dateSplit[2]} ${monthLookUp[dateSplit[1] * 1 - 1]}`
    },
    calcFlightSearchLongDate: function (dt) {
        if (!dt) return ""
        const date = new Date(dt)
        return weekdayLookUp[date.getDay()] + ", " + date.getDate() + " " + monthLookUp[date.getMonth()] + " " + date.getFullYear().toString().substring(2, 4)
    }
}

export default utils