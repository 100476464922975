import React, { useState } from "react"
import PropTypes from 'prop-types'
import { FaRegCopy } from "react-icons/fa6";
import { FaRegCircleCheck } from "react-icons/fa6";

const Copy = (props) => {
    const [copied, setCopied] = useState(false)

    const copy = function () {
        navigator.clipboard.writeText(props.text)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 1200)
    }

    return (
        <>
            {copied
                ? <FaRegCircleCheck className="ml-1 inline-block -mt-0.5 text-green" />
                : <FaRegCopy className="cursor-pointer ml-1 inline-block -mt-0.5" onClick={copy} />
            }
        </>
    )
}

Copy.propTypes = {
    text: PropTypes.string
}

export default Copy