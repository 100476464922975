import React, { useState, useCallback, useEffect } from 'react';
import { CommonError, CommonLoader, CommonTable, CommonPagination } from '../..';
import API from '../../../apis';
import { useNavigate } from 'react-router-dom';
import MixPanel from '../../../config/mixpanel';

const Manage = () => {
    const navigate = useNavigate();
    let [error, setError] = useState(null);
    let [data, setData] = useState(null);
    let [numPages, setNumPages] = useState(null);
    let [currentPage, setCurrentPage] = useState(null);

    const fetchCount = useCallback(async function () {
        const response = await API.backoffice.partnerLeads.count()
        if (response.status === 200 && response.data?.data) {
            if (response.data.data.count > 0) {
                setNumPages(response.data.data.num_pages)
            } else {
                setError("No Results Found!")
            }
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate])

    const fetchData = useCallback(async function () {
        const response = await API.backoffice.partnerLeads.list(currentPage)
        if (response.status === 200 && response.data?.data) {
            setData(response.data.data)
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError("API Error!")
        }
    }, [navigate, currentPage])

    useEffect(() => {
        fetchCount().then(fetchData)
    }, [fetchCount, fetchData])

    useEffect(() => {
        setError(null)
        setData(null)
        fetchData()
    }, [currentPage, fetchData])

    const newleads = (id) => {
        if (id) {
            navigate(`/backoffice/users/partners/add?q=${id}`);
            MixPanel.track(MixPanel.types.backoffice.partner.newlead)
        } else {
            console.error("No ID provided for navigation");
        }
    };

    return (
        <>
            <div className="flex flex-col">
                <h6 className="font-semibold hamburger-spacing">New Onboardings</h6>
                <hr className="mt-3 text-gray" />
                {error && <CommonError error={error} className="my-4" />}
                {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
                {data &&
                    <CommonTable className="mt-4" data={data} showDetails={(id) => newleads(id)} />
                }
                {data && numPages > 1 &&
                    <div className="my-6 self-end">
                        <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                    </div>
                }
            </div>
        </>
    );
}

export default Manage;
