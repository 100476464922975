import React, { useEffect, useState } from 'react'
import { CommonOfferSelect, CommonPopup, CommonFormButton, CommonLoader } from '../../../index.js'
import customer from '../../../../apis/customer.js'
import utils from '../../../common/utils.js'
import Constants from '../../../../config/constants.js'
import MixPanel from '../../../../config/mixpanel.js'
import { IoIosInformationCircleOutline } from "react-icons/io"

const Offers = (props) => {
    const [error, setError] = useState(null)
    const [selectedOffer, setSelectedOffer] = useState(null)
    const [offers, setOffers] = useState([])
    const [showProceessingFeeLine, setShowProcessingFeeLine] = useState(false)
    const [stopAtApproval, setStopAtApproval] = useState(false)

    useEffect(() => {
        const fetchOffers = async function () {
            const response = await customer.getOffers()
            if (response.status === 200 && "data" in response.data && "offers" in response.data.data && response.data.data.offers.length > 0) {
                const offers = response.data.data.offers
                setOffers(offers)
                setSelectedOffer(offers[0].id)
                setStopAtApproval(response.data.data?.stop_at_approval || false)
                MixPanel.track(MixPanel.types.customer.application.offers_shown)
            } else if (response.status === 401) {
                props.changeView(Constants.CUSTOMER_VIEWS.SESSION_EXPIRED)
            } else {
                setError("API Error!")
            }
        }
        fetchOffers()
    }, [props])

    async function onSubmit() {
        const response = await customer.shareLead({ selectedOffer })
        if (response.status === 200 && "data" in response.data && "url" in response.data.data && response.data.data.url) {
            if (typeof props.changeView === "function") props.changeView(Constants.CUSTOMER_VIEWS.LENDER_REDIRECTION, null, { url: response.data.data.url, logo: offers[0].lenderLogo })
        } else {
            setError(response.error)
        }
    }

    return (
        <div className="flex-1 flex flex-col py-4">
            <div className="flex-1">
                {error && <CommonPopup onOk={() => setError("")} onCancel={() => setError("")}><div>{error}</div></CommonPopup>}
                {offers.length === 0 && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {offers.length > 0 &&
                    <>
                        <p className="">Loan Amount</p>
                        <p className="mt-1 text-xl font-black border-b border-gray-500 pb-3 tracking-wide select-none" onClick={() => setShowProcessingFeeLine(prev => !prev)}>
                            <span className="text-gray-700">&#8377;&nbsp;</span><span>{utils.formatINR(offers[0].loanAmount)}</span><IoIosInformationCircleOutline className="inline-block w-3 h-3 -mt-2 ml-1" />
                            {showProceessingFeeLine && <p className="mt-2 text-xs p-2 border border-1 border-gray-500 text-center font-normal"><IoIosInformationCircleOutline className="inline-block w-3 h-3 mr-1" /> You will be charged upto 3% of the loan amount as processing fee.</p>}
                        </p>
                        {
                            (stopAtApproval || props.data.stop_at_approval) ?
                                <p className="mt-4 font-medium">Congratulations! You are approved.</p> :
                                <p className="mt-4 font-medium">Select an offer</p>
                        }
                        <p className="flex items-center mt-2">
                            <img src={offers[0].lenderLogo} alt="lender" className="h-8 ml-4" />
                            {/* <span className="ml-2">{offers[0].lender}</span> */}
                        </p>
                        <CommonOfferSelect offers={offers} onOfferSelect={setSelectedOffer} selectedOffer={selectedOffer} />
                        {
                            !(stopAtApproval || props.data.stop_at_approval) &&
                            <p className="mt-4 text-xs text-center">On clicking continue, you will be directed to the lending partner’s webpage.</p>
                        }
                    </>
                }
            </div>
            {
                !(stopAtApproval || props.data.stop_at_approval) &&
                <div className="flex-none">
                    <CommonFormButton text="CONTINUE" className="w-full mt-4" onClick={onSubmit} />
                </div>
            }
        </div>
    )
}

export default Offers