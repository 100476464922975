import callapi from "./callapi"
import endpoints from "../config/endpoints"

const paymentCollection = {
    accountStatus: async function () {
        return await callapi({
            method: "get",
            url: endpoints.paymentCollection.accountStatus
        })
    },
    newPaymentRequest: async function (data) {
        const { customer_name, mobile, email, desc, amount } = data

        if (!customer_name) return { error: "Customer Name is required." }
        if (!mobile) return { error: "Mobile Number is required." }
        if (!email) return { error: "Email ID is required." }
        if (!desc) return { error: "Description is required." }
        if (!amount) return { error: "Amount is required." }

        return await callapi({
            method: "post",
            url: endpoints.paymentCollection.new,
            data
        })
    },
    paymentStatus: async function (data) {
        const { payment_id } = data

        return await callapi({
            method: "get",
            url: endpoints.paymentCollection.status(payment_id)
        })
    }
}

export default paymentCollection