import axios from "axios"
import MixPanel from "../config/mixpanel"

axios.defaults.withCredentials = true

export default async function callapi(options) {
    const response = { status: 0, data: null, error: null }
    try {
        const apiResponsePromise = axios(options)
        const forceSlowPromise = new Promise(res => setTimeout(res, 1200))
        const resolvePromises = await Promise.all([apiResponsePromise, forceSlowPromise])
        const apiResponse = resolvePromises[0]
        response.status = apiResponse.status
        response.data = apiResponse.data
        MixPanel.track(MixPanel.types.general.api.success)
    } catch (e) {
        if (e.response) {
            response.status = e.response.status
            if (e.response.data) {
                if ("error" in e.response.data) response.error = e.response.data.error
                else response.data = e.response.data
            } else {
                response.error = "API Error!"
            }
        } else if (e.request) {
            response.error = "API Timeout!"
        } else {
            response.error = 'Unknown Error!'
        }
        MixPanel.track(MixPanel.types.general.api.error, { status_code: e?.response?.status || 0, url: options?.url || "unknown" })
    } finally {
        return response
    }
}