import React, { useState } from 'react'
import { CommonPopup, CommonFormTextInput, CommonFormSelect, CommonFormButton } from '../../..'
import API from '../../../../apis'
import Constants from '../../../../config/constants'
import MixPanel from '../../../../config/mixpanel'

const MoreCustomerDetails = (props) => {
    const [error, setError] = useState(null)
    const [data, updateData] = useState({ mobile: props.data.mobile })

    const setData = function (k, v) {
        updateData(prev => ({ ...prev, [k]: v }))
    }

    async function onSubmit() {
        const response = await API.customer.moreDetails(data)
        if (response.status === 200) {
            const eligibility = response.data?.data?.eligible
            if (eligibility === true) props.changeView(Constants.CUSTOMER_VIEWS.OFFERS)
            else props.changeView(Constants.CUSTOMER_VIEWS.REJECTED)
            MixPanel.track(MixPanel.types.customer.application.form2)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex-1 flex flex-col space-y-4">
            <div className="flex-1 flex flex-col space-y-4 mt-4">
                {error && <CommonPopup small={true} onOk={() => setError("")} onCancel={() => setError("")}><div>{error}</div></CommonPopup>}
                <p className="text-lg font-bold text-center">Fill details to check your loan eligibility.</p>

                <CommonFormTextInput id="sk-cce-pan" label="PAN Number*" minLength="10" maxLength="10" className="col-span-2" onChange={(v) => setData("pan", v)} />
                <CommonFormTextInput id="sk-cce-work-pin" label="Work Place PIN Code*" type="number" min="100000" max="999999" onChange={(v) => setData("work_pincode", v)} />
                <CommonFormTextInput id="sk-cce-home-pin" label="Residential PIN Code*" type="number" min="100000" max="999999" onChange={(v) => setData("home_pincode", v)} />
                <CommonFormSelect id="sk-cce-home-type" label="Residential Type*" onChange={(v) => setData("home_type", v)}>
                    <option value="Owned">Self-Owned</option>
                    <option value="Rented">Rented</option>
                    <option value="Others">Others</option>
                </CommonFormSelect>
                <CommonFormSelect id="sk-cce-employment-type" label="Employment Type*" onChange={(v) => setData("employment_type", v)}>
                    <option value="Self-Employed">Self-Employed</option>
                    <option value="Salaried">Salaried</option>
                    {/* <option value="Others">Others</option> */}
                </CommonFormSelect>

                <p className="col-span-3 text-xs">This eligibility check won't affect your credit score.</p>
            </div>
            <CommonFormButton id="sk-cce-submit" text="CONTINUE" className="col-span-2 mt-3" onClick={onSubmit} />
        </div>
    )
}

export default MoreCustomerDetails