import React, { useState } from 'react'
import { CommonLoader, CommonError, CommonPagination, CommonTable, CommonSearch } from '../..'
import API from '../../../apis'
import { default as ApplicationDetails } from '../../agent/transactions/pages/application-details'
import Constants from '../../../config/constants'
import { useSearchParams } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'
import { default as MarkDisbursed } from './pages/mark-disbursed'
import DisbursementTracker from './pages/disbursement-tracker'

const Transactions = () => {
    let [searchParams] = useSearchParams()
    function getDefaultFilter() {
        const partner = searchParams.get("partner")
        const agent = searchParams.get("agent")
        if (partner || agent) {
            return { search: partner || agent, status: Constants.BACKOFFICE.APPLICATION_STATUS.ALL }
        }

        return { search: null, status: Constants.BACKOFFICE.APPLICATION_STATUS.ALL }
    }

    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [detail, setDetail] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)
    let [seed, setSeed] = useState(Date.now())
    let [showMarkDisbursed, setShowMarkDisbursed] = useState(false)
    let [showDisbursementTracker, setShowDisbursementTracker] = useState(false)

    const actionsTrigger = async function (type, value) {
        if (type === "MARK_DISBURSED") {
            setShowMarkDisbursed(detail)
            return true
        } else if (type === "RESHARE_LINK" || type === "MARK_PF_RECEIVED" || type === "MARK_DUPLICATE" || type === "MARK_TEST") {
            if (type === "MARK_TEST") {
                if (!window.confirm("Are you sure?")) return false
            }
            const response = await API.backoffice.applicationTransactions.update(detail, { [type]: true })
            if (response.status === 200) {
                setSeed(Date.now())
                MixPanel.track(MixPanel.types.backoffice.tnpl.action, { type })
                return true
            }
        } else if (type === "CHANGE_AMOUNT") {
            const response = await API.backoffice.applicationTransactions.update(detail, { [type]: true, value })
            if (response.status === 200) {
                setSeed(Date.now())
                MixPanel.track(MixPanel.types.backoffice.tnpl.action, { type })
                return true
            }
        } else if (type === "DISBURSEMENT_DETAILS") {
            setShowDisbursementTracker(true)
            return true
        }

        return false
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">TNPL Transactions</h6>
            <hr className="mt-3 text-gray" />
            <CommonSearch seed={seed} className="mt-4" context="bo_application" statuses={Object.values(Constants.BACKOFFICE.APPLICATION_STATUS)} defaultFilter={getDefaultFilter()} setError={setError} setData={setData} getCount={API.backoffice.applicationTransactions.count} getList={API.backoffice.applicationTransactions.list} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} showExport={true} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {numPages && numPages > 0 && data && <CommonTable className="mt-4" data={data} showDetails={setDetail} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <ApplicationDetails id={detail} onClose={() => setDetail(null)} detailAPI={API.backoffice.applicationTransactions.detail} showActions={true} actionsTrigger={actionsTrigger} />}
            {showMarkDisbursed && <MarkDisbursed application={detail} onOk={() => setShowMarkDisbursed(false)} onCancel={() => setShowMarkDisbursed(false)} />}
            {showDisbursementTracker && <DisbursementTracker application={detail} onOk={() => setShowDisbursementTracker(false)} onCancel={() => setShowDisbursementTracker(false)} />}
        </div>
    )
}

export default Transactions