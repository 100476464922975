import React from "react"
import { MdLuggage } from "react-icons/md";
import { IoBagHandle } from "react-icons/io5";
import { FaRegRegistered } from "react-icons/fa";
import { HiOutlineViewList } from "react-icons/hi";
import { isMobile } from "react-device-detect";
import { CommonSimpleToolTip } from "../../..";
import Constants from "../../../../config/constants";
import { GiMeal } from "react-icons/gi";
import MixPanel from "../../../../config/mixpanel";

const Selection = ({ type, partner, flightData, expanded, cheapest_fare, searchData, filters, setSelectedFlight, selectedFlight }) => {
    const isSelectedFlightFare = (partner, flight, fareIndex) => {
        return selectedFlight !== null && selectedFlight.partner === partner && selectedFlight.flight.id === flight.id && (selectedFlight.fareIndex === fareIndex)
    }

    const filter_sme_fare = filters?.popularSMEFares === true
    const filter_refundable = filters?.popularRefundable === true
    let faresArr = flightData?.fares || []
    if (filter_sme_fare) faresArr = faresArr?.filter(x => x?.fare_type?.toLowerCase()?.includes("sme"))
    if (filter_refundable) faresArr = faresArr?.filter(x => x?.refund_type > 0)
    const faresToShow = faresArr ? (expanded ? faresArr : type === 3 ? faresArr.slice(0, 2) : [faresArr[0]]) : []
    const fare_for_agent = filters?.showNetPrice

    const selectFlight = (fareIndex, event) => {
        if (event.target.checked) {
            setSelectedFlight({ partner, flight: { ...flightData }, fareIndex })
            MixPanel.track(MixPanel.types.agent.flight.select)
        }
    }

    return (
        <div className="flex flex-col gap-4 border-l border-gray-100 my-2 p-2">
            {faresToShow.map((fare, i) => fare &&
                <div key={i} className="flex flex-col gap-0.5">
                    <div className="flex items-center justify-center gap-1">
                        <input type="radio" name="sk-flight-search-selection" checked={isSelectedFlightFare(partner, flightData, i)} onChange={(e) => selectFlight(i, e)} />
                        <span className={"font-semibold " + (isMobile ? "text-sm " : "")}>{fare_for_agent ? fare.agent_fare : fare.customer_fare}</span>
                    </div>
                    {/* Tags */}
                    <p className={"text-sk-blue text-center mb-0.5 font-semibold " + (isMobile ? "text-[9px]" : "text-[10px]")}>
                        <span>{fare.fare_type?.toUpperCase()?.replaceAll("_", " ")}</span>
                        {fare.customer_fare_raw <= cheapest_fare && <span> | CHEAPEST</span>}
                    </p>
                    {/* Tooltips */}
                    <div className="flex items-center justify-center">
                        {
                            fare?.baggage?.cabin && <CommonSimpleToolTip>
                                <IoBagHandle className="w-3.5 h-3.5 -translate-y-0.5 text-sk-blue-select" />
                                <span className="text-xs font-medium">Hand Baggage: {fare?.baggage?.cabin}</span>
                            </CommonSimpleToolTip>
                        }
                        {
                            fare?.baggage?.checkin && <CommonSimpleToolTip>
                                <MdLuggage className="w-4 h-4 text-sk-blue-select" />
                                <span className="text-xs font-medium">Check-in Baggage: {fare?.baggage?.checkin}</span>
                            </CommonSimpleToolTip>
                        }
                        {
                            fare?.refund_type * 1 > 0 && <CommonSimpleToolTip>
                                <FaRegRegistered className={"w-3 h-3 -translate-y-0.5 " + (fare?.refund_type === 1 ? "text-sk-blue-select" : "text-gray-500")} />
                                <span className="text-xs font-medium">{Constants.FLIGHTS.REFUNDABLE[fare?.refund_type]}</span>
                            </CommonSimpleToolTip>
                        }
                        {
                            fare?.meal && <CommonSimpleToolTip>
                                <GiMeal className="w-4 h-4 translate-y-0.5 text-sk-blue-select" />
                                <span className="text-xs font-medium">Free Meal</span>
                            </CommonSimpleToolTip>
                        }
                        <CommonSimpleToolTip>
                            <HiOutlineViewList className="w-3.5 h-4 ml-0.5 text-sk-blue-select" />
                            <div className="w-50 grid grid-cols-3 text-xs font-medium *:py-1 *:px-2">
                                <span className="col-span-full underline underline-offset-2">Fare Breakup</span>
                                <span className="col-span-1">&nbsp;</span>
                                <span className="col-span-1">Per Pax</span>
                                <span className="col-span-1">Total</span>
                                <span className="col-span-1">Adult</span>
                                <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].adult}</span>
                                <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].adult_total}</span>
                                {searchData.child && <>
                                    <span className="col-span-1">Child</span>
                                    <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].child}</span>
                                    <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].child_total}</span>
                                </>}
                                {searchData.infant && <>
                                    <span className="col-span-1">Infant</span>
                                    <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].infant}</span>
                                    <span className="col-span-1">{fare[fare_for_agent ? "agent_fare_breakup" : "customer_fare_breakup"].infant_total}</span>
                                </>}
                            </div>
                        </CommonSimpleToolTip>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Selection