import React, { useState } from 'react'
import { default as SingleOffer } from "./single-offer"

const OfferSelect = (props) => {
    let [selectedOfferId, setSelectedOfferId] = useState(props.selectedOffer || null)

    function selectOffer(id) {
        const newSelectedOffer = id === selectedOfferId ? null : id
        setSelectedOfferId(newSelectedOffer)
        if (typeof props.onOfferSelect === "function") props.onOfferSelect(newSelectedOffer)
    }

    return (
        <div className={" " + (props.className ? props.className : "")}>
            {props.offers.map(offer =>
                <SingleOffer key={offer.id} id={offer.id} offer={offer} className="my-2 cursor-pointer" showSelection={true} selected={selectedOfferId === offer.id} onClick={() => selectOffer(offer.id)} />
            )}
        </div>
    )
}

export default OfferSelect