import React, { useState } from 'react'
import { CommonPopup, CommonFormTextInput, CommonFormButton } from '../../..'
import { Link } from 'react-router-dom'
import constants from '../../../../config/constants.js'
import MixPanel from '../../../../config/mixpanel.js'

const Mobile = (props) => {
    const [error, setError] = useState(null)
    const [mobile, setMobile] = useState(props.data.mobile || null)

    async function onSubmit() {
        if (!mobile) return setError("Please enter your mobile number.")
        if (mobile.length !== 10) return setError("Mobile number should be 10 digits.")
        if (isNaN(mobile) || !(["6", "7", "8", "9"].some(x => x === mobile.toString().charAt(0)))) return setError("Mobile number is invalid.")

        props.changeView(constants.CUSTOMER_VIEWS.SEND_OTP, null, { mobile })
        MixPanel.track(MixPanel.types.customer.auth.enter_mobile)
    }

    return (
        <div className="flex-1 flex flex-col justify-between">
            {error && <CommonPopup small={true} onOk={() => setError("")} onCancel={() => setError("")}><div>{error}</div></CommonPopup>}
            <div className="mt-4 flex-1">
                <p className="text-lg font-bold mt-12">Let's get you started</p>
                <CommonFormTextInput id="sk-cce-mobile" label="Mobile Number*" type="tel" minLength="10" maxLength="10" onChange={setMobile} value={props?.data?.mobile || ""} disabled={props.data.mobile && props.data.lockMobile === true ? true : false} className="mt-4" />
            </div>
            <div className="">
                <p className="mt-12 text-xs">By creating an account, I agree to the Sankash <Link to="https://sankash.in/assets/documents/terms-of-use.pdf" target="_blank" className="text-sk-blue underline">Terms of Service</Link> and <Link to="https://sankash.in/assets/documents/privacy-policy.pdf" target="_blank" className="text-sk-blue underline">Privacy Policy</Link>.</p>
                <CommonFormButton text="SEND OTP" className="w-full mt-8" onClick={onSubmit} />
            </div>
        </div>
    )
}

export default Mobile