import React, { useState, useEffect, useCallback } from 'react'
import { default as Mobile } from './pages/mobile.jsx'
import { default as SendOTP } from './pages/send-otp.jsx'
import { default as EnterOTP } from './pages/enter-otp.jsx'
import { default as CustomerDetails } from './pages/customer-details.jsx'
import { default as MoreCustomerDetails } from './pages/more-customer-details.jsx'
import { default as Rejection } from './pages/rejection.jsx'
import { default as Offers } from './pages/offers.jsx'
import { default as Redirection } from './pages/redirection.jsx'
import { default as ProcessingFee } from './pages/processing-fee.jsx'
import { default as Complete } from './pages/complete.jsx'
import { CommonLoader, UseScript } from '../../index.js'
import { useSearchParams } from 'react-router-dom'
import Customer from '../../../apis/customer.js'
import constants from '../../../config/constants.js'
import MixPanel from '../../../config/mixpanel.js'

const ContinueApplication = () => {
    UseScript("https://checkout.razorpay.com/v1/checkout.js")
    const [currentView, changeCurrentView] = useState({ view: constants.CUSTOMER_VIEWS.PLEASE_WAIT, applicationNumber: null, data: { flowType: 1 }, error: null })
    const [searchParams] = useSearchParams()
    const changeView = useCallback((v, a, d, e) => {
        changeCurrentView(prev => ({ view: v || prev.view, applicationNumber: a || prev.applicationNumber, data: { ...prev.data, ...(d || {}) }, error: e || null }))
        if (v === constants.CUSTOMER_VIEWS.SESSION_EXPIRED) MixPanel.track(MixPanel.types.customer.application.session_expired)
        else if (v === constants.CUSTOMER_VIEWS.API_ERROR) MixPanel.track(MixPanel.types.customer.application.api_error)
        else if (v === constants.CUSTOMER_VIEWS.INTERNAL_ERROR) MixPanel.track(MixPanel.types.customer.application.internal_error)
    }, [])

    // useEffect(() => console.log(currentView), [currentView])

    const handleApplicationState = useCallback(async function ({ page, applicationNumber, data }) {
        if (page === "rejected") {
            changeView(constants.CUSTOMER_VIEWS.REJECTED, applicationNumber, data)
            return
        } else if (page === "expired") {
            changeView(constants.CUSTOMER_VIEWS.EXPIRED, applicationNumber, data)
            MixPanel.track(MixPanel.types.customer.application.expired)
            return
        }

        const sessionExists = await checkUserIsLoggedIn(applicationNumber)

        if (!sessionExists.logged_in) {
            if (data.otp_verified) {
                // session has expired
                changeView(constants.CUSTOMER_VIEWS.SESSION_EXPIRED)
            } else {
                if (page === "new") {
                    changeView(constants.CUSTOMER_VIEWS.MOBILE_NUMER, applicationNumber, { ...data, flowType: 2, ...sessionExists.data })
                } else {
                    changeView(constants.CUSTOMER_VIEWS.SEND_OTP, applicationNumber, data)
                }
            }
            return
        }

        page = sessionExists.data.page

        if (page === "new") {
            changeView(constants.CUSTOMER_VIEWS.DETAILS, applicationNumber, { ...data, flowType: 2, ...sessionExists.data })
        } else if (page === "more_details") {
            changeView(constants.CUSTOMER_VIEWS.MORE_DETAILS, applicationNumber, { ...data, flowType: 2 })
        } else if (page === "offer") {
            changeView(constants.CUSTOMER_VIEWS.OFFERS, applicationNumber, data)
        } else if (page === "rejected") {
            changeView(constants.CUSTOMER_VIEWS.REJECTED, applicationNumber, data)
        } else if (page === "redirect") {
            changeView(constants.CUSTOMER_VIEWS.LENDER_REDIRECTION, applicationNumber, { ...data, logo: sessionExists.data.logo, url: sessionExists.data.redirect_url })
        } else if (page === "in_progress") {
            changeView(constants.CUSTOMER_VIEWS.LENDER_REDIRECTION, applicationNumber, { ...data, logo: sessionExists.data.logo, url: sessionExists.data.redirect_url })
            // changeView(constants.CUSTOMER_VIEWS.UNDER_PROGRESS, applicationNumber, data)
            // MixPanel.track(MixPanel.types.customer.application.in_progress)
        } else if (page === "pfee") {
            changeView(constants.CUSTOMER_VIEWS.PROCESSING_FEE, applicationNumber, data)
        } else if (page === "complete") {
            changeView(constants.CUSTOMER_VIEWS.COMPLETE, applicationNumber, data)
        } else if (page === "expired") {
            changeView(constants.CUSTOMER_VIEWS.EXPIRED, applicationNumber, data)
        } else {
            changeView(constants.CUSTOMER_VIEWS.API_ERROR, applicationNumber, data)
        }
    }, [changeView])

    const checkUserIsLoggedIn = async function (applicationNumber) {
        const response = await Customer.check(applicationNumber)
        if (response.status === 200 && response.data?.data?.page) return { data: response.data.data, logged_in: true }
        else return { data: response.data.data, logged_in: false }
    }

    const checkApplicationStatus = useCallback(async function (otp_verified) {
        try {
            const tokenbase64 = searchParams.get("token")
            if (!tokenbase64) throw new Error(`no token`)
            const token = atob(tokenbase64)
            const { application_number: applicationNumber, page, data, processing_fee, amount, stop_at_approval } = JSON.parse(token)

            MixPanel.identify("app_" + (applicationNumber.split("-").pop() * 1))

            if (!applicationNumber || !page || !data) throw new Error(`data missing in token`)

            handleApplicationState({ page, applicationNumber, data: { data, processing_fee, otp_verified, amount, stop_at_approval } })
        } catch (e) {
            changeView(constants.CUSTOMER_VIEWS.INTERNAL_ERROR)
        }
    }, [changeView, handleApplicationState, searchParams])

    useEffect(() => {
        checkApplicationStatus(false)
    }, [checkApplicationStatus])

    return (
        <>
            {/* initial screen */}
            {currentView.view === constants.CUSTOMER_VIEWS.PLEASE_WAIT && <div className="flex-1 flex items-center justify-center"><p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p></div>}

            {currentView.view === constants.CUSTOMER_VIEWS.MOBILE_NUMER && <Mobile changeView={changeView} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.SEND_OTP && <SendOTP changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.ENTER_OTP && <EnterOTP checkApplicationStatus={checkApplicationStatus} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.DETAILS && <CustomerDetails changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.MORE_DETAILS && <MoreCustomerDetails changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.REJECTED && <Rejection applicationNumber={currentView.applicationNumber} />}
            {currentView.view === constants.CUSTOMER_VIEWS.OFFERS && <Offers changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.LENDER_REDIRECTION && <Redirection changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.PROCESSING_FEE && <ProcessingFee changeView={changeView} applicationNumber={currentView.applicationNumber} data={currentView.data} />}
            {currentView.view === constants.CUSTOMER_VIEWS.COMPLETE && <Complete applicationNumber={currentView.applicationNumber} />}

            {/* application expired */}
            {currentView.view === constants.CUSTOMER_VIEWS.EXPIRED && <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6"><img src="/assets/icons/exclamation.svg" alt="" /><p className="text-center text-xl">Application expired!</p><p>Your application has expired due to inactivity. Please create a new application.</p></div>}

            {/* application under process */}
            {currentView.view === constants.CUSTOMER_VIEWS.UNDER_PROGRESS && <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6"><img src="/assets/icons/exclamation.svg" alt="" /><p className="text-center text-xl">Application in progress!</p><p>Your application is under processing at the lender's end. Kindly reach out to your travel partner for updates.</p></div>}

            {/* session expired */}
            {currentView.view === constants.CUSTOMER_VIEWS.SESSION_EXPIRED && <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6"><img src="/assets/icons/exclamation.svg" alt="" /><p className="text-center text-xl">Session Expired!</p><p>Your session has expired due to inactivity. Please refresh the page and start again.</p></div>}

            {/* api error */}
            {currentView.view === constants.CUSTOMER_VIEWS.API_ERROR && <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6"><img src="/assets/icons/exclamation.svg" alt="" /><p className="text-center text-xl">API Error!</p><p>{currentView.error}</p></div>}

            {/* unknown error */}
            {currentView.view === constants.CUSTOMER_VIEWS.INTERNAL_ERROR && <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6"><img src="/assets/icons/exclamation.svg" alt="" /><p className="text-center text-xl">Something went wrong!</p><p>We're facing technical difficulties in processing your application. Please reach out to your travel partner for more information.</p></div>}
        </>
    )
}

export default ContinueApplication