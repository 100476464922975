import React, { useState, useRef } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonError, CommonFormEmailInput } from '../../../index.js'
import auth from '../../../../apis/auth.js'
import MixPanel from '../../../../config/mixpanel.js'
import { IoMdArrowBack } from "react-icons/io";

const Mobile = (props) => {
    let [error, setError] = useState(null)
    let mobileRef = useRef(null)
    let emailRef = useRef(null)

    async function sendOTP() {
        setError(null)
        const mobile = mobileRef?.current?.value
        const email = emailRef?.current?.value
        const response = await auth.sendOTP({ mobile, email }, props.email)

        if (response.status === 200 && response.data?.data?.mid) {
            props.changeView(1, { mid: response.data.data.mid, sent_to: response.data.data.sent_to })
            MixPanel.track(MixPanel.types.auth.forgot_password.otp_generated)
        } else {
            setError(response.error)
        }
    }

    return (
        <div>
            <p className="text-gray-700 uppercase text-sm cursor-pointer font-medium" onClick={() => props.changeView(4)}><IoMdArrowBack className="inline align-middle mr-1" /> <span>Back</span></p>
            <p className="text-xl font-medium mt-5 mb-3 text-sk-blue">Forgot Password</p>
            <p className="mb-6">Please enter your {props.email ? "Email Address" : "Mobile Number"}.</p>
            {error && <CommonError error={error} className="text-center my-2" />}
            {!props.email && <CommonFormTextInput inputRef={mobileRef} id="sk-forgot-password-mobile" type="tel" minLength="10" maxLength="10" label="Mobile Number" className="mb-6" />}
            {props.email && <CommonFormEmailInput inputRef={emailRef} id="sk-forgot-password-email" label="Email" className="mb-6" />}
            <CommonFormButton text="SEND OTP" className="mt-10 mb-2 w-full" onClick={sendOTP} />
        </div>
    )
}

export default Mobile