import React, { useState } from 'react'
import { CommonLinkButton } from '../../../index.js'
import { default as PGChargesRazorpay } from './pg-charges-razorpay.jsx'

const RazorpayNotActive = () => {
    const [showPGCharges, setShowPGCharges] = useState(false)

    return (
        <div className="mt-4 mb-4 select-none">
            <p>
                Collect payment from your customer using Payment gateway services powered by Razorpay. <br />
                <span className="text-sm text-sk-blue underline underline-offset-4 font-medium cursor-pointer" onClick={() => setShowPGCharges(true)}>(Click here to check PG charges)</span>
            </p>
            <p className="text-xl font-medium text-red mt-8">Sorry!</p>
            <p className="mt-2 text-red">Payment Collection service is not active for you. To activate please complete one-time RazorPay KYC registration.</p>
            <img src="/assets/razorpay-complete-kyc.png" alt="complete kyc" className="my-8" />
            <CommonLinkButton text="Complete Razorpay KYC Registration" className="w-full lg:w-96" to="https://rzp.io/i/oIAZEaKP0" newPage={true} />
            <p className="mt-8">Powered By <img src="/assets/razorpay-logo.png" alt="razorpay logo" className="inline-block h-5 ml-2" /></p>
            {showPGCharges && <PGChargesRazorpay hide={() => setShowPGCharges(false)} />}
        </div>
    )
}

export default RazorpayNotActive