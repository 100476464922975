import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonFormSelect, CommonError } from '../../../index'
import application from '../../../../apis/application'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel'

const MoreCustomerDetails = (props) => {
    const [data, setData] = useState({})
    let [error, setError] = useState(null)
    const navigate = useNavigate()

    function onDataChange(property, value) {
        setData({ ...data, [property]: value })
    }

    async function onSubmit() {
        setError(null)

        const response = await application.moreDetails(props.applicationNumber, data)
        if (response.status === 200) {
            const eligibility = response.data?.data?.eligible
            if (eligibility === true) props.next(2, response.data?.data?.applicationNumber)
            else props.next(3, response.data?.data?.applicationNumber)
            MixPanel.track(MixPanel.types.agent.application.form2)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError(response.error)
        }
        props.formSubmit(2)
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Eligibility Check</h6>
            <hr className="mt-3 text-gray" />
            <p className="mt-6 text-lg font-medium">Need more customer's details to know their Loan Eligibility.</p>
            {error && <CommonError error={error} className="mt-2 col-span-3" />}
            <form className="mt-4 grid grid-cols-6 gap-4" autoComplete="off">

                <CommonFormTextInput id="sk-eg-pan" label="Customer's PAN Number*" maxLength="10" onChange={(v) => onDataChange("pan", v)} className="col-span-6 lg:col-span-6 xl:col-span-4" />

                <CommonFormTextInput id="sk-eg-work-pin" label="Work Place PIN Code*" type="number" min="100000" max="999999" onChange={(v) => onDataChange("work_pincode", v)} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1" />

                <CommonFormTextInput id="sk-eg-home-pin" label="Residential PIN Code*" type="number" min="100000" max="999999" onChange={(v) => onDataChange("home_pincode", v)} className="col-span-6 lg:col-span-3 xl:col-span-2" />

                <CommonFormSelect id="sk-eg-home-type" label="Customer's Residential Type*" onChange={(v) => onDataChange("home_type", v)} className="col-span-6 lg:col-span-3 xl:col-span-2 xl:col-start-1">
                    <option value="Owned">Self-Owned</option>
                    <option value="Rented">Rented</option>
                    <option value="Others">Others</option>
                </CommonFormSelect>

                <CommonFormSelect id="sk-eg-employment-type" label="Customer's Employment Type*" onChange={(v) => onDataChange("employment_type", v)} className="col-span-6 lg:col-span-3 xl:col-span-2">
                    <option value="Self-Employed">Self-Employed</option>
                    <option value="Salaried">Salaried</option>
                    {/* <option value="Others">Others</option> */}
                </CommonFormSelect>

                <p className="col-span-6 col-start-1 text-xs">This eligibility check won't affect customer's credit score.</p>
                <CommonFormButton id="sk-eg-submit" text="Continue" className="col-span-6 lg:col-span-6 xl:col-span-4" onClick={onSubmit} />
            </form>
        </div>
    )
}

export default MoreCustomerDetails