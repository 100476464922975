import React, { useState } from 'react'
import utils from '../../common/utils'
import PropTypes from 'prop-types'

const CurrencyInput = (props) => {
    const [value, setValue] = useState([props.value || "", props.value ? utils.formatINR(props.value) : ""])

    function focusInput(e) {
        e.preventDefault()
        document.getElementById(props.id).focus()
    }

    function onInputFocused(e) {
        e.preventDefault()
        document.getElementById(props.id).type = "number"
        document.getElementById(props.id).value = value[0]
    }

    function onBlur() {
        document.getElementById(props.id).type = "text"
        document.getElementById(props.id).value = value[1]
    }

    function onChange(e) {
        if (document.getElementById(props.id).type === "number") {
            setValue([e.target.value, utils.formatINR(e.target.value)])
            if (typeof props.onChange === "function") props.onChange(e.target.value)
        }
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse " + (props.className ? props.className : "") + " " + (props.disabled && "bg-gray-100 bg-opacity-50")} onClick={focusInput}>
            <input ref={props.inputRef || null} id={props.id} type="text" min={props.min || ""} max={props.max || ""} className="flex-1 peer focus:outline-none font-medium inline-block w-full pl-3" onChange={onChange} onBlur={onBlur} onFocus={onInputFocused} disabled={props.disabled || false} defaultValue={value[1]} />
            <span className={"absolute " + (props.label ? "top-8 left-6" : "top-3 left-5")}>&#8377;</span>
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

CurrencyInput.propTypes = {
    value: PropTypes.number,
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    min: PropTypes.string,
    max: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool
}

export default CurrencyInput