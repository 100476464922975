import callapi from "./callapi"
import endpoints from "../config/endpoints"

const onboarding = {
    cashfree: async function () {
        return await callapi({
            method: "get",
            url: endpoints.onboarding.cashfree

        })
    },
    cashfreeData: async function (data) {
        return await callapi({
            method: "post",
            url: endpoints.onboarding.cashfree,
            data

        })
    },
    cashfreeURL: async function (data) {
        return await callapi({
            method: "get",
            url: endpoints.onboarding.cashfreeURL,
            data

        })
    }
}

export default onboarding;