import React, { useEffect, useState } from 'react'
import { CommonDropdown } from '../../index.js'
import { Link } from 'react-router-dom'
import constants from '../../../config/constants.js'
import utils from '../../common/utils.js'

const Header = (props) => {
    const [balance, setBalance] = useState(window.sessionStorage.getItem(constants.SessionVariables.WalletBalance) || 0)
    const [initials, setInitials] = useState(window.sessionStorage.getItem(constants.SessionVariables.UserInitials) || 'SK')

    useEffect(() => {
        window.addEventListener('storage', function (e) {
            if (e.key === constants.SessionVariables.WalletBalance) {
                setBalance(window.sessionStorage.getItem(constants.SessionVariables.WalletBalance) || 0)
            } else if (e.key === constants.SessionVariables.UserInitials) {
                setInitials(window.sessionStorage.getItem(constants.SessionVariables.UserInitials) || 'SK')
            }
        })
    }, [])

    return (
        <div className={"flex flex-col " + (props.className || "")}>
            <div className="flex flex-row justify-end gap-x-10 mr-14 mt-5">
                {props.notifications && <div className="relative">
                    <img className="inline-block w-5 h-5 mr-3" src="/assets/icons/notifications.svg" alt="notifications icon" />
                    <span className="text-white font-bold rounded-full text-xs bg-red px-1 absolute -top-1 left-3">2</span>
                    <span className="text-sm font-semibold">Notifications</span>
                </div>}
                {props.wallet && <Link to="/agent/wallet">
                    <img className="inline-block w-6 h-6 mr-2" src="/assets/icons/wallet-blue.svg" alt="wallet icon" />
                    <span className="text-sm font-semibold mr-2">&#8377; {utils.formatINR(balance || 0, 2)}</span>
                </Link>}
                <div>
                    <CommonDropdown id="sk-header-user-dropdown" dropdownId="sk-header-user-dropdown-menu" rotateElementId="sk-header-user-dropdown-rotate">
                        <div className="select-none">
                            <div className="relative inline-flex items-center justify-center w-6 h-6 overflow-hidden bg-sk-blue rounded-full">
                                <span className="font-extrabold text-white text-xs">{initials || 'SK'}</span>
                            </div>
                            <span className="text-sm font-semibold mx-2">Welcome {window.sessionStorage.getItem(constants.SessionVariables.FirstName)}</span>
                            <img id="sk-header-user-dropdown-rotate" className="inline-block h-2 w-2 transition" src="/assets/icons/arrow-down-gray.svg" alt="expand" />
                        </div>
                        <Link to={props.base + "/user/change-password"}><span className="block px-4 py-2 hover:bg-gray-100 select-none">Change Password</span></Link>
                        <Link to={props.base + "/auth/logout"}><span className="block px-4 py-2 hover:bg-gray-100 select-none">Logout</span></Link>
                    </CommonDropdown>
                </div>
            </div>
        </div>
    )
}

export default Header