import React, { useState } from 'react'
import { CommonFormButton, CommonError, CommonOTP } from '../../../index.js'
import MixPanel from '../../../../config/mixpanel.js'

const OTP = (props) => {
    let [error, setError] = useState(null)
    let [otp, setOtp] = useState(null)

    async function checkOTP() {
        setError(null)
        const response = await props.API({ otp, mid: props.data.mid })
        if (response.status === 200 && response.data?.data) {
            props.changeView(2, { mid: response.data.data })
            MixPanel.track(MixPanel.types.onboarding.otp_entered)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div>
            <p className="text-xl font-medium mt-5 mb-3 text-sk-blue">Enter OTP</p>
            <p className="mb-6">Please enter the OTP sent on <span className="tracking-widest text-sk-blue">{props.sentTo || props?.data?.mobile}</span>.</p>
            {error && <CommonError error={error} className="text-center my-2" />}
            <CommonOTP onChange={setOtp} resendIn={null} />
            <CommonFormButton text="VERIFY" className="w-full mt-10 mb-2" onClick={checkOTP} />
        </div>
    )
}

export default OTP