import React, { useEffect, useState } from "react"
import { CommonLoader } from "../../.."
import API from "../../../../apis"
import Constants from "../../../../config/constants"
import MixPanel from "../../../../config/mixpanel"

const displayImages = Constants.FLIGHTS.PARTNER_LOGOS
const numImages = Object.keys(displayImages).length

const DoSearch = ({ data, setView }) => {
    const [i, setI] = useState(0)

    for (const image in displayImages) {
        const imgTag = new Image()
        imgTag.src = displayImages[image]
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            setI(prev => {
                let next = prev + 1
                if (next >= numImages) next = 0
                return next
            })
        }, 2000)
        return () => clearTimeout(timeout)
    }, [i])

    useEffect(() => {
        const search = async () => {
            const search_data = {
                "from": data.from,
                "to": data.to,
                "departure": data.departure_date,
                "travellers": {
                    "adult": data.adult,
                    "child": data.child,
                    "infant": data.infant
                },
                "class": data.travel_class,
                "special_fare": data.fare_type
            }
            if (data.return_date) search_data.return = data.return_date
            const response = await API.flights.search(search_data)
            if (response.status === 200 && response.data?.data) setView(2, null, response.data.data, response.data.is_intl ? 3 : 1)
            else setView(9, null, null, response.error || "API Error")
        }
        search()
        MixPanel.track(MixPanel.types.agent.flight.search)
    }, [setView, data])

    return (
        <div className="mt-4 flex flex-col items-center justify-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />
            <p className="text-center">Searching...</p>
            {numImages > 0 && <p className="text-center"><img src={Object.values(displayImages)[i]} className="max-h-52 max-w-52" alt="partner" /></p>}
        </div>
    )
}

export default DoSearch