import React from 'react'
import Copy from './copy';

const Metrics = (props) => {
    const data = props.data

    return (
        <div className="flex">
            {data && <div className={"flex-1 flex space-x-4 *:flex-1 *:p-4 *:bg-sk-blue *:bg-opacity-10 *:text-center *:text-xs *:font-bold *:rounded-xl " + (props.className || "")}>
                {data.map((x, i) =>
                    <p key={i}>
                        <span className="text-sk-blue">{x[0]}</span><br />
                        <span className="text-sm">
                            {x[1]}
                            {x[2] && <Copy text={x[1]} />}
                        </span>
                    </p>
                )}
            </div>}
        </div>
    )
}

export default Metrics