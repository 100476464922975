import React, { useState } from 'react'
import { CommonError, CommonPopup, CommonFormTextInput, CommonFormButton } from '../..'
import { useNavigate } from 'react-router-dom'
import useScript from '../../common/useScripts'
import API from '../../../apis'

const QRMapping = () => {
    const [error, setError] = useState(null)
    const [data, changeData] = useState({})
    const [completed, setCompleted] = useState(false)
    const navigate = useNavigate()

    useScript("https://unpkg.com/html5-qrcode")

    const setData = function (k, v) {
        changeData(prev => ({ ...prev, [k]: v }))
    }

    const startScanning = function () {
        if (!data.partner) return setError("Please enter partner id.")

        const config = { fps: 10, qrbox: { width: 250, height: 250 } }
        let html5QrCode = new window.Html5Qrcode("sk-backoffice-map-qr-reader")
        const onScanSuccess = function (decodedText, decodedResult) {
            setError(null)
            html5QrCode.stop()
            if (decodedText && typeof decodedText === "string" && decodedText.toLowerCase().startsWith("https://sankash.in/")) {
                setData("code", decodedText)
            } else {
                setError("Invalid QR Code. Please scan again.")
            }
        }

        const onScanFailure = function () {
            setError("Scan failed. Please scan again.")
        }

        html5QrCode.start({ facingMode: "environment" }, config, onScanSuccess, onScanFailure)
        setTimeout(() => {
            if (html5QrCode && html5QrCode.getState() === 2) html5QrCode.stop()
        }, 60000)
    }

    const submit = async function () {
        setError(null)
        const response = await API.backoffice.partner.qrCode(data)
        if (response.status === 200) {
            setCompleted(true)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Scan QR Code</h6>
            <hr className="mt-3 text-gray" />
            {completed && <CommonPopup onOk={() => navigate("./../")} onCancel={() => navigate("./../")}><div className="whitespace-pre-line">QR Code has been mapped.</div></CommonPopup>}
            <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 xl:w-1/3 mt-4 space-y-4">
                {error && <CommonError error={error} />}
                <CommonFormTextInput id="sk-backoffice-map-qr-partner" label="Partner PSK ID" onChange={v => setData("partner", v)} placeholder="PSK-XXXXXXX" />
                <CommonFormButton text="Start Scan" onClick={startScanning} />
                <div id="sk-backoffice-map-qr-reader" className="w-full max-w-[600px]"></div>
                <CommonFormTextInput id="sk-backoffice-map-qr-code" label="Scanned QR Code" disabled={true} value={data.code || ""} />
                <CommonFormButton text="Submit" onClick={submit} disabled={!("code" in data && data.code)} />
            </div>
        </div>
    )
}

export default QRMapping