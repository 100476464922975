import React from 'react'
import { isMobile } from 'react-device-detect'

const insuranceSteps = (props) => {
    const stepLabels = {
        "1": "Search Insurance",
        "2": "Select Premium",
        "3": "Passenger Details",
        "4": "Review & Pay",
        "5": "Download Policy"
    }

    let shownSteps = ["1", "2", "3", "4", "5"]
    if (isMobile) {
        if (props.currentStep === 1 || props.currentStep === 2) shownSteps = ["1", "2", "3", "0"]
        else if (props.currentStep === 3) shownSteps = ["0", "2", "3", "4", "0"]
        else if (props.currentStep === 4 || props.currentStep === 5) shownSteps = ["0", "3", "4", "5"]
        else throw new Error(`invalid current step`)
    }

    return (
        <div className="flex gap-x-2 justify-center md:justify-start">
            {shownSteps.map(step =>
                step === "0" ? "..." :
                    <div key={step} className={"flex flex-col items-center " + (isMobile ? "w-20" : "w-28")}>
                        <div className={"w-6 h-6 rounded-full flex justify-center items-center font-bold " + (step <= props.currentStep ? "bg-sk-blue text-white" : "bg-gray bg-opacity-20 text-gray-700")}>
                            <small className={"font-xs " + (step < props.currentStep && "rotate-6")}>{step < props.currentStep ? <img src="/assets/icons/tick.svg" alt="step" className="w-4 h-4 inline -mt-0.5 invert" /> : step}</small>
                        </div>
                        <p className={"font-semibold text-center mt-2 text-xs " + (step <= props.currentStep ? "text-sk-blue" : "text-gray-700")}>{stepLabels[step]}</p>
                    </div>
            )}
        </div>
    )
}

export default insuranceSteps