import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { default as Mobile } from './pages/mobile'
import { default as OTP } from './pages/otp'
import { default as Password } from './pages/password'
import { CommonPopup } from '../../index.js'

const ForgotPassword = (props) => {
    const navigate = useNavigate()
    const [view, setView] = useState({ view: 0, data: {} })
    const [showPopup, setShowPopup] = useState(false)

    function changeView(_view, _data) {
        if (_view === 3) setShowPopup(true)
        else if (_view === 4) {
            redirect()
        } else setView({ view: _view, data: { ...view.data, ..._data } })
    }

    function redirect() {
        navigate(props.base + "/auth")
    }

    return (
        <div className="w-full h-full flex flex-col items-center justify-center sk-background">
            {showPopup &&
                <CommonPopup onOk={redirect} onCancel={redirect} icon={"/assets/icons/green-tick.svg"}>
                    Your password has been changed successfully.
                </CommonPopup>
            }
            <img src="/assets/sankash-logo.svg" alt="Sankash" className="w-48 mr-5 md:self-end" />
            <div className="flex flex-col bg-white mx-4 px-8 md:px-16 py-8 md:py-12 rounded-xl shadow-2xl md:*:w-96 *:w-full">
                {view.view === 0 && <Mobile changeView={changeView} email={props.email} />}
                {view.view === 1 && <OTP changeView={changeView} mid={view.data?.mid} sentTo={view.data?.sent_to} />}
                {view.view === 2 && <Password changeView={changeView} attemptMid={view.data?.attemptMid} />}
            </div>
        </div>
    )
}

export default ForgotPassword