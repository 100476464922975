import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Wallet = {
    getBalance: async function () {
        return callapi({
            method: "get",
            url: endpoints.wallet.balance
        })
    },
    newRequest: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.wallet.newRequest,
            data
        })
    },
    pendingRequests: async function () {
        return callapi({
            method: "get",
            url: endpoints.wallet.pendingRequests
        })
    },
    newPayment: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.wallet.newPayment,
            data
        })
    },
    confirmPayment: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.wallet.confirmPayment,
            data
        })
    },
    checkPromoCode: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.wallet.checkPromoCode,
            data
        })
    }
}

export default Wallet