import React, { useEffect, useState } from 'react'
import { CommonAccordion, CommonOfferSelect, CommonFormButton, CommonLoader, CommonPopup } from '../../../index'
import utils from '../../../common/utils'
import { default as ItineraryDetails } from './itinerary-details'
import application from '../../../../apis/application'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel'

const Eligible = (props) => {
    let [offers, setOffers] = useState([])
    const navigate = useNavigate()
    let [alert, setAlert] = useState(null)
    let [data, setData] = useState({})
    const [searchParams] = useSearchParams()

    useEffect(() => {
        async function getOffers(applicationNumber) {
            const offerResponse = await application.getOffers(applicationNumber)
            if (offerResponse.status === 200) {
                const offers = offerResponse.data.data.offers
                setOffers(offers)
                updateData("selected_offer", offers[0].id)
                if (searchParams.get("show_itinerary") === "true") {
                    updateData("show_itinerary", true)
                }
                MixPanel.track(MixPanel.types.agent.application.offers_shown)
            } else if (offerResponse.status === 401) {
                navigate("/agent/auth")
            } else {
                navigate("/agent/error")
            }
        }
        getOffers(props.applicationNumber)
    }, [props.applicationNumber, navigate, searchParams])

    async function onSubmit() {
        const response = await application.sendLink(props.applicationNumber, data)
        if (response.status === 200) {
            setAlert("Link successfully shared with the customer.")
            MixPanel.track(MixPanel.types.agent.application.link_shared)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setAlert(response.error)
        }
    }

    async function onAlertOk() {
        if (alert && typeof alert === "string" && alert.includes("successfully shared")) props.next(8)
        setAlert(null)
    }

    const updateData = function (k, v) {
        setData(prev => {
            if (Array.isArray(k)) {
                let newData = prev
                for (let i = 0; i < k.length; i++) {
                    newData = { ...newData, [k[i]]: v[i] }
                }
                return newData
            } else {
                return { ...prev, [k]: v }
            }
        })
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Eligibility Check</h6>
            <hr className="mt-3 text-gray" />
            {offers && offers.length > 0 && <>
                <p className="mt-6 text-xl font-medium">
                    Awesome
                    <img className="inline ml-2 w-8 h-8" src="/assets/icons/eligible.png" alt="offer eligible" />
                </p>
                <p>Your Customer is eligible for <span className="text-2xl font-bold text-sk-blue ml-1">&#8377;{utils.formatINR(offers[0].approvedAmount)}</span></p>
                <hr className="mt-3 text-gray lg:w-96 opacity-30" />
                <div className="grid grid-cols-3">
                    <CommonAccordion id="sk-eg-offers" className="col-span-3 lg:col-span-2 inline mt-8" heading={"Offer Details"} show={true}>
                        <div className="p-4">
                            <p className="flex items-center mb-4">
                                <img src={offers[0].lenderLogo} alt="lender" className="h-10" />
                                {/* <span className="ml-2">{offers[0].lender}</span> */}
                            </p>
                            <CommonOfferSelect className="" offers={offers} onOfferSelect={(v) => setData({ ...data, 'selected_offer': v })} selectedOffer={data.selected_offer || offers[0]?.id} />
                        </div>
                    </CommonAccordion>
                    <CommonAccordion id="sk-eg-itinerary" className="col-span-3 xl:col-span-2 xl:col-start-1 inline mt-8" heading="Itinerary Details (Optional)" show={data.show_itinerary || false}>
                        <div className="p-4 mt-1">
                            <ItineraryDetails onChange={updateData} getDestinationType={() => data.destination_type} />
                        </div>
                    </CommonAccordion>
                    <CommonFormButton id="sk-eg-submit" text="Share Link with the Customer" className="col-span-3 lg:col-span-2 lg:col-start-1 my-8" onClick={onSubmit} />
                </div>
            </>
            }
            {offers && offers.length === 0 && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
            {alert && <CommonPopup onOk={onAlertOk} onCancel={onAlertOk} icon={alert.includes("successfully shared") ? "/assets/icons/green-tick.svg" : ""}>
                <div>{alert}</div>
            </CommonPopup>}
        </div>
    )
}

export default Eligible