import React, { useEffect, useState } from 'react'
import { CommonFormButton, CommonFormCheckbox, CommonOTP, CommonPopup } from '../../../index.js'
import Customer from '../../../../apis/customer.js'
import { Link } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel.js'

const EnterOTP = (props) => {
    const [isChecked, setIsChecked] = useState(false)
    const [OTP, setOTP] = useState("")
    const [error, setError] = useState("")
    const [timer, setTimer] = useState(Date.now())
    const [resendAt, setResendAt] = useState(props.data.resend_at)
    const [resendIn, setResendIn] = useState()

    if (timer < resendAt) {
        setTimeout(function () {
            setTimer(Date.now())
        }, 100)
    }

    useEffect(() => {
        setResendIn(resendAt ? Math.max(0, Math.floor((resendAt - timer) / 1000)) : null)
    }, [resendAt, timer])

    async function resendOTP() {
        const response = await Customer.resendOTP({ applicationNumber: props.applicationNumber, mid: props.data.mid, mobile: props.data.mobile })
        if (response.status === 200) {
            setError("OTP resent!")
            if (response.data?.data?.resend_at) {
                setResendAt(response.data?.data?.resend_at)
            } else {
                setResendAt(null)
            }
            MixPanel.track(MixPanel.types.customer.auth.resend_otp)
        } else {
            setError("Error in sending OTP!")
        }
    }

    async function onSubmit() {
        setError("")
        if (OTP.toString().length !== 6) {
            setError("Please enter OTP.")
            return
        }
        if (props.data.flowType !== 2 && !isChecked) {
            setError("Please accept terms and conditions.")
            return
        }

        const response = await Customer.verifyOTP({ applicationNumber: props.applicationNumber, mid: props.data.mid, otp: OTP.toString() })
        if (response.status === 200) {
            props.checkApplicationStatus(true)
            MixPanel.track(MixPanel.types.customer.auth.enter_otp)
        } else if (response.status === 400 || response.status === 403) {
            setError(response.error)
        } else {
            setError("API Error!")
        }
    }

    return (
        <div className="flex-1 flex flex-col justify-between">
            {error && <CommonPopup small={true} onOk={() => setError("")} onCancel={() => setError("")}><div>{error}</div></CommonPopup>}
            <div className="mt-4">
                <p className="text-sk-blue font-medium text-lg">Welcome {props.data.flowType !== 2 ? props.data.first_name : ""}</p>
                <p className="mt-4 font-bold">OTP successfully sent to <span className="tracking-widest">{props.data.mobile_masked}</span></p>
                <p className="mt-8"></p>
                <CommonOTP resendIn={resendIn} onChange={setOTP} resend={resendOTP} />
            </div>
            <div className="">
                {props.data.flowType !== 2 && <div className="flex mx-6">
                    <CommonFormCheckbox className="flex-none w-4 h-4 mt-0.5" checked={isChecked} onChange={setIsChecked} />
                    <p className="text-xs ml-2" onClick={() => setIsChecked(!isChecked)}>By clicking on Continue, you agree to SanKash <Link to="https://sankash.in/assets/documents/terms-of-use.pdf" target="_blank"><span className="text-sk-blue">Terms of Service</span></Link> and <Link to="https://sankash.in/assets/documents/privacy-policy.pdf" target="_blank"><span className="text-sk-blue">Privacy Policy</span></Link>.</p>
                </div>}
                <CommonFormButton text="VERIFY" className="w-full mt-8" onClick={onSubmit} />
            </div>
        </div>
    )
}

export default EnterOTP