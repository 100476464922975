import React, { useState } from "react";
import PassportScanner from "../../agent/insurance/new-insurance/passport-scanner";

const ScanPassport = () => {
    const [show, setShow] = useState(false)

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Scan Passport</h6>
            <hr className="mt-3 text-gray" />
            <p className="mt-4 text-sk-blue font-medium underline underline-offset-2 cursor-pointer" onClick={() => setShow(true)}>Launch Passport Scanner</p>
            {show && <PassportScanner hide={() => setShow(false)} updateData={() => setShow(false)} showCopy={true} />}
        </div>
    )
}

export default ScanPassport