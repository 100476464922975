import GA from "../../../config/analytics"
import { useEffect } from "react"
import { useLocation } from "react-router-dom"

const Tracking = ({children}) => {
    // track pageviews
    const location = useLocation()
    useEffect(() => {
        GA.sendPageView(location.pathname)
    }, [location])

    return children
}

export default Tracking