import React from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from "react-icons/fa6"

const Checkbox = (props) => {
    let isChecked = props.checked || false

    function toggle() {
        typeof props.onChange === "function" && props.onChange(!isChecked)
    }

    return (
        <div className={"flex items-center " + (props.className ? props.className : "")} onClick={toggle}>
            {isChecked ? <FaCheck className={"inline select-none w-4 h-4 rounded text-white p-0.5 " + (props.disabled ? "bg-gray-500" : "bg-sk-blue cursor-pointer")} /> : <span className={"rounded-sm border-2 w-4 h-4 " + (props.disabled ? "border-gray-500" : "border-sk-blue cursor-pointer")}></span>}
            {props.label && <span className={"pl-2 " + (props.labelClassName || "") + " " + (props.disabled ? "" : "cursor-pointer")}>{props.label}</span>}
        </div>
    )
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    labelClassName: PropTypes.string
}

export default Checkbox