import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Onboarding = {
    new: function (data) {
        return callapi({
            method: "post",
            url: endpoints.agentOnboarding.new,
            data
        })
    },
    sendOTP: function (data) {
        return callapi({
            method: "post",
            url: endpoints.agentOnboarding.sendOTP,
            data
        })
    },
    verifyOTP: function (data) {
        return callapi({
            method: "post",
            url: endpoints.agentOnboarding.verifyOTP,
            data
        })
    },
    validate: function (data) {
        return callapi({
            method: "post",
            url: endpoints.agentOnboarding.validate,
            data
        })
    }
}

export default Onboarding