import React, { useState, useRef } from 'react'
import { CommonFormButton, CommonError, CommonFormTextInput } from '../../../index.js'
import auth from '../../../../apis/auth.js'
import MixPanel from '../../../../config/mixpanel.js'

const Password = props => {
    let [error, setError] = useState(null)
    let passwordRef = useRef(null)
    let confirmPasswordRef = useRef(null)

    async function setPassword() {
        setError(null)
        const password = passwordRef.current?.value
        const confirmPassword = confirmPasswordRef.current?.value
        const response = await auth.setPassword({ password: password, confirmPassword: confirmPassword, attemptMid: props.attemptMid })

        if (response.status === 200) {
            props.changeView(3, {})
            MixPanel.track(MixPanel.types.auth.forgot_password.change_password)
        } else {
            setError(response.error)
        }
    }

    return (
        <div>
            <p className="text-xl font-medium mt-5 mb-3 text-sk-blue">Set a new password</p>
            <p className="mb-6">Password must contain atleast one character and one number.</p>
            {error && <CommonError error={error} className="my-2" />}
            <CommonFormTextInput inputRef={passwordRef} id="sk-forgot-password-password" type="password" label="Password" minLength="6" className="mb-6" />
            <CommonFormTextInput inputRef={confirmPasswordRef} id="sk-forgot-password-confirm-password" type="password" label="Confirm Password" minLength="6" className="mb-6" />
            <CommonFormButton text="SET PASSWORD" className="w-full mt-10 mb-2" onClick={setPassword} />
        </div>
    )
}

export default Password