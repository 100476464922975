import React, { useState } from 'react'
import { CommonError, CommonLoader, CommonTable, CommonPagination, CommonDropdown, CommonSearch } from '../..'
import { useSearchParams, Link } from 'react-router-dom'
import API from '../../../apis'
import { BsThreeDotsVertical } from "react-icons/bs"
import { default as Details } from './pages/agent-detail'

const Manage = () => {
    let [searchParams] = useSearchParams()
    function getDefaultFilter() {
        const partner = searchParams.get("partner")
        if (partner) {
            return { search: partner, status: null }
        }
        return { search: null, status: null }
    }

    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [detail, setDetail] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)

    return (
        <div className="flex flex-col">
            <div className="flex">
                <h6 className="font-semibold hamburger-spacing">Manage Agents</h6>
                <CommonDropdown id="sk-more-options" dropdownId="sk-more-options-dropdown-menu" alignLeft={true}>
                    <BsThreeDotsVertical className="mt-1 ml-2" />
                    <Link to="/backoffice/users/agents/add">
                        <span className="block px-4 py-2 hover:bg-gray-100 select-none">Add New Agent</span>
                    </Link>
                </CommonDropdown>
            </div>
            <hr className="mt-3 text-gray" />
            <CommonSearch className="mt-4" context="bo_agent" hideStatus={true} defaultFilter={getDefaultFilter()} setError={setError} setData={setData} getCount={API.backoffice.agent.count} getList={API.backoffice.agent.list} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {data && <CommonTable className="mt-4" data={data} showDetails={setDetail} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <Details id={detail} onClose={() => setDetail(null)} />}
        </div>
    )
}

export default Manage