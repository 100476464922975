import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Dashboard = {
    get: async function (type) {
        return await callapi({
            method: "get",
            url: endpoints.backoffice.dashboards.get(type)
        })
    }
}

export default Dashboard