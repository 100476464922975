import React from 'react'

import { CommonAccordion } from '../../..'

const Passenger = (props) => {
    const { passengerDetails } = props

    return (
        <CommonAccordion className="p-4" heading={"Review - Passenger " + (props.i + 1) + " (Age " + (props.x >= 1 ? props.x + " years" : Math.round(props.x * 100) + " months") + ")"} show={false}>
            <div className="flex-1 grid grid-cols-2 *:col-span-full xl:*:col-span-1 gap-6 px-6">
                <p><span className="text-sm font-semibold text-gray">Traveler's Full Name</span><br /><span className="font-bold">{`${passengerDetails.title}. ${passengerDetails.first_name} ${passengerDetails.last_name}`}</span></p>
                <p><span className="text-sm font-semibold text-gray">Traveler's Email</span><br /><span className="font-bold">{passengerDetails.email}</span></p>
                <p><span className="text-sm font-semibold text-gray">Gender</span><br /><span className="font-bold">{passengerDetails.gender}</span></p>
                <p><span className="text-sm font-semibold text-gray">Traveler's Phone Number</span><br /><span className="font-bold">{passengerDetails.mobile}</span></p>
                <p><span className="text-sm font-semibold text-gray">Date of Birth</span><br /><span className="font-bold">{passengerDetails.dob}</span></p>
                <p><span className="text-sm font-semibold text-gray">Destination</span><br /><span className="font-bold">{passengerDetails.destination}</span></p>
                <p><span className="text-sm font-semibold text-gray">Pincode</span><br /><span className="font-bold">{passengerDetails.pincode}</span></p>
                <p><span className="text-sm font-semibold text-gray">Occupation</span><br /><span className="font-bold">{passengerDetails.occupation}</span></p>
                <p><span className="text-sm font-semibold text-gray">City</span><br /><span className="font-bold">{passengerDetails.city}</span></p>
                <p><span className="text-sm font-semibold text-gray">Passport Number</span><br /><span className="font-bold">{passengerDetails.passport_no || ""}</span></p>
                <p><span className="text-sm font-semibold text-gray">State</span><br /><span className="font-bold">{passengerDetails.state}</span></p>
                <p><span className="text-sm font-semibold text-gray">Country of issue of Passport</span><br /><span className="font-bold">{passengerDetails.passport_country}</span></p>
                <p><span className="text-sm font-semibold text-gray">Address</span><br /><span className="font-bold">{passengerDetails.address}</span></p>
                <p><span className="text-sm font-semibold text-gray">Nominee Name</span><br /><span className="font-bold">{passengerDetails.nominee_title + ". " + passengerDetails.nominee_name}</span></p>
                <p><span className="text-sm font-semibold text-gray">Resident Status</span><br /><span className="font-bold">{passengerDetails.resident}</span></p>
                <p><span className="text-sm font-semibold text-gray">Relation to Insured</span><br /><span className="font-bold">{passengerDetails.nominee_relation}</span></p>
            </div>
        </CommonAccordion>
    )
}

export default Passenger