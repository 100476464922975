import React, { useEffect, useState } from 'react'
import { CommonLoader } from '../../../index.js'
import customer from '../../../../apis/customer.js'
import MixPanel from '../../../../config/mixpanel.js'

const Complete = () => {
    const [data, setData] = useState(null)
    const [redirectAt, setRedirectAt] = useState(null)
    const [redirectIn, setRedirectIn] = useState(null)
    const [close, setClose] = useState(null)

    if (redirectAt && redirectAt !== 0) {
        setTimeout(function () {
            const timeLeft = (redirectAt - new Date().getTime()) / 1000
            if (timeLeft > 0) {
                setRedirectIn(timeLeft)
            } else {
                window.location.replace(data.url)
            }
        }, 100)
    }

    useEffect(() => {
        async function getData() {
            const response = await customer.complete()
            if (response.status === 200 && response.data?.data) {
                setData(response.data.data)
                if (response.data.data.url) {
                    setRedirectAt(new Date().getTime() + 6 * 1000)
                } else {
                    setClose(true)
                }
                MixPanel.track(MixPanel.types.customer.application.complete)
            }
        }
        getData()
    }, [])

    return (
        <div className="flex-1 flex flex-col items-center justify-center">
            {data && <img src="/assets/icons/green-tick.svg" alt="success" className="h-12" />}
            {data && <p className="text-xl font-bold mt-2">Payment Successful</p>}
            {data && <p className="text-sm text-center">We've sent you a confirmation message on <span className="tracking-widest">{data?.mobile}</span>.</p>}
            {data && <div className="mt-4 rounded-xl bg-gray-50 border border-gray-500 p-4 flex flex-row justify-around *:inline-block *:font-bold *:px-4">
                <p><span className="text-gray-500">Amount</span><br />{data.amount}</p>
                <p className="border-l border-r border-gray-500"><span className="text-gray-500">Tenure</span><br />{data.tenure} months</p>
                <p><span className="text-gray-500">EMI</span><br />{data.emi}</p>
            </div>}
            {data && redirectAt && <CommonLoader className="h-8 mt-24" />}
            {data && redirectAt && redirectIn > 1 && <p className="mt-2">Redirecting back to merchant <span>in {Math.floor(redirectIn)} secs</span>...</p>}
            {data && redirectAt && redirectIn <= 1 && <p className="mt-2">Redirecting...</p>}
            {data && close && <p className="mt-24">You may now close this page.</p>}
            {!data && <CommonLoader className="h-8 mt-24" />}
            {!data && <p className="text-center mt-2">Please wait...</p>}
        </div>
    )
}

export default Complete