import React, { useState } from 'react'
import { CommonError, CommonFormButton, CommonFormFileInput, CommonTable, CommonPopup } from '../..'
import API from '../../../apis'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const Update = () => {
    const [error, setError] = useState(null)
    const [alert, setAlert] = useState(null)
    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState(null)
    const [verifiedData, setVerifiedData] = useState(null)
    const navigate = useNavigate()

    async function upload() {
        if (!file) {
            setError("No file selected.")
            return false
        }

        const response = await API.util.uploadBulkUpdate({
            file: file[0],
            ext: file[0].name.split(".").pop(),
            size: file[0].size,
            type: file[0].type
        })

        if (response.status === 200 && response.data?.data) {
            return response.data.data
        } else if (response.status === 401) {
            navigate("/backoffice/auth")
        } else {
            setError(response.error || "Error uploading file.")
            return false
        }
    }

    async function verify() {
        setError(null)
        const fileName = await upload()
        if (fileName) {
            const response = await API.backoffice.partner.bulkUpdate({ fileName, verify: true })
            if (response.status === 200 && response.data?.data) {
                setFileName(fileName)
                setVerifiedData(response.data.data)
                MixPanel.track(MixPanel.types.backoffice.partner.bulk_update)
            } else {
                setError(response.error || "API Error!")
                setFile(null)
                setFileName(null)
                document.getElementById("sk-bulk-update-partners").value = ""
            }
        }
    }

    async function update() {
        setError(null)
        if (!fileName) return setError("Filename not found!")
        const response = await API.backoffice.partner.bulkUpdate({ fileName })
        if (response.status === 200) {
            setAlert("Updates have been scheduled. You shall receive an email once complete.")
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Bulk Update</h6>
            <hr className="mt-3 text-gray" />
            {error && <CommonError error={error} className="mt-4" />}
            {verifiedData === null && <div className="w-96 mt-4 space-y-4">
                <p>Select a file for bulk update.</p>
                <CommonFormFileInput id="sk-bulk-update-partners" label="Upload CSV File" accept=".csv" onChange={setFile} />
                <CommonFormButton className="w-full" text="Verify" onClick={verify} />
                <div>
                    <p className="text-xs">Required file format:</p>
                    <ul className="*:text-xs list-decimal ml-8">
                        <li>PSK ID - PSKXXXXXXX</li>
                        <li>TNPL Enabled - 0 or 1</li>
                        <li>FlexMoney MID - Integer (value 0 will disable lender)</li>
                        <li>Fibe MID - Integer (value 0 will disable lender)</li>
                        <li>TI Enabled - 0 or 1</li>
                        <li>Insurance Domestic Discount - 1 to 100</li>
                        <li>Insurance International Discount - 1 to 100</li>
                        <li>Razorpay Account ID - Integer (value 0 will disable)</li>
                        <li>RM ID</li>
                    </ul>
                </div>
                <div>
                    <p className="text-xs">Please Note:</p>
                    <ul className="*:text-xs list-disc ml-8">
                        <li>First row in CSV file should contain headers. Updates will be read from row 2.</li>
                        <li>Empty value for a partner in a column will not do any update in regards to that partner for that column.</li>
                    </ul>
                </div>
            </div>}
            {verifiedData && <div className="mt-4 space-y-4 mb-8 w-2/3">
                <p>Below is a summary and complete list of changes that will be done. Confirm and hit Update.</p>
                <CommonTable data={verifiedData.summary} />
                <CommonTable data={verifiedData.details} />
                <CommonFormButton className="w-1/2" text="Update" onClick={update} />
            </div>}
            {alert && <CommonPopup onOk={() => navigate("/backoffice/users/partners/manage")} onCancel={() => navigate("/backoffice/users/partners/manage")} icon="/assets/icons/green-tick.svg">
                <div>{alert}</div>
            </CommonPopup>}
        </div>
    )
}

export default Update