import React from "react"
import { Tooltip } from "react-tooltip"

const SimpleToolTip = (props) => {
    const unique = "tt_" + Date.now().toString() + Math.floor(Math.random() * 10e4).toString()

    return (
        <div>
            {/*className="relative group"*/}
            <div className="inline-block" id={unique}>
                {React.Children.toArray(props.children)[0]}
            </div>

            <Tooltip className="drop-shadow-lg text-center rounded-lg w-max max-w-60 px-2 py-1" anchorSelect={"#" + unique} style={{ backgroundColor: "rgb(255, 255, 255)", color: "#000", zIndex: 99 }} place={props.place || "bottom-start"} opacity={1}>{React.Children.toArray(props.children)[1]}</Tooltip>

            {/* <div className="invisible group-hover:visible absolute top-0 z-50 translate-y-6 bg-white px-2 py-1 drop-shadow-lg text-center rounded-lg w-max max-w-60">
                {React.Children.toArray(props.children)[1]}
            </div> */}
        </div>
    )
}

export default SimpleToolTip