import callapi from "./callapi"
import endpoints from "../config/endpoints"

const customer = {
    check: async function (applicationNumber) {
        return callapi({
            method: "get",
            url: endpoints.customer.check,
            params: {
                app: applicationNumber
            }
        })
    },
    sendOTP: async function ({ applicationNumber, data, mobile }) {
        if (!applicationNumber) return { error: "Application not found!" }
        if (!data) return { error: "Internal Server Error" }
        return callapi({
            method: "post",
            url: endpoints.customer.sendOTP(applicationNumber),
            data: { data, mobile }
        })
    },
    resendOTP: async function ({ applicationNumber, mid, mobile }) {
        if (!applicationNumber) return { error: "Application not found!" }
        if (!mid) return { error: "Internal Server Error" }
        return callapi({
            method: "post",
            url: endpoints.customer.resendOTP(applicationNumber),
            data: { mid, mobile }
        })
    },
    verifyOTP: async function ({ applicationNumber, mid, otp }) {
        if (!applicationNumber) return { error: "Application not found!" }
        if (!mid) return { error: "Internal Server Error" }
        if (!otp) return { error: "Please enter OTP." }
        return callapi({
            method: "post",
            url: endpoints.customer.verifyOTP(applicationNumber),
            data: { mid, otp }
        })
    },
    getOffers: async function () {
        return callapi({
            method: "get",
            url: endpoints.customer.getOffers
        })
    },
    shareLead: async function ({ selectedOffer }) {
        if (!selectedOffer) return { error: "No offer selected." }
        return callapi({
            method: "post",
            url: endpoints.customer.shareLead,
            data: { selected_offer: selectedOffer }
        })
    },
    refreshStatus: async function () {
        return callapi({
            method: "post",
            url: endpoints.customer.refreshStatus
        })
    },
    newPayment: async function () {
        return callapi({
            method: "get",
            url: endpoints.customer.newPayment
        })
    },
    confirmPayment: async function (data) {
        return callapi({
            method: "post",
            url: endpoints.customer.confirmPayment,
            data
        })
    },
    complete: async function () {
        return callapi({
            method: "get",
            url: endpoints.customer.complete
        })
    },
    new: async function (data) {
        const { first_name, last_name, mobile, dob, gender, monthly_income, email, amount } = data

        if (!first_name) return { error: "First Name is required." }
        if (!last_name) return { error: "Last Name is required." }
        if (!mobile) return { error: "Mobile is required." }
        if (!dob) return { error: "Date of Birth is required." }
        if (!gender) return { error: "Gender is required." }
        if (!monthly_income) return { error: "Monthly Income is required." }
        if (!email) return { error: "Email is required." }
        if (!amount) return { error: "Itinerary Amount is required." }

        return callapi({
            method: "post",
            url: endpoints.customer.new,
            data
        })
    },
    moreDetails: async function (data) {
        const { pan, work_pincode, home_pincode, home_type, employment_type } = data

        if (!pan) return { error: "PAN is required." }
        if (!work_pincode) return { error: "Work Pincode is required." }
        if (!home_pincode) return { error: "Home Pincode required." }
        if (!home_type) return { error: "Home Type is required." }
        if (!employment_type) return { error: "Employment Type is required." }

        return callapi({
            method: "post",
            url: endpoints.customer.moreDetails,
            data
        })
    },
}

export default customer