import React, { useState, useRef, useCallback, useEffect } from 'react'
import { default as Search } from "./search-widget"
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Download } from '..'

const CommonSearch = (props) => {
    let [filters, setFilters] = useState(props.defaultFilter)
    let filterRef = useRef(filters)
    let currentPage = useRef(props.currentPage)

    let setNumPages = useRef(props.setNumPages)
    let setCurrentPage = useRef(props.setCurrentPage)
    let setError = useRef(props.setError)
    let navigate = useNavigate()
    let setData = useRef(props.setData)
    let getCount = useRef(props.getCount)
    let getList = useRef(props.getList)

    const fetchData = useCallback(async function () {
        if (currentPage.current === null) return
        const response = await getList.current(filterRef.current, currentPage.current)
        if (response.status === 200 && "data" in response.data) {
            setData.current(response.data.data)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError.current("API Error!")
        }
    }, [navigate])

    const fetchCount = useCallback(async function () {
        const response = await getCount.current(filterRef.current)
        if (response.status === 200 && "data" in response.data && "count" in response.data.data) {
            if (response.data.data.count > 0) {
                setCurrentPage.current(1)
                setNumPages.current(response.data.data.num_pages)
            } else {
                setError.current("No Results Found!")
            }
        } else if (response.status === 401) {
            // navigate("/agent/auth")
        } else {
            setError.current("API Error!")
        }
    }, [])

    useEffect(() => {
        setError.current(null)
        setData.current(null)
        setNumPages.current(null)
        setCurrentPage.current(null)
        filterRef.current = filters
        fetchCount()
    }, [filters, fetchCount, props.seed])

    useEffect(() => {
        currentPage.current = props.currentPage
        setError.current(null)
        setData.current(null)
        fetchData()
    }, [props.currentPage, fetchData])

    const exportData = async function () {
        setError.current(null)
        const response = await getList.current(filterRef.current, currentPage.current, true)
        if (response.status === 200 && response.data?.data) {
            await Download(response.data.data)
        } else {
            setError.current("Error while exporting data.")
        }
    }

    return (
        <div className={props.className || ""}>
            <Search context={props.context} onChange={setFilters} statuses={props.statuses} hideStatus={props.hideStatus} defaultFilter={props.defaultFilter} showExport={props.showExport} exportData={exportData} />
        </div>
    )
}

Search.propTypes = {
    seed: PropTypes.number,
    className: PropTypes.string,
    defaultFilter: PropTypes.object,
    context: PropTypes.string,
    hideStatus: PropTypes.bool,
    statuses: PropTypes.arrayOf(PropTypes.string),
    showExport: PropTypes.bool,
    setError: PropTypes.func,
    setData: PropTypes.func,
    getCount: PropTypes.func,
    getList: PropTypes.func,
    setNumPages: PropTypes.func,
    setCurrentPage: PropTypes.func,
    currentPage: PropTypes.number
}

export default CommonSearch