import React, { useState } from 'react'
import { CommonFormButton, CommonPopup } from '../../../index.js'
import customer from '../../../../apis/customer.js'
import utils from '../../../common/utils.js'
import Constants from '../../../../config/constants.js'
import MixPanel from '../../../../config/mixpanel.js'

const ProcessingFee = (props) => {
    const processingFee = props.data.processing_fee
    const [alert, setAlert] = useState(null)

    async function createOrder() {
        const response = await customer.newPayment()
        if (response.status === 200 && response.data?.data?.order_id && response.data?.data?.key) {
            await openRazorpay(response.data?.data?.key, response.data?.data?.order_id, response.data.data.mobile)
        } else if (response.status === 401) {
            props.changeView(Constants.CUSTOMER_VIEWS.SESSION_EXPIRED)
        } else {
            props.changeView(Constants.CUSTOMER_VIEWS.API_ERROR, null, null, response.error || "API Error!")
        }
    }

    async function openRazorpay(key, orderId, mobile) {
        var options = {
            "key": key,
            "amount": processingFee * 100,
            "currency": "INR",
            "name": "Sankash",
            "description": "Payment for " + props.applicationNumber,
            "image": Constants.SANKASH_LOGO_URL,
            "order_id": orderId,
            "prefill": {
                "contact": mobile
            },
            "handler": confirmPayment,
            "theme": {
                "color": "#01BAFA"
            }
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.on('payment.failed', paymentFailure);
        rzp1.open()
        MixPanel.track(MixPanel.types.customer.application.pfee_rzp_open)
    }

    async function paymentFailure(response) {
        setAlert(response.error.description)
        MixPanel.track(MixPanel.types.customer.application.pfee_rzp_error)
    }

    async function confirmPayment({ razorpay_payment_id, razorpay_order_id, razorpay_signature }) {
        const response = await customer.confirmPayment({ payment_id: razorpay_payment_id, order_id: razorpay_order_id, signature: razorpay_signature })
        if (response.status === 200) {
            props.changeView(Constants.CUSTOMER_VIEWS.COMPLETE)
            MixPanel.track(MixPanel.types.customer.application.pfee_success)
        } else if (response.status === 401) {
            props.changeView(Constants.CUSTOMER_VIEWS.SESSION_EXPIRED)
        } else {
            setAlert(response.error || "API Error!")
        }
    }

    return (
        <div className="flex-1 flex flex-col">
            {alert && <CommonPopup onOk={() => setAlert("")} onCancel={() => setAlert("")}><div>{alert}</div></CommonPopup>}
            <div className="flex-1 flex flex-col items-center justify-center">
                <div className="relative">
                    <img src="/assets/icons/rupee.png" alt="rupee" />
                    <img src="/assets/icons/green-tick.svg" alt="success" className="w-6 h-6 absolute -top-2 left-11" />
                </div>
                <p className="mt-4 text-center font-medium text-gray-700">Your loan is ready to be disbursed.</p>
                <p className="mt-6 text-center font-semibold">You will need to pay a nominal convenience fees of</p>
                <p className="text-2xl font-bold my-2">{utils.formatINR(processingFee, 0, true)}</p>
                <p className="text-center font-semibold">to complete the process.</p>
            </div>
            <div className="flex-none">
                <CommonFormButton text={"Continue to Pay " + utils.formatINR(processingFee, 0, true)} className="w-full" onClick={createOrder} />
            </div>
        </div>
    )
}

export default ProcessingFee