import React from "react"
import { isMobile } from "react-device-detect"
import utils from "../../../common/utils"

const Flight = ({ type, horizontal, bigger, flightData, searchData, selectedFlight }) => {
    const getPrimary = () => isMobile ? "font-medium text-[10px]" : bigger ? "font-semibold text-[11px]" : "font-semibold text-[10px]"
    const getSecondary = () => isMobile ? "font-medium text-[9px] text-gray-700" : bigger ? "text-[11px] font-medium text-gray-700" : "text-[10px] font-medium text-gray-500"

    const setDefaultAirlineImage = ({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "https://storage.googleapis.com/sankash-public/cdn/airlines-logo/default.png";
    }

    return (
        <>
            {flightData && searchData && <div className={"flex " + (isMobile ? "py-2 pr-2" : "p-4") + " " + (horizontal ? "flex-row gap-4" : "flex-col gap-1")}>
                <div className="flex items-center gap-2">
                    <img src={flightData?.flight_icon} alt="spicejet" className={isMobile ? "h-5 w-5" : bigger ? "w-10 h-10" : "w-7 h-7"} onError={setDefaultAirlineImage} />
                    <div className="flex flex-col gap-0">
                        <span className={isMobile ? "text-[11px] font-semibold line-clamp-1" : bigger ? "text-lg font-semibold line-clamp-1" : "text-sm font-semibold"}>{flightData?.flight_name}</span>
                        <span className={getSecondary()}>{flightData?.flight_code}-{flightData?.flight_no}</span>
                    </div>
                </div>
                {!isMobile && <div className="flex items-center gap-2">
                    <div className="flex flex-col gap-0">
                        <span className={getSecondary()}>{type === 1 ? searchData?.from : searchData?.to}</span>
                        <span className={getPrimary()}>{flightData?.departure?.split(" ")[1]}</span>
                        <span className={getSecondary()}>{utils.calcFlightDate(flightData?.departure)}</span>
                    </div>
                    <div className="flex flex-col gap-0 items-center">
                        <span className={getSecondary() + " px-4"}>{flightData?.stops > 0 ? flightData?.stops > 1 ? `${flightData?.stops} stops` : `${flightData?.stops} stop` : "Non - Stop"}</span>
                        <hr className="h-px border-gray-500 w-full my-0.5" />
                        <span className={getPrimary() + " px-4"}>{utils.calcFlightDuration(flightData?.duration_mins)}</span>
                    </div>
                    <div className="flex flex-col gap-0">
                        <span className={getSecondary()}>{type === 1 ? searchData?.to : searchData?.from}</span>
                        <span className={getPrimary()}>{flightData?.arrival?.split(" ")[1]}</span>
                        <span className={getSecondary()}>{utils.calcFlightDate(flightData?.arrival)}</span>
                    </div>
                </div>}
                {isMobile && <div className="flex flex-col justify-stretch pr-1">
                    <div className="flex justify-between items-center gap-2">
                        <span className={getSecondary()}>{type === 1 ? searchData?.from : searchData?.to}</span>
                        <span className={getPrimary()}>{flightData?.departure?.split(" ")[1]}</span>
                    </div>
                    <div className="flex justify-between items-center gap-2">
                        <span className={getSecondary()}>{type === 1 ? searchData?.to : searchData?.from}</span>
                        <span className={getPrimary()}>{flightData?.arrival?.split(" ")[1]}</span>
                    </div>
                    {!selectedFlight && <div className="flex justify-between items-center gap-2">
                        <span className={getSecondary()}>Stops</span>
                        <span className={getPrimary()}>{flightData?.stops > 0 ? flightData?.stops : "Non - Stop"}</span>
                    </div>}
                </div>}
            </div>}
        </>
    )
}

export default Flight