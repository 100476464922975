import React, { useEffect, useState } from 'react'
import { IconContext } from 'react-icons'

const MenuItemStatic = (props) => {
    const [expanded, setExpanded] = useState(props.active ? true : false)

    useEffect(() => {
        setExpanded(props.active ? true : false)
    }, [props.active])

    const handleClick = () => {
        if (props.expanded) {
            setExpanded(!expanded)
        } else {
            props.toggleExpanded()
            setExpanded(true)
        }
    }

    return (
        <div id={props.id || null} className={"flex flex-col cursor-pointer " + (props.active === true && "bg-sk-blue-select")}>
            <div className="flex flex-row items-center py-4 px-6" onClick={handleClick}>
                <p className="mx-2">
                    {props.logo && typeof props.logo === "string" && <img className="inline-block h-5 w-5" src={props.logo} alt="menu icon" />}
                    {props.logo && typeof props.logo === "object" && <IconContext.Provider value={{ className: 'h-5 w-5 text-white' }}>{props.logo}</IconContext.Provider>}
                </p>
                <p className={"mx-2 grow text-white text-sm uppercase line-clamp-1 " + (props.active === true ? "font-semibold" : "font-medium")}>{props.text}</p>
                {props.expandable === true &&
                    <div className="mx-2">
                        <img className={"transition " + (expanded && "rotate-180")} src="/assets/icons/arrow-down.svg" alt="expand" />
                    </div>
                }
            </div>
            {props.expandable && expanded &&
                <div className="flex flex-col mt-2 mb-5">
                    {props.children}
                </div>
            }
        </div>
    )
}

export default MenuItemStatic