import React, { useEffect } from 'react'

const OTP = (props) => {
    function onKeyUp(e) {
        try {
            const target = e.target;
            const key = e.key;
            const val = target.value;

            if (key === "Backspace" || key === "Delete") {
                if (val !== "") {
                    target.value = "";
                } else {
                    const prev = target.previousElementSibling;
                    if (prev) {
                        prev.focus();
                    }
                }
                return;
            } else {
                if (isNaN(val) || val === "e") {
                    target.value = "";
                    return;
                } else {
                    if (val.toString().length > 1) {
                        target.value = val.toString().substring(0, 1)
                    }
                    const next = target.nextElementSibling;
                    if (next) {
                        next.focus();
                    }
                }
            }
        } catch (e) {
            console.log(e)
        } finally {
            onChange()
        }
    }

    function preventBackspace(e) {
        if (e.key === "Backspace") {
            e.preventDefault()
            e.stopPropagation()
        }
        return false
    }

    function clearOTP() {
        for (let i = 1; i <= 6; i++) document.getElementById("otp-" + i).value = ""
        document.getElementById("otp-1").focus()
    }

    useEffect(() => {
        document.getElementById("otp-1").focus()
    }, [])

    function onChange() {
        let otp = ""
        for (let i = 1; i <= 6; i++) otp += document.getElementById("otp-" + i).value
        if (typeof props.onChange === "function") props.onChange(otp)
    }

    return (
        <div className={props.className || ""}>
            <p className="text-xs font-thin">OTP <span className="float-right cursor-pointer" onClick={clearOTP}>Clear</span></p>
            <div className="mt-2 flex justify-center *:border-b *:border-opacity-80 *:border-gray-500 *:outline-none *:w-8 *:mx-2 *:pb-1 *:text-center *:text-lg">
                <input id="otp-1" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
                <input id="otp-2" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
                <input id="otp-3" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
                <input id="otp-4" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
                <input id="otp-5" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
                <input id="otp-6" type="number" pattern="[0-9]{1}" className="" onKeyUp={onKeyUp} onKeyDown={preventBackspace} />
            </div>
            {props.resendIn !== null ? <p className="mt-8 text-xs">Didn't get the OTP? {props.resendIn === 0 ? <span className="text-sk-blue font-medium cursor-pointer" onClick={props.resend}>Resend OTP</span> : "Resend available in " + props.resendIn + " seconds."}</p> : <p className="mt-8 text-xs">No more retries left.</p>}
        </div>
    )
}

export default OTP