import React, { useState } from 'react'
import { default as InsuranceSteps } from './new-insurance/insurance-steps'
import { default as InsuranceSearch } from './new-insurance/insurance-search'
import { default as InsuranceResult } from './new-insurance/insurance-result'
import { default as PassengerDetails } from './new-insurance/passenger-details'
import { default as InsuranceReview } from './new-insurance/insurance-review'
import { default as InsuranceConfirmation } from './new-insurance/insurance-confirmation'
import { CommonPopup } from '../..'

const NewInsurance = () => {
    let [currentView, changeCurrentView] = useState({ view: 0, insuranceNumber: null, data: null })
    let [error, setError] = useState(null)

    async function changeView(err, view, insuranceNumber, data = {}) {
        if (err) setError(err)
        if (view === 0) changeCurrentView(prev => ({ view: 0, insuranceNumber: null, data: prev.data || null }))
        else {
            changeCurrentView((prev) => ({ view: view || prev.view, insuranceNumber: insuranceNumber || prev.insuranceNumber, data: { ...prev.data, ...data } }))
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Travel Insurance</h6>
            <hr className="mt-3 text-gray" />
            <div className="mt-6">
                <InsuranceSteps currentStep={currentView.view + 1} />
            </div>
            {currentView.view === 0 && <div className="mt-7"><InsuranceSearch changeView={changeView} data={currentView.data} /></div>}
            {currentView.view === 1 && <div className="mt-7"><InsuranceResult changeView={changeView} data={currentView.data} /></div>}
            {currentView.view === 2 && <div className="mt-7"><PassengerDetails changeView={changeView} data={currentView.data} /></div>}
            {currentView.view === 3 && <div className="mt-7"><InsuranceReview changeView={changeView} data={currentView.data} insuranceNumber={currentView.insuranceNumber} /></div>}
            {currentView.view === 4 && <div className="mt-7"><InsuranceConfirmation insuranceNumber={currentView.insuranceNumber} /></div>}
            {error && <CommonPopup onOk={() => setError(null)} onCancel={() => setError(null)}><div>{error}</div></CommonPopup>}
        </div>
    )
}

export default NewInsurance