import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonFormCurrencyInput, CommonFormSelect, CommonError, CommonPopup } from '../../../index'
import paymentCollection from '../../../../apis/payment-collection.js'
import { useNavigate } from 'react-router-dom'
import PGChargesRazorpay from './pg-charges-razorpay.jsx'
import PGChargesCashfree from './pg-charges-cashfree.jsx'
import MixPanel from '../../../../config/mixpanel.js'

const NewCollection = ({ pgData }) => {
    let [data, setData] = useState({})
    let [error, setError] = useState(null)
    const navigate = useNavigate()
    let [alert, setAlert] = useState(null)

    const [showPGCharges, setShowPGCharges] = useState(false)

    async function submit() {
        setError(null)
        const response = await paymentCollection.newPaymentRequest(data)
        if (response.status === 200 && response.data?.data?.payment_url) {
            setAlert(response.data.data.payment_url)
            MixPanel.track(MixPanel.types.agent.payment.generate_link)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError(response.error)
        }
    }

    function reload() {
        navigate("/agent/transactions?tab=payment-collections")
    }

    return (
        <form className="mt-4 grid grid-cols-6 gap-4" autoComplete="off">
            {alert && <CommonPopup onOk={reload} onCancel={reload} icon="/assets/icons/green-tick.svg" wide={true}>
                <div>
                    <p>Payment collection request has been sent!</p>
                    <p className="mt-2">Payment URL: <a target='_blank' rel='noreferrer' href={alert} className="text-sk-blue font-semibold underline underline-offset-2">{alert}</a></p>
                </div>
            </CommonPopup>}
            {error && <CommonError error={error} className="mb-4 col-span-full" />}
            <p className="col-span-full mb-4">
                Collect payment from your customer using Payment gateway services powered by {pgData.pg === "Razorpay" ? "Razorpay" : "Cashfree"}.<br />
                <span className="col-span-full text-sm text-sk-blue underline underline-offset-4 font-medium cursor-pointer" onClick={() => setShowPGCharges(true)}>(Click here to check PG charges)</span>
            </p>

            <CommonFormTextInput id="sk-pg-name" label="Customer Name*" maxLength="64" minLength="3" onChange={(v) => setData({ ...data, "customer_name": v })} className="col-span-full lg:col-span-3 xl:col-span-2 xl:col-start-1" />
            <CommonFormTextInput id="sk-pg-mobile" label="Mobile Number*" type="tel" minLength="10" maxLength="10" onChange={(v) => setData({ ...data, "mobile": v })} className="col-span-full lg:col-span-3 xl:col-span-2" />

            <CommonFormTextInput id="sk-pg-email" type="email" label="E-mail Id*" maxLength="64" onChange={(v) => setData({ ...data, "email": v })} className="col-span-full lg:col-span-3 xl:col-span-2 xl:col-start-1" />
            <CommonFormTextInput id="sk-pg-desc" label="Description*" minLength="3" maxLength="64" onChange={(v) => setData({ ...data, "desc": v })} className="col-span-full lg:col-span-3 xl:col-span-2" />

            <CommonFormCurrencyInput id="sk-pg-amount" label="Amount*" onChange={(v) => setData({ ...data, "amount": v })} className="col-span-full lg:col-span-3 xl:col-span-2 xl:col-start-1" />
            <CommonFormSelect id="sk-pg-validity" label="Payment Link Validity" onChange={(v) => setData({ ...data, "validity": v })} className="col-span-full lg:col-span-3 xl:col-span-2">
                {new Array(6).fill(0).map((x, i) => <option key={i + 1} value={i + 1}>{(i + 1) + (i === 0 ? " day" : " days")}</option>)}
            </CommonFormSelect>
            <p></p>
            <CommonFormButton text="Generate Payment Link" className="col-span-full lg:col-span-4 mb-4" onClick={submit} />
            <p></p>
            <p className="col-span-full text-center mb-8">Powered By <img src={pgData.pg === "Razorpay" ? "/assets/razorpay-logo.png" : "/assets/cashfree-logo.svg"} alt="razorpay logo" className={pgData.pg === "Razorpay" ? "inline-block h-5 ml-2" : "inline-block h-8 ml-2"} /></p>
            {showPGCharges && pgData.pg === "Razorpay" && <PGChargesRazorpay hide={() => setShowPGCharges(false)} />}
            {showPGCharges && pgData.pg !== "Razorpay" && <PGChargesCashfree hide={() => setShowPGCharges(false)} />}
        </form>
    )
}

export default NewCollection