import React, { useEffect, useRef, useState } from "react"
import SearchNav from "./search-top-nav"
import HomeSearch from "./home-search"
import { CommonLoader, CommonPopup, CommonFormButtonStateless } from "../../.."
import Filters from "./search-filters"
import { isMobile } from "react-device-detect"
import { FaFilter } from "react-icons/fa6";
import postProcessing from "./post-processing"
import Constants from "../../../../config/constants"
import PartnerHeader from "./partner-header"
import FlightComparison from "./flight-comparison"
import utils from "../../../common/utils"
import SelectedFlightGroup from "./selected-flight"
import MixPanel from "../../../../config/mixpanel"

const SearchResults = ({ searchData, searchResponse, setView, defaultType }) => {
    const [showSearch, setShowSearch] = useState(false)
    const [showFilters, setShowFilters] = useState(false)
    const [selectedFlight, setSelectedFlight] = useState(null)
    const selectedFlightRef = useRef(null)
    const otherSelectedFlight = useRef(null)

    const [selectedFlightStyle, setSelectedFlightStyle] = useState({})
    const [mobileHeaderStyle, setMobileHeaderStyle] = useState({})

    const [partnerSortOrder, setPartnerSortOrder] = useState([])
    const [partnerDisplayOffset, setPartnerDisplayOffset] = useState(0)
    const [flightSortOrder, setFlightSortOrder] = useState([])
    const [type, setType] = useState(defaultType || 1)

    const [filters, setFilters] = useState({ showNetPrice: false, selectedAirlines: [], preferredDepartureTime: "all", preferredArrivalTime: "all" })
    const [numOfDisplayFlights, setNumOfDisplayFlights] = useState(10)
    const [airlines, setAirlines] = useState([])
    const [isProcessing, setIsProcessing] = useState(false)

    // do processing when filters change
    useEffect(() => {
        if (!searchResponse || typeof searchResponse != "object") return
        setFlightSortOrder([])
        const process = async () => {
            await new Promise(res => setTimeout(res, 700))
            setPartnerSortOrder(await postProcessing.partnerSortOrder(searchResponse, Constants.FLIGHTS.DEFAULT_SORT_ORDER))
            const { flightSortOrder, uniqueAirlines } = await postProcessing.flightSortOrder(searchResponse, type, filters)
            setFlightSortOrder(flightSortOrder)
            setAirlines(uniqueAirlines)
            setNumOfDisplayFlights(10)
            setIsProcessing(false)
        }
        setIsProcessing(true)
        process()
    }, [searchResponse, type, filters])

    // update reference whenever selected flight changes
    useEffect(() => {
        if (selectedFlight && typeof (selectedFlight) === "object") {
            selectedFlightRef.current = { ...selectedFlight }
        } else {
            selectedFlightRef.current = null
        }
    }, [selectedFlight])

    // track onward and return selected flights
    useEffect(() => {
        let currentFlight = selectedFlightRef.current ? { ...(selectedFlightRef.current) } : null
        if (otherSelectedFlight.current) setSelectedFlight({ ...(otherSelectedFlight.current) })
        else setSelectedFlight(null)
        otherSelectedFlight.current = currentFlight
    }, [type])

    // align selected flight in desktop
    useEffect(() => {
        setTimeout(() => {
            if (!isMobile) {
                if (selectedFlight) {
                    const viewportOffset = document.getElementById("sk-flights-search-comparison")?.getBoundingClientRect()
                    if (viewportOffset && viewportOffset?.left > 0 && viewportOffset?.width > 0) {
                        setSelectedFlightStyle({ left: viewportOffset.left, width: viewportOffset.width })
                        return
                    }
                }
                setSelectedFlightStyle({})
            }
        }, 50)
    }, [selectedFlight])

    // align selected flight in mobile even when scrolling
    useEffect(() => {
        if (isMobile) {
            const handleScroll = () => {
                const viewportOffset = document.getElementById("sk-flights-search-mobile-header")?.getBoundingClientRect()
                const viewportOffsetX = document.getElementById("sk-flights-search-mobile-row")?.getBoundingClientRect()
                if (viewportOffset?.top < 0) {
                    setMobileHeaderStyle({ position: "fixed", left: viewportOffsetX?.left })
                } else {
                    setMobileHeaderStyle({ position: "relative" })
                }
            }
            window.addEventListener('scroll', handleScroll)
            return () => window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        const handleXScroll = () => {
            if (mobileHeaderStyle?.position === "fixed") {
                const viewportOffset = document.getElementById("sk-flights-search-mobile-row")?.getBoundingClientRect()
                setMobileHeaderStyle({ position: "fixed", left: viewportOffset?.left })
            }
        }
        if (isMobile) {
            document.getElementById("sk-flights-search-mobile-header").addEventListener("scroll", handleXScroll)
        }
        return () => document.getElementById("sk-flights-search-mobile-header")?.removeEventListener("scroll", handleXScroll)
    }, [mobileHeaderStyle])

    const redirect = () => {
        if (selectedFlight && selectedFlight?.flight?.redirect_url) {
            window.open(selectedFlight.flight.redirect_url, "_blank")
            MixPanel.track(MixPanel.types.agent.flight.redirect)
        }
    }

    return (
        <>
            <SearchNav data={searchData} showEdit={() => setShowSearch(true)} searchEnabled={true} type={type} />
            {showSearch && <CommonPopup sticky={true} wide={true} hideOk={true} onCancel={() => setShowSearch(false)} >
                <HomeSearch setView={setView} data={searchData} popup={true} />
            </CommonPopup>}
            {isProcessing === true && <CommonPopup hideOk={true} sticky={true}>
                <div className="flex items-center justify-center"><CommonLoader className="w-6 h-6 mr-2" /> Please wait...</div>
            </CommonPopup>}
            <>{isMobile
                ? <div className="grid grid-flow-row gap-4 mt-4 max-w-full pb-20">
                    {/* filters and type selection */}
                    <div className="flex gap-2">
                        <div className="flex-none bg-sk-blue flex items-center justify-center p-4 rounded">
                            <FaFilter className="w-4 h-4 text-white" onClick={() => setShowFilters(true)} />
                        </div>
                        {[1, 2].includes(type) && <div className={"flex-1 flex flex-col items-center py-1 px-4 rounded-lg bg-opacity-10 " + (type === 1 ? "bg-sk-blue" : "cursor-pointer")} onClick={() => setType(1)}>
                            <span className="whitespace-nowrap font-semibold text-sm">{searchData.from} &rarr; {searchData.to}</span>
                            <span className="text-xs font-medium">{utils.calcFlightSearchLongDate(searchData.departure_date)}</span>
                        </div>}
                        {[1, 2].includes(type) && searchData.return_date && <div className={"flex-1 flex flex-col items-center py-1 px-4 rounded-lg bg-opacity-10 " + (type === 2 ? "bg-sk-blue" : "cursor-pointer")} onClick={() => setType(2)}>
                            <span className="whitespace-nowrap font-semibold text-sm">{searchData.to} &rarr; {searchData.from}</span>
                            <span className="text-xs font-medium">{utils.calcFlightSearchLongDate(searchData.return_date)}</span>
                        </div>}
                    </div>
                    <div className="pb-10 overflow-x-auto whitespace-nowrap flex flex-col *:shrink-0 -mx-8 *:px-2 gap-y-4" id="sk-flights-search-mobile-header">
                        <div className="grid grid-flow-col *:px-4 *:py-2 top-0 z-10" style={mobileHeaderStyle}>
                            <div className="w-32">{<span className="text-xs text-gray-700">{flightSortOrder.length} flight(s).</span>}</div>
                            <PartnerHeader partnerSortOrder={partnerSortOrder} partnerDisplayOffset={partnerDisplayOffset} setPartnerDisplayOffset={setPartnerDisplayOffset} />
                        </div>

                        {/* static div to replace the fixed div in dom */}
                        {mobileHeaderStyle?.position === "fixed" && <div className="py-2">&nbsp;</div>}

                        {flightSortOrder.length > 0 && <FlightComparison type={type} partnerSortOrder={partnerSortOrder} partnerDisplayOffset={partnerDisplayOffset} flightSortOrder={flightSortOrder} numOfDisplayFlights={numOfDisplayFlights} setNumOfDisplayFlights={setNumOfDisplayFlights} searchData={searchData} searchResponse={searchResponse} filters={filters} setSelectedFlight={setSelectedFlight} selectedFlight={selectedFlight} />}
                    </div>
                    {/* show filters for mobile */}
                    {showFilters && <CommonPopup onCancel={() => setShowFilters(false)} hideOk={true} wide={true}>
                        <Filters filters={filters} setFilters={setFilters} airlines={airlines} />
                    </CommonPopup>}
                </div>
                : partnerSortOrder.length > 0 && <div className="w-full flex pb-20">
                    {/* Filters */}
                    <div className="flex w-60 py-8 px-4 bg-white"><Filters filters={filters} setFilters={setFilters} airlines={airlines} /></div>
                    <div className="flex-1 relative pt-4 pl-8">
                        <div className="block py-4">
                            <div className="w-full space-y-8 p-4 mt-12">
                                <div className="*:p-4 grid grid-cols-6 sticky top-0 z-10" id="sk-flights-search-comparison">
                                    <div className="col-span-2">{<span className="text-xs text-gray-700">Showing {Math.min(numOfDisplayFlights, flightSortOrder.length)} of {flightSortOrder.length} flights.</span>}</div>
                                    <PartnerHeader partnerSortOrder={partnerSortOrder} partnerDisplayOffset={partnerDisplayOffset} setPartnerDisplayOffset={setPartnerDisplayOffset} />
                                </div>
                                {flightSortOrder.length > 0 && <FlightComparison type={type} partnerSortOrder={partnerSortOrder} partnerDisplayOffset={partnerDisplayOffset} flightSortOrder={flightSortOrder} numOfDisplayFlights={numOfDisplayFlights} setNumOfDisplayFlights={setNumOfDisplayFlights} searchData={searchData} searchResponse={searchResponse} filters={filters} setSelectedFlight={setSelectedFlight} selectedFlight={selectedFlight} />}
                            </div>
                        </div>
                        {/* Searched Departure and Return Banner */}
                        {[1, 2].includes(type) && <div className="absolute top-0 left-[50%] -translate-y-1/3 -translate-x-1/2 z-10 flex gap-4">
                            <div className="bg-white drop-shadow-lg flex gap-2 p-1 rounded-lg">
                                <div className={"flex flex-col items-center py-3 px-8 rounded-lg bg-opacity-10 " + (type === 1 ? "bg-sk-blue" : "cursor-pointer")} onClick={() => setType(1)}>
                                    <span className="whitespace-nowrap font-semibold">{searchData.from} &rarr; {searchData.to}</span>
                                    <span className="text-xs font-medium">{utils.calcFlightSearchLongDate(searchData.departure_date)}</span>
                                </div>
                                {searchData.return_date && <div className={"flex flex-col items-center py-3 px-8 rounded-lg bg-opacity-10 " + (type === 2 ? "bg-sk-blue" : "cursor-pointer")} onClick={() => setType(2)}>
                                    <span className="whitespace-nowrap font-semibold">{searchData.to} &rarr; {searchData.from}</span>
                                    <span className="text-xs font-medium">{utils.calcFlightSearchLongDate(searchData.return_date)}</span>
                                </div>}
                            </div>
                        </div>}

                    </div>
                </div>
            }
                {/* selected flight display */}
                {selectedFlight && <div className={"fixed bg-white " + (isMobile ? "w-full left-0 bottom-0" : "bottom-0 z-10")} style={selectedFlightStyle}>
                    {isMobile
                        ? <div className="px-4 pt-1 pb-2 bg-sk-blue bg-opacity-15 flex gap-4 items-center justify-around w-full relative">
                            <img src="/assets/icons/cross.svg" alt="close" className="absolute top-2 right-0 h-7 p-2 inline-block self-end opacity-50 cursor-pointer" onClick={() => setSelectedFlight(null)} />
                            <SelectedFlightGroup flightData={selectedFlight.flight} searchData={searchData} />
                            <div className="flex flex-col items-center gap-1">
                                <span className="text-lg font-semibold">{selectedFlight.flight.fares[selectedFlight.fareIndex].customer_fare}</span>
                                <CommonFormButtonStateless text={"Take me to " + Constants.FLIGHTS.PARTNER_NAMES[selectedFlight.partner]} className="px-4 text-sm" short={true} onClick={redirect} />
                            </div>
                        </div>
                        : <div className="px-6 py-1 rounded-xl bg-sk-blue bg-opacity-15 flex gap-10 items-center justify-center relative">
                            <img src="/assets/icons/cross.svg" alt="close" className="absolute top-2 right-0 h-7 p-2 inline-block self-end opacity-50 cursor-pointer" onClick={() => setSelectedFlight(null)} />
                            <SelectedFlightGroup flightData={selectedFlight.flight} searchData={searchData} bigger={true} horizontal={true} />
                            <span className="text-xl font-semibold">{selectedFlight.flight.fares[selectedFlight.fareIndex].customer_fare}</span>
                            <CommonFormButtonStateless text={"Take me to " + Constants.FLIGHTS.PARTNER_NAMES[selectedFlight.partner]} className="px-4" short={true} onClick={redirect} />
                        </div>}
                </div>}
            </>
        </>
    )
}

export default SearchResults