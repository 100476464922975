import React, { useState } from 'react'
import { CommonFormButton, CommonPopup } from '../../..'
import API from '../../../../apis/index.js';
// eslint-disable-next-line
import paymentCollection from '../../../../apis/payment-collection.js';
import CashfreeSubmitted from './cashfree-submitted.jsx'

const CashfreeEsclation = () => {
    // eslint-disable-next-line
    const [url, setUrl] = useState(null)
    // eslint-disable-next-line
    const [pageState, setPageState] = useState(0)
    let [error, setError] = useState(null)
    // eslint-disable-next-line
    const [interval, setIntervalNum] = useState(null)

    const cashfreeApi = async function () {
        const response = await API.onboarding.cashfreeURL();
        if (response.status === 200 && response.data?.data) {
            setUrl(response.data.data)
            window.location.href = response?.data.data
            // setPageState(1)
            // setIntervalNum(setInterval(checkAccountStatus, 10000))
        }
        else { setError(response.error || "API Error!") }
    }

    return (
        <>
            {error && <CommonPopup onOk={() => setError(null)}><div>{error}</div></CommonPopup>}
            {pageState === 0 && <div className='flex items-center flex-col justify-center'>
                <img src='/assets/escalation.jpg' alt='pending document verification' className='max-h-[300px] w-auto my-4' />
                <p className='xs:text-lg text-xl text-center font-medium'>Your application was not approved.</p>
                <p className='mt-2 text-center'>Your application was not approved due to some discrepancy in the information provided.<br /> You may need to edit or provide more information.</p>
                <CommonFormButton onClick={cashfreeApi} className="mt-8 px-4 py-2" text="Re-submit your appplication" />
            </div>}
            {/* {pageState === 1 && <iframe title="cashfree-embed" src={url} className='w-full h-full mt-4 flex-1 min-h-[80vh]' />} */}
            {pageState === 2 && <CashfreeSubmitted />}
        </>
    )
}

export default CashfreeEsclation