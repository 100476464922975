import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Static = {
    rmDetails: async function () {
        return await callapi({
            url: endpoints.support.rmDetails,
            method: "get"
        })
    },
    createTicket: async function ({ subject, desc }) {
        if (!subject) return { error: "Subject is required." }
        else if (subject.length > 100) return { error: "Subject is too long." }
        else if (!desc) return { error: "Description is required." }
        else if (desc.length > 255) return { error: "Description is too long." }
        return await callapi({
            url: endpoints.support.createTicket,
            method: "post",
            data: { subject, desc }
        })
    }
}

export default Static