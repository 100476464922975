import React from 'react'
import PropTypes from 'prop-types'

const FileInput = (props) => {
    function focusInput() {
        document.getElementById(props.id).focus()
    }

    function onChange(e) {
        if (typeof props.onChange === "function") props.onChange(e.target.files)
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse " + (props.className ? props.className : "")} onClick={focusInput}>
            <input ref={props.inputRef || null} id={props.id} type="file" className="peer focus:outline-none font-medium inline-block w-full text-sm pt-1 file:py-1 file:float-right file:px-3 file:rounded-full file:border-0 file:text-xs file:font-semibold file:text-gray-700 file:bg-gray-100 hover:file:bg-gray-300 file:cursor-pointer" onChange={onChange} disabled={props.disabled ? true : false} accept={props.accept || ""} />
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

FileInput.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    accept: PropTypes.string
}

export default FileInput