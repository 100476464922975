import React from 'react'
import Login from './login'

const BackofficeLogin = () => {
    return (
        <Login base="/backoffice" email={true}>
            <p className="text-xl font-medium mt-5 mb-3 text-sk-blue">Backoffice Login</p>
        </Login>
    )
}

export default BackofficeLogin