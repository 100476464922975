import React, { useEffect } from 'react'
import auth from '../../../apis/auth'
import { useNavigate } from 'react-router-dom'
import { CommonLoader } from '../../index'
import MixPanel from '../../../config/mixpanel'

const Logout = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
        MixPanel.reset()
        auth.logout(() => {
            window.sessionStorage.clear()
            navigate(props.base + "/auth")
        })
    }, [navigate, props.base])

    return (
        <div className="flex flex-col items-center justify-center">
            <CommonLoader className="inline w-8 h-8 mb-2 text-gray-500" />
            <p>Please wait while you're being logged out.</p>
        </div>
    )
}

export default Logout