const Functions = {
    updateSessionVar: function (variable, balance) {
        const event = new StorageEvent('storage', {
            key: variable,
            oldValue: window.sessionStorage.getItem(variable),
            newValue: balance
        })
        window.sessionStorage.setItem(variable, balance)
        window.dispatchEvent(event)
    },
}

export default Functions