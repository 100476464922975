import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconContext } from 'react-icons'

const MenuSubItem = (props) => {
    const navigate = useNavigate()

    const linkClick = () => {
        navigate(props.link ? props.link : "#")
        props.closeNav()
    }

    return (
        // <Link to={props.link ? props.link : "#"} onClick={props.closeNav} >
        <div onClick={linkClick} className={"flex flex-row items-center p-3 cursor-pointer " + (props.active ? "bg-white rounded-s-full pl-4" : "hover:bg-sk-blue-select") + " " + (props.expanded ? props.active ? "ml-16" : "pl-20" : "px-6")}>
            <p className="mx-2">
                {props.logo && typeof props.logo === "string" && <img className={"inline-block h-5 w-5 " + (props.active && "text-white")} src={props.logo} alt="sub menu icon" />}
                {props.logo && typeof props.logo === "object" && <IconContext.Provider value={{ className: "h-5 w-5 " + (!props.active && "invert") }}>{props.logo}</IconContext.Provider>}
            </p>
            <p className={"grow text-xs uppercase line-clamp-1 " + (props.active === true ? "font-bold text-black" : "font-medium text-white")}>{props.text}</p>
        </div>
        // </Link>
    )
}

export default MenuSubItem