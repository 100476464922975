import React, { useState } from 'react'
import { CommonFormButton, CommonFormCurrencyInput, CommonFormDateInput, CommonFormSelect, CommonFormTextInput } from '../../..'

const CashDeposit = (props) => {
    const [data, setData] = useState({ type: "cash" })
    const updateData = (k, v) => setData(prev => ({ ...prev, [k]: v }))

    async function onSubmit() {
        if (!data.amount) props.onError("Please enter amount.")
        else if (!data.account) props.onError("Please select bank account where deposit was made.")
        else if (!data.date) props.onError("Please select date when deposit was made.")
        else await props.onSubmit(data)
    }

    return (
        <div className="grid grid-cols-2 xl:w-2/3 xs:w-full gap-y-6 gap-x-4">
            <p className="text-xs col-span-2">As cash has already been deposited into our account, kindly provide the transaction details and proceed with submitting the request to update the wallet balance.</p>
            <CommonFormCurrencyInput label="Amount" id="sk-wallet-cash-amount" onChange={(v) => updateData("amount", v)} />
            <CommonFormSelect label="Select SanKash Bank" id="sk-wallet-cash-account" onChange={(v) => updateData("account", v)} >
                <option value="ICICI">ICICI a/c ending 9631</option>
                <option value="HDFC">HDFC a/c ending 0034</option>
            </CommonFormSelect>
            <CommonFormDateInput label="Deposit Date" id="sk-wallet-cash-date" onChange={(v) => updateData("date", v)} />
            <CommonFormTextInput label="Remarks" id="sk-wallet-cash-remarks" onChange={(v) => updateData("remarks", v)} />
            <CommonFormButton text="Cancel" className="px-4" isOutline={true} onClick={props.onCancel} />
            <CommonFormButton text="Submit" className="px-4" onClick={onSubmit} />
        </div>
    )
}

export default CashDeposit