import React, { useEffect } from 'react'
import Customer from '../../../../apis/customer.js'
import { CommonLoader } from '../../../index.js'
import Constants from '../../../../config/constants.js'

const SendOTP = (props) => {
    useEffect(() => {
        async function sendOTP() {
            const response = await Customer.sendOTP({ applicationNumber: props.applicationNumber, data: props.data.data, mobile: props.data.mobile })
            if (response.status === 200) {
                // otp was successfully sent
                props.changeView(Constants.CUSTOMER_VIEWS.ENTER_OTP, null, response.data.data)
            } else if (response.status === 409) {
                // application in progress
                props.changeView(Constants.CUSTOMER_VIEWS.UNDER_PROGRESS)
            } else if (response.status === 429) {
                // too many otps generated
                props.changeView(Constants.CUSTOMER_VIEWS.API_ERROR, null, null, response.error)
            } else {
                // all other errors
                props.changeView(Constants.CUSTOMER_VIEWS.INTERNAL_ERROR)
            }
        }
        sendOTP()
    }, [props])

    return (
        <div className="flex-1 flex items-center justify-center"><p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Sending OTP...</p></div>
    )
}

export default SendOTP