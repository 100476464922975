import React from "react"
import Flight from "./tile-flight"
import { isMobile } from "react-device-detect"

const SelectedFlightGroup = ({ type, horizontal, bigger, flightData, searchData }) => {
    return (
        <>{
            flightData.return
                ? <div className={"flex " + (isMobile && "flex-col")}>
                    <Flight type={type} horizontal={false} bigger={false} flightData={flightData.onward} searchData={searchData} selectedFlight={true} />
                    <Flight type={type} horizontal={false} bigger={false} flightData={flightData.return} searchData={searchData} selectedFlight={true} />
                </div>
                : <Flight type={type} horizontal={horizontal} bigger={bigger} flightData={flightData.onward} searchData={searchData} />
        }</>
    )
}

export default SelectedFlightGroup