import React from 'react'
import PropTypes from 'prop-types'

const Checkbox = (props) => {
    let isChecked = props.checked || false

    function toggle() {
        typeof props.onChange === "function" && props.onChange(!isChecked)
    }

    return (
        <div className={"rounded-sm border-2 flex items-center " + (props.disabled ? "border-gray-500" : "border-sk-blue cursor-pointer") + " " + (isChecked ? props.disabled ? "bg-gray-500" : "bg-sk-blue" : "") + " " + (props.className ? props.className : "")} onClick={toggle}>
            {isChecked ? <img src="/assets/icons/tick.svg" alt="checked" className="invert inline select-none" /> : ""}
        </div>
    )
}

Checkbox.propTypes = {
    checked: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool
}

export default Checkbox