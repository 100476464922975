import endpoints from "../config/endpoints"
import callapi from "./callapi"

const Flights = {
    search: async function (data) {
        return callapi({
            url: endpoints.flights.search,
            method: "post",
            data
        })
    }
}

export default Flights