import React, { useEffect, useState } from "react"
import { CommonPopup, CommonLoader, CommonError } from "../../.."
import API from "../../../../apis"
import utils from "../../../common/utils"
import AddDisbursementTracker from "./add-disbursement-tracker"

const DisbursementTracker = ({ application, onOk, onCancel }) => {
    const [isRunning, setIsRunning] = useState(false)
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [newData, setNewData] = useState({})

    useEffect(() => {
        // fetch existing disbursements
        const fetch = async () => {
            const response = await API.backoffice.applicationTransactions.getDisbursements(application)
            if (response.status === 200 && response.data?.data) {
                setData(response.data?.data)
            } else {
                setError(response.error || "API Error")
            }
            setIsRunning(false)
        }
        fetch()
        setError(null)
        setIsRunning(true)
    }, [application])

    const updateNewDisbursementData = (disbursementId, index, key, value) => {
        setNewData(prev => {
            const update = { ...prev }
            if (!(disbursementId in update)) update[disbursementId] = {}
            if (!(index in update[disbursementId])) update[disbursementId][index] = {}
            update[disbursementId][index][key] = value
            return update
        })
    }

    const updateDisbursementTracker = async () => {
        setIsRunning(true)
        if (Object.keys(newData).length === 0) return onOk()
        const setData = []
        for (let disbursementId in newData) {
            for (let idx in newData[disbursementId]) {
                setData.push({ ...newData[disbursementId][idx], id: disbursementId })
            }
        }
        const response = await API.backoffice.applicationTransactions.setDisbursementTracker(application, setData)
        if (response.status === 200) onOk()
        else setError(response.error || "API Error!")
        setIsRunning(false)
    }

    return (
        <CommonPopup onOk={updateDisbursementTracker} disableOk={isRunning} onCancel={onCancel} dimensions="w-full xl:w-2/3" sticky={true} showCancel={true}>
            <div className="flex flex-col gap-y-8 text-left">
                <div className="font-medium text-center underline underline-offset-2">DISBURSEMENT TRACKER: {application}</div>
                {error && <p className="text-center"><CommonError error={error} /></p>}
                {data === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {data && Array.isArray(data) && data.map((d, i) => <div key={i} className="flex flex-col text-center">
                    {/* heading */}
                    <p className="text-sm">{`${i + 1}. Disbursement Tracker for - ${d.lender} - ${utils.formatINR(d.amount, 2, true)} - ${d.tenure} months - ${d.product_type}`}</p>
                    {/* existing disbursements */}
                    {d.info?.dt && Array.isArray(d.info.dt) && d.info.dt.map((dt, j) => <div key={j} className="text-sm">{`${(i + 1) + "." + (j + 1)} | Date: ${utils.formatDate(dt.date)} | Amount: ${utils.formatINR(dt.amount, 2, true)} | UTR: ${dt.utrNo}`}</div>)}
                    {/* add new disbursement */}
                    <AddDisbursementTracker index={i} disbursementId={d.id} updateNewDisbursementData={updateNewDisbursementData} />
                </div>)}
                <p className="text-xs mt-4 text-center">Note: to update, use the same UTR No and make changes as required.</p>
            </div>
        </CommonPopup>
    )
}

export default DisbursementTracker