import React from 'react'
import PropTypes from 'prop-types'

const PopupInput = (props) => {
    return (
        <div className={"bg-white relative border rounded-xl border-sk-blue py-3 px-6 flex flex-col-reverse " + (props.className ? props.className : "")} onClick={props.show}>
            <span id={props.id} type="email" className={"peer focus:outline-none font-medium inline-block w-full truncate line-clamp-1 " + (props.value ? "text-black" : "text-gray-500")} onClick={props.show}>{props.value || "Select"}</span>
            <label className="text-sm block text-sk-blue font-semibold">{props.label}</label>
        </div>
    )
}

PopupInput.propTypes = {
    id: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string
}

export default PopupInput