import React, { useCallback, useEffect, useState } from 'react'
import { CommonPagination, CommonTable, CommonError, CommonLoader, CommonBadges } from '../../../index'
import transactions from '../../../../apis/transactions'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../../config/mixpanel'
import { default as PaymentDetails } from './payment-details'
import API from '../../../../apis'

const Insurance = () => {
    // eslint-disable-next-line
    let [filters, setFilters] = useState({})
    let [error, setError] = useState(null)
    let [count, setCount] = useState(null)
    let [numPages, setNumPages] = useState(1)
    let [currentPage, setCurrentPage] = useState(1)
    let [data, setData] = useState(null)
    let navigate = useNavigate()
    let [detail, setDetail] = useState(null)

    const fetchCount = useCallback(async function () {
        const response = await transactions.paymentCollection.getCount(filters)
        if (response.status === 200 && "data" in response.data && "count" in response.data.data) {
            setCount(response.data.data.count)
            if (response.data.data.count > 0) {
                setNumPages(response.data.data.num_pages)
                return true
            } else {
                setError("No Results Found!")
            }
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError("API Error!")
        }
        return false
    }, [filters, navigate])

    const fetchData = useCallback(async function () {
        const response = await transactions.paymentCollection.getData(filters, currentPage)
        if (response.status === 200 && "data" in response.data) {
            setData(response.data.data)
        } else if (response.status === 401) {
            navigate("/agent/auth")
        } else {
            setError("API Error!")
        }
    }, [currentPage, filters, navigate])

    useEffect(() => {
        setError(null)
        setCount(null)
        setData(null)
        fetchCount().then(x => x && fetchData())
    }, [filters, fetchCount, fetchData])

    useEffect(() => {
        setError(null)
        setData(null)
        fetchData()
    }, [currentPage, fetchData])

    useEffect(() => {
        MixPanel.track(MixPanel.types.agent.transactions.payment)
    }, [])

    return (
        <div className="mt-4 mb-10">
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {count && count > 0 && data && <div className='w-full text-center'><CommonBadges.General title="New" text="Click on Payment Collection ID to view Payment and Settlement details." /></div>}
            {count > 0 && data && <CommonTable className="mt-4" data={data} showDetails={setDetail} highlighted={detail} linked={0} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <PaymentDetails id={detail} onClose={() => setDetail(null)} detailAPI={API.transactions.paymentCollection.getDetail} />}
        </div>
    )
}

export default Insurance