import React, { useState } from 'react'
import { CommonFormButton, CommonPopup, CommonFormCheckbox } from '../../../index'
import { default as InsurancePlanBanner } from './insurance-plan-banner'
import Constants from '../../../../config/constants'
import utils from '../../../common/utils'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const InsuranceResult = (props) => {
    const { searchData, searchResults } = props.data
    let [selectedPlan, setSelectedPlan] = useState(null)
    const [error, setError] = useState(null)
    const [priceToAgent, setPriceToAgent] = useState(true)
    const [selectedPlansForEmail, setSelectedPlansForEmail] = useState({})
    const countOfPlansForEmailShare = Object.values(selectedPlansForEmail).filter(x => x).length
    // eslint-disable-next-line
    const [insurerFilter, setInsurerFilter] = useState(0)

    function selectPlan(id) {
        if (id === selectedPlan) setSelectedPlan(null)
        else setSelectedPlan(id)
    }

    function onSubmit() {
        if (selectedPlan == null) {
            setError("Please select a plan!")
            return
        }

        // const walletBalance = window.sessionStorage.getItem(Constants.SessionVariables.WalletBalance) * 1 || 0
        // if (walletBalance < (searchResults.filter(x => x.id === selectedPlan)[0].net_amount * 1)) {
        //     setError("Wallet balance is low. Kindly add money to wallet and try again.")
        //     return
        // }

        props.changeView(null, 2, null, { selectedPlan: searchResults.filter(x => x.id === selectedPlan)[0] })
        MixPanel.track(MixPanel.types.agent.insurance.select_premium)

    }

    async function emailQuotesToAgent() {
        if (countOfPlansForEmailShare === 0) return
        const response = await API.insurance.mailQuotes({ searchData, selectedPlans: selectedPlansForEmail })
        if (response.status === 200) {
            setError("Quotes have been shared over your registered email.")
            MixPanel.track(MixPanel.types.agent.insurance.email_quotes)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="">
            <div className="w-full p-6 bg-sk-yellow rounded-2xl flex gap-3 flex-col lg:flex-row text-sm lg:text-base">
                <div className="flex-1 px-4">
                    <p className="font-semibold">{Constants.INSURANCE.TRIP_TYPE[searchData.trip_type]}</p>
                    <p className="font-semibold">{Constants.INSURANCE.DESTINATION_TYPE[searchData.destination_type]}</p>
                </div>
                <div className="flex-1 px-4">
                    <p className="font-semibold">No. of Pax: {searchData.num_pax.length}</p>
                    <p className="font-semibold">Age: {searchData.num_pax.map(x => utils.formatInsuranceAge(x)).join(", ")}</p>
                </div>
                <div className="flex-1 px-4">
                    {searchData.num_days && <p className="font-semibold">Trip Duration: {searchData.num_days} days</p>}
                    {searchData.num_days && <p className="font-semibold">(<span>{utils.formatDate(searchData.departure_date)}</span>{searchData.return_date && <span> to {utils.formatDate(searchData.return_date)}</span>})</p>}
                    {!searchData.num_days && <p className="font-semibold">Departure Date: {searchData.departure_date}</p>}
                    <br />
                    <button type="button" className="bg-sk-blue rounded-2xl px-4 py-2 text-white text-sm font-semibold" onClick={() => props.changeView(null, 0)}>Modify search</button>
                </div>
            </div>
            <div className="w-full mt-4 ml-2 flex flex-col md:flex-row gap-4 justify-between items-center">
                <div className="flex flex-col gap-2">
                    <span className="font-semibold lg:text-lg inline-block">Click a plan to select</span>
                    {/* <div className="flex gap-x-2 items-center flex-col md:flex-row">
                        <span className="text-xs">Select Insurer</span>
                        <div className="flex flex-row *:flex-1 gap-2">
                            <CommonFormButton short={true} isOutline={insurerFilter !== 0} text="ALL" className="px-2 text-xs" onClick={setInsurerFilter.bind(this, 0)} />
                            {searchResults.some(x => x.insurer_id === 1) && <CommonFormButton short={true} isOutline={insurerFilter !== 1} text="ICICI LOMBARD" className="px-2 text-xs" onClick={setInsurerFilter.bind(this, 1)} />}
                            {searchResults.some(x => x.insurer_id === 2) && <CommonFormButton short={true} isOutline={insurerFilter !== 2} text="CARE HEALTH" className="px-2 text-xs" onClick={setInsurerFilter.bind(this, 2)} />}
                        </div>
                    </div> */}
                </div>
                <div className="flex space-x-1 cursor-pointer items-center flex-col lg:flex-row gap-2">
                    <button type="button" className={"rounded-2xl px-3 py-1 text-xs font-semibold mr-2 border " + (countOfPlansForEmailShare > 0 ? "bg-sk-blue text-white border-sk-blue cursor-pointer" : "text-gray-500 border border-gray-500 cursor-default")} disabled={countOfPlansForEmailShare === 0} onClick={emailQuotesToAgent}>Email Selected Quotes {countOfPlansForEmailShare > 0 && <span>({countOfPlansForEmailShare})</span>}</button>
                    <div className="flex gap-1">
                        <CommonFormCheckbox className="flex-none w-3 h-3 mt-0.5" checked={!priceToAgent} onChange={() => setPriceToAgent(p => !p)} />
                        <span className="text-xs" onClick={() => setPriceToAgent(p => !p)}>Hide Price to Agent</span>
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-6 w-full gap-6 mt-4">
                {searchResults.filter(x => insurerFilter === 0 ? true : x.insurer_id === insurerFilter).map((r, i) =>
                    <InsurancePlanBanner key={i} id={r.id} heading={r.plan_name} price={r.premium} destinationType={searchData.destination_type} sumInsured={r.sum_insured} className="col-span-6 lg:col-span-3" active={selectedPlan === r.id} onClick={() => selectPlan(r.id)} agentPrice={priceToAgent ? r.net_amount : null} emailShareSelected={selectedPlansForEmail[r.id]} selectEmailShare={(k, v) => setSelectedPlansForEmail(prev => ({ ...prev, [k]: v }))} />
                )}
            </div>
            <div className="flex justify-center">
                <CommonFormButton id="sk-ins-res-submit" text="Continue" className="w-full lg:w-1/2 my-8" onClick={onSubmit} />
            </div>
            {error && <CommonPopup onOk={() => setError(null)} onCancel={() => setError(null)}>
                <div>{error}</div>
            </CommonPopup>}
        </div>
    )
}

export default InsuranceResult