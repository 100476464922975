import { useEffect } from 'react'
import { Outlet, useNavigate, Link } from 'react-router-dom'
import { AgentHeader } from '../../index'
import Auth from '../../../apis/auth'

const User = (props) => {
    const navigate = useNavigate()

    useEffect(() => {
        Auth.checkAsync((loggedIn) => {
            if (loggedIn === false) {
                navigate(props.base + "/auth")
                return
            }
        })
    }, [navigate, props.base])

    return (
        <div className="flex flex-col bg-gray-50">
            <div className="flex flex-row justify-between">
                <div className="pt-2 flex-none">
                    <Link to="/agent"><img className="h-20 ml-4" src="/assets/sankash-logo.svg" alt="Sankash Logo" /></Link>
                </div>
                <AgentHeader className="" brand={true} />
            </div>
            <div className="flex-1">
                <Outlet />
            </div>
        </div>
    )
}

export default User