import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import Popup from './popup'

const Tooltip = (props) => {
    const [visible, setVisible] = useState(false)

    return (
        <div className={"relative " + (props.className || "")}>
            <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
                {React.Children.toArray(props.children)[0]}
            </div>

            {visible && <>
                {!isMobile
                    ? <div className={"absolute z-10 mt-1 inline-block px-3 py-2 text-sm font-medium transition-opacity duration-300 bg-white rounded-lg drop-shadow-lg " + (props.width || "w-96") + " " + (props.bottomLeft ? "right-0" : "")}>
                        {React.Children.toArray(props.children)[1]}
                    </div>
                    : <Popup onOk={() => setVisible(false)} onCancel={() => setVisible(false)}>
                        {React.Children.toArray(props.children)[1]}
                    </Popup>}
            </>}
        </div>
    )
}

export default Tooltip