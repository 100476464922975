import React, { useState } from "react";
import Selection from "./tile-selection"
import { isMobile } from "react-device-detect";
import FlightGroup from "./flight-group";
import { MdOutlineDoubleArrow } from "react-icons/md";

const FlightComparison = ({ type, partnerSortOrder, partnerDisplayOffset, flightSortOrder, numOfDisplayFlights, setNumOfDisplayFlights, searchData, searchResponse, filters, setSelectedFlight, selectedFlight }) => {

    const [viewMore, setViewMore] = useState({})
    const updateViewMore = (i, val) => setViewMore(prev => ({ ...prev, [i]: val }))

    const flightsToDisplay = flightSortOrder.slice(0, numOfDisplayFlights)

    const partnersToDisplay = partnerSortOrder.slice(partnerDisplayOffset, partnerDisplayOffset + 4)
    const flightDetails = (flight_id, partner) => {
        if (partner) return searchResponse[partner][flight_id]
        for (let partner of partnerSortOrder) {
            if (searchResponse[partner][flight_id]) {
                return searchResponse[partner][flight_id]
            }
        }
    }

    // assume first flight and first fare is the cheapest
    const cheapest_fare = flightDetails(flightsToDisplay[0])?.fares[0]?.customer_fare_raw || 0
    const hasViewMore = (flight_id) => partnersToDisplay.some(partner => flightDetails(flight_id, partner)?.fares?.filter(x => filters?.popularSMEFares === true ? x.fare_type.toLowerCase().includes("sme") : true)?.length > 1 || false)

    const showMoreFlights = () => {
        setNumOfDisplayFlights(curr => {
            return Math.min(curr + 10, flightSortOrder.length)
        })
    }

    return (
        <>
            {isMobile
                ? flightsToDisplay.map((flight_id, i) =>
                    <div key={i} className="grid grid-flow-col mb-6 *:overflow-hidden relative" id={i === 0 ? "sk-flights-search-mobile-row" : "sk-flights-search-mobile-row-" + (i)}>
                        <div className="w-32 px-2">
                            <FlightGroup type={type} flightData={flightDetails(flight_id)} searchData={searchData} />
                        </div>
                        {
                            partnersToDisplay.map((partner, j) => <div key={j} className="w-32"><Selection type={type} partner={partner} flightData={flightDetails(flight_id, partner)} expanded={viewMore[i]} cheapest_fare={cheapest_fare} searchData={searchData} filters={filters} setSelectedFlight={setSelectedFlight} selectedFlight={selectedFlight} /></div>)
                        }
                        {
                            hasViewMore(flight_id) && <span className="absolute bottom-0 left-[50%] -translate-x-1/2 translate-y-4 bg-sk-blue-light p-0 px-4 py-1 text-xs font-medium rounded-full cursor-pointer" onClick={() => updateViewMore(i, viewMore[i] ? false : true)}>{viewMore[i] ? "View Less" : "View More"}</span>
                        }
                    </div>
                )
                : flightsToDisplay.map((flight_id, i) =>
                    <div key={i} className="grid grid-cols-6 relative shadow-[0_0px_15px_-3px_rgba(0,0,0,0.1)] rounded-xl">
                        <div className="col-span-2 flex flex-col">
                            <FlightGroup type={type} flightData={flightDetails(flight_id)} searchData={searchData} />
                        </div>
                        {
                            partnersToDisplay.map((partner, j) => <div key={j}><Selection type={type} partner={partner} flightData={flightDetails(flight_id, partner)} expanded={viewMore[i]} cheapest_fare={cheapest_fare} searchData={searchData} filters={filters} setSelectedFlight={setSelectedFlight} selectedFlight={selectedFlight} /></div>)
                        }
                        {
                            hasViewMore(flight_id) && <span className="absolute bottom-0 left-[50%] -translate-x-1/2 translate-y-4 bg-sk-blue-light p-0 px-4 py-1 text-xs font-medium rounded-full cursor-pointer" onClick={() => updateViewMore(i, viewMore[i] ? false : true)}>{viewMore[i] ? "View Less" : "View More"}</span>
                        }
                    </div>
                )}

            {numOfDisplayFlights < flightSortOrder.length && <div className="text-center pt-8">
                <span className="text-center text-sm font-semibold cursor-pointer border-sk-blue rounded-full border px-3 py-2" onClick={showMoreFlights}><MdOutlineDoubleArrow className="text-sk-blue w-6 h-6 inline-block -mt-1 rotate-90" /> More Flights</span>
            </div>}
        </>
    )
}

export default FlightComparison