import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormSelect, CommonAccordion, CommonFormDateInput, CommonFormButton } from '../../../index'
import API from '../../../../apis'
import PassportScanner from './passport-scanner'

const Passenger = (props) => {
    const worldwideWithUsCanada = ["Australia", "Austria", "Belgium", "Canada", "China", "Denmark", "Finland", "France", "Germany", "Hong Kong", "Hungary", "Ireland", "Israel", "Italy", "Japan", "Malaysia", "Maldives", "Netherlands", "New Zealand", "Norway", "Phillipenes", "Portugal", "S.Korea", "Singapore", "Spain", "Sweden", "Switzerland", "Thailand", "UK", "United Arab Emirates", "USA", "Other"]
    const worldwideWithoutUsCanada = ["Australia", "Austria", "Belgium", "China", "Denmark", "Finland", "France", "Germany", "Hong Kong", "Hungary", "Ireland", "Israel", "Italy", "Japan", "Malaysia", "Maldives", "Netherlands", "New Zealand", "Norway", "Phillipenes", "Portugal", "S.Korea", "Singapore", "Spain", "Sweden", "Switzerland", "Thailand", "UK", "United Arab Emirates", "Other"]

    const [showPassportScanner, setShowPassportScanner] = useState(false)

    const updateData = async function (k, v) {
        await props.updateData(props.i, k, v)
        if (k === "pincode" && v.length === 6) {
            const response = await API.util.pincode(v)
            if (response.status === 200 && response.data?.data) {
                await props.updateData(props.i, "city", response.data.data.district)
                document.getElementById("sk-insur-passg-" + props.i + "-city").value = response.data.data.district
                await props.updateData(props.i, "state", response.data.data.state)
                document.getElementById("sk-insur-passg-" + props.i + "-state").value = response.data.data.state
            }
        }
    }

    return (
        <CommonAccordion className="flex-1 mt-4 p-4" heading={"Passenger " + (props.i + 1) + " (Age " + (props.x >= 1 ? props.x + " years" : Math.round(props.x * 100) + " months") + ")"} show={props.show || false}>
            <form className="grid grid-cols-12 gap-x-4 gap-y-4 mt-6" autoComplete="off">

                <div className="col-span-12 text-center">
                    <CommonFormButton className="px-2 text-xs" short={true} isOutline={true} text="Scan Passport" onClick={() => setShowPassportScanner(true)} />
                </div>

                <p className="col-span-12 font-bold text-sm flex justify-between items-center">
                    <span>Traveller Personal Details</span>
                    {props.i > 0 && <CommonFormButton className="px-2 text-xs" short={true} isOutline={true} text="Copy section from Passenger 1" onClick={() => props.copySection(props.i, ["mobile", "email"])} />}
                </p>

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-title"} label="Title" className="col-span-full xl:col-span-2" onChange={v => updateData("title", v)} defaultValue={props.data?.title ? [props.data?.title, props.data?.title + "."] : null}>
                    <option value="Mr">Mr.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Mrs">Mrs.</option>
                </CommonFormSelect>

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-first_name"} label="First Name of Traveller" className="col-span-full xl:col-span-5" minLength="3" maxLength="50" onChange={v => updateData("first_name", v)} value={props.data?.first_name || null} regex="[^A-Za-z\. ]" />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-last_name"} label="Last Name of Traveller" className="col-span-full xl:col-span-5" minLength="3" maxLength="50" onChange={v => updateData("last_name", v)} value={props.data?.last_name || null} regex="[^A-Za-z\. ]" />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-mobile"} label="Mobile Number of Traveller" type="tel" minLength="10" maxLength="10" className="col-span-full xl:col-span-6" onChange={v => updateData("mobile", v)} value={props.data?.mobile || null} />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-email"} label="E-mail Id of Traveller" type="email" className="col-span-full xl:col-span-6" maxLength="50" onChange={v => updateData("email", v)} value={props.data?.email || null} />

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-gender"} label="Gender" className="col-span-full xl:col-span-6" onChange={v => updateData("gender", v)} defaultValue={props.data?.gender ? [props.data?.gender, props.data?.gender] : null}>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                </CommonFormSelect>

                <CommonFormDateInput id={"sk-insur-passg-" + props.i + "-dob"} label="Date of Birth" className="col-span-full xl:col-span-6" onChange={v => updateData("dob", v)} value={props.data?.dob || null} />

                <p className="col-span-full font-bold text-sm flex justify-between items-center mt-4">
                    <span>Traveller Address Details</span>
                    {props.i > 0 && <CommonFormButton className="px-2 text-xs" short={true} isOutline={true} text="Copy section from Passenger 1" onClick={() => props.copySection(props.i, ["address", "pincode", "city", "state"])} />}
                </p>

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-address"} label="Address" className="col-span-full" minLength="3" maxLength="100" onChange={v => updateData("address", v)} value={props.data?.address || null} />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-pincode"} label="Pincode" type="number" min="100000" max="999999" className="col-span-full xl:col-span-4" onChange={v => updateData("pincode", v)} value={props.data?.pincode || null} />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-city"} label="City" className="col-span-full xl:col-span-4" disabled={true} onChange={v => updateData("city", v)} value={props.data?.city || null} />

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-state"} label="State" className="col-span-full xl:col-span-4" disabled={true} onChange={v => updateData("state", v)} value={props.data?.state || null} />

                <p className="col-span-full font-bold text-sm flex justify-between items-center mt-4">
                    <span>Travel and Passport Details</span>
                    {props.i > 0 && <CommonFormButton className="px-2 text-xs" short={true} isOutline={true} text="Copy section from Passenger 1" onClick={() => props.copySection(props.i, ["occupation", "resident", "destination", "passport_country"])} />}
                </p>

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-occupation"} label="Occupation" className="col-span-full xl:col-span-4" onChange={v => updateData("occupation", v)} defaultValue={props.data?.occupation ? [props.data?.occupation, props.data?.occupation] : null}>
                    <option value="Service">Service</option>
                    <option value="Others">Others</option>
                </CommonFormSelect>

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-resident"} label="Resident Status" className="col-span-full xl:col-span-4" onChange={v => updateData("resident", v)} defaultValue={props.data?.resident ? [props.data.resident, props.data.resident] : null}>
                    <option value="Resident">Resident</option>
                    <option value="Non-Resident">Non-Resident</option>
                </CommonFormSelect>

                {props.searchData.destination_type === "1" && <CommonFormSelect id={"sk-insur-passg-" + props.i + "-destination"} label="Destination" className="col-span-full xl:col-span-4" onChange={v => updateData("destination", v)} defaultValue={props.data?.destination ? [props.data?.destination, props.data?.destination] : null}>
                    <option value="India">India</option>
                </CommonFormSelect>}

                {props.searchData.destination_type === "2" && <CommonFormSelect id={"sk-insur-passg-" + props.i + "-destination"} label="Destination" className="col-span-full xl:col-span-4" onChange={v => updateData("destination", v)} defaultValue={props.data?.destination ? [props.data?.destination, props.data?.destination] : null}>
                    {worldwideWithUsCanada.map((x, i) => <option key={i} value={x}>{x}</option>)}
                </CommonFormSelect>}

                {props.searchData.destination_type === "3" && <CommonFormSelect id={"sk-insur-passg-" + props.i + "-destination"} label="Destination" className="col-span-full xl:col-span-4" onChange={v => updateData("destination", v)} defaultValue={props.data?.destination ? [props.data?.destination, props.data?.destination] : null}>
                    {worldwideWithoutUsCanada.map((x, i) => <option key={i} value={x}>{x}</option>)}
                </CommonFormSelect>}

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-passport-country"} label="Country of issue of Passport" className="col-span-full xl:col-span-6" onChange={v => updateData("passport_country", v)} defaultValue={props.data?.passport_country ? [props.data?.passport_country, props.data?.passport_country] : null}>
                    <option value="India">India</option>
                </CommonFormSelect>

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-passport-number"} label="Passport no" className="col-span-full xl:col-span-6" minLength="3" maxLength="20" onChange={v => updateData("passport_no", v)} value={props.data?.passport_no || null} placeholder={props.searchData.destination_type === "1" ? "Optional" : ""} />

                <p className="col-span-full font-bold text-sm flex justify-between items-center mt-4">
                    <span>Nominee Details</span>
                    {props.i > 0 && <CommonFormButton className="px-2 text-xs" short={true} isOutline={true} text="Copy section from Passenger 1" onClick={() => props.copySection(props.i, ["nominee_title", "nominee_name", "nominee_relation"])} />}
                </p>

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-nominee-title"} label="Title" className="col-span-full xl:col-span-2" onChange={v => updateData("nominee_title", v)} defaultValue={props.data?.nominee_title ? [props.data?.nominee_title, props.data?.nominee_title + "."] : null}>
                    <option value="Mr">Mr.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Mrs">Mrs.</option>
                </CommonFormSelect>

                <CommonFormTextInput id={"sk-insur-passg-" + props.i + "-nominee-name"} label="Full Name" className="col-span-full xl:col-span-5" onChange={v => updateData("nominee_name", v)} value={props.data?.nominee_name || null} />

                <CommonFormSelect id={"sk-insur-passg-" + props.i + "-nominee-relation"} label="Relation with Insured" className="col-span-full xl:col-span-5" onChange={v => updateData("nominee_relation", v)} defaultValue={props.data?.nominee_relation ? [props.data?.nominee_relation, props.data?.nominee_relation] : null}>
                    <option value="Spouse">Spouse</option>
                    <option value="Father">Father</option>
                    <option value="Mother">Mother</option>
                    <option value="Brother">Brother</option>
                    <option value="Sister">Sister</option>
                    <option value="Child">Child</option>
                    {/* <option value="Employer">Employer</option> */}
                    <option value="Others">Others</option>
                </CommonFormSelect>
            </form>

            {showPassportScanner && <PassportScanner hide={() => setShowPassportScanner(false)} updateData={updateData} />}
        </CommonAccordion>
    )
}

export default Passenger