import React, { useState } from "react"
import { MdFlight } from 'react-icons/md'
import { CommonFormAirportInput, CommonFormDateInput, CommonFormRadioButtons, CommonFormPopupInput, CommonFormButton, CommonError } from "../../.."
import { TbArrowsExchange } from "react-icons/tb"
import TravelClass from "./travel-class"

const HomeSearch = (props) => {
    const [data, setData] = useState(props.data ? { ...props.data } : { round_trip: false, fare_type: "REGULAR" })
    const [showTravelAndClass, setShowTravelAndClass] = useState(false)
    const [error, setError] = useState(null)

    const updateData = (k, v) => {
        setError(null)
        setData(prev => ({ ...prev, [k]: v }))
    }

    const swapFromTo = () => {
        if (!data.from && !data.to) return
        setData(prev => {
            const newData = { ...prev }
            newData.fromBkp = newData.from
            newData.from = newData.to
            newData.to = newData.fromBkp
            delete newData.fromBkp
            return newData
        })
    }

    const travellerClassValue = () => {
        let val = null
        if (data.adult || data.child || data.infant) {
            val = (data.adult ?? 0) * 1 + (data.child ?? 0) * 1 + (data.infant ?? 0) * 1
            if (val === 1) val += " Traveller"
            else if (val > 1) val += " Travellers"
        }
        if (data.travel_class) {
            if (val) val += ", " + data.travel_class
            else val = data.travel_class
        }
        return val
    }

    const submit = () => {
        if (!data.from) return setError(`Origin city is required.`)
        if (!data.to) return setError(`Destination city is required.`)
        if (data.to === data.from) return setError(`Origin City and Destination City cannot be same.`)
        if (!data.departure_date) return setError(`Departure date is required.`)
        if (data.departure_date < new Date().toISOString().substring(0, 10)) return setError(`Departure date cannot be in the past.`)
        if (data.round_trip) {
            if (!data.return_date) return setError(`Return date is required.`)
            if (data.return_date < new Date().toISOString().substring(0, 10)) return setError(`Return date cannot be in the past.`)
            if (data.return_date < data.departure_date) return setError(`Departure date cannot be after Return date.`)
        }
        if (!data.adult) return setError(`Atleast 1 Adult traveller is required.`)
        if (!data.travel_class) return setError(`Travel class is required.`)

        props.setView(1, data)
    }

    return (
        <div className={"w-full relative " + (props.className || "")}>
            <div className="flex justify-center my-0">
                <div className={(props.popup ? "w-full p-12 mx-4" : "w-full xl:w-2/3 2xl:w-1/2") + " grid grid-cols-2 gap-4 border border-sk-blue rounded-lg p-8 bg-gray-50"}>
                    <CommonFormRadioButtons className="col-span-full flex justify-center mt-4" options={["One Way", "Round Trip"].map(x => ({ key: x, text: x }))} defaultValue={data.round_trip ? "Round Trip" : "One Way"} onChange={v => updateData("round_trip", v === "Round Trip")} size={CommonFormRadioButtons.sizes.xs} />
                    <div className="flex flex-col lg:flex-row lg:items-center col-span-full gap-2 relative">
                        <CommonFormAirportInput className="flex-1" getDestinationType={() => "A"} id="sk-flights-from" label="From" onChange={v => updateData("from", v)} defaultValue={data.from} />
                        <p className="text-center"><TbArrowsExchange className="hidden lg:inline-block text-sk-blue w-5 h-5 flex-none cursor-pointer" onClick={swapFromTo} /></p>
                        <CommonFormAirportInput className="flex-1" getDestinationType={() => "A"} id="sk-flights-to" label="To" onChange={v => updateData("to", v)} defaultValue={data.to} />
                    </div>
                    <div className="flex flex-col lg:flex-row col-span-full gap-4">
                        <CommonFormDateInput className="flex-1" id="sk-flights-departure" label="Departure" onChange={v => updateData("departure_date", v)} defaultValue={data.departure_date || null} />
                        {data.round_trip === true && <CommonFormDateInput className="flex-1 transition-all duration-300" id="sk-flights-return" label="Return" onChange={v => updateData("return_date", v)} defaultValue={data.return_date || null} />}
                    </div>
                    <CommonFormPopupInput className="col-span-full" label="Travellers & Class" id="sk-flights-travellers-class" show={() => setShowTravelAndClass(true)} value={travellerClassValue()} />
                    <div className="col-span-full flex items-center gap-2">
                        <span className="text-xs">Special fares:</span>
                        <CommonFormRadioButtons options={["REGULAR", "STUDENT", "SENIOR CITIZEN", "ARMED FORCES"].map(x => ({ key: x, text: x }))} defaultValue={data.fare_type || "Regular"} onChange={v => updateData("fare_type", v)} size={CommonFormRadioButtons.sizes.xs} className="flex flex-wrap" />
                    </div>
                    {error && <div className="col-span-full"><CommonError error={error} /></div>}
                    <div className="flex justify-center col-span-full"><CommonFormButton className="px-8" text="Search" onClick={submit} /></div>
                </div>
            </div>
            <div className="absolute top-0 left-[50%] -translate-x-1/2 -translate-y-2/3 drop-shadow-lg">
                <div className="py-2 px-4 rounded-xl border border-sk-blue bg-white flex flex-col items-center justify-center">
                    <MdFlight className="rotate-90 h-8 w-8 text-sk-blue" />
                    <span className="text-xs">Flights</span>
                </div>
            </div>
            {showTravelAndClass && <TravelClass hide={() => setShowTravelAndClass(false)} updateData={updateData} data={data} />}
        </div>
    )
}

export default HomeSearch