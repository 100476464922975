import React from 'react'
import PropTypes from 'prop-types'

const Button = (props) => {
    return (
        <button disabled={props.disabled} type={props.type || "button"} className={"rounded-xl font-semibold select-none focus:outline-none uppercase " + (props.disabled ? "bg-gray-300 text-white" : (props.isOutline ? "bg-white text-sk-blue border-sk-blue border-2 hover:bg-sk-blue hover:text-white" : "bg-sk-blue text-white")) + " " + (props.short ? "py-2" : "py-4") + " " + (props.className ? props.className : "")} onClick={props.onClick}>
            {props.text}
        </button>
    )
}

Button.propTypes = {
    type: PropTypes.string,
    onClick: PropTypes.func,
    isOutline: PropTypes.bool,
    className: PropTypes.string,
    short: PropTypes.bool,
    text: PropTypes.string,
    disabled: PropTypes.bool
}

export default Button