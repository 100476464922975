import React from 'react'
import { Outlet } from 'react-router-dom'

const Layout = () => {
    return (
        <div className="w-full h-full flex flex-col bg-gray-100 justify-center items-center">
            <div className="flex-1 h-full w-full sm:w-2/3 md:w-1/2 xl:w-1/3 bg-white flex flex-col py-4 px-6">
                <div className="flex-none flex flex-row-reverse justify-between items-center">
                    <img src="/assets/sankash-logo.svg" alt="sankash logo" className="float-right h-14 mb-2" />
                    {process.env.REACT_APP_ENV !== "prod" && <span className="uppercase text-red font-medium">ENV: {process.env.REACT_APP_ENV}</span>}
                </div>
                <hr className="-mx-6 h-0.5 bg-sk-blue border-0" />
                <Outlet />
            </div>
        </div>
    )
}

export default Layout