

const endpoints = {
    auth: {
        login: process.env.REACT_APP_BACKEND_URL + "/auth/login",
        logout: process.env.REACT_APP_BACKEND_URL + "/auth/logout",
        check: process.env.REACT_APP_BACKEND_URL + "/auth/check",
        forgotPassword: {
            sendOTP: process.env.REACT_APP_BACKEND_URL + "/auth/forgot-password/send-otp",
            verifyOTP: process.env.REACT_APP_BACKEND_URL + "/auth/forgot-password/verify-otp",
            setPassword: process.env.REACT_APP_BACKEND_URL + "/auth/forgot-password/set-password"
        }
    },
    user: {
        changePassword: process.env.REACT_APP_BACKEND_URL + "/user/change-password"
    },
    application: {
        new: process.env.REACT_APP_BACKEND_URL + "/agent/application/new",
        moreDetails: (appId) => process.env.REACT_APP_BACKEND_URL + "/agent/application/" + appId + "/more-details",
        getOffers: (appId) => process.env.REACT_APP_BACKEND_URL + "/agent/application/" + appId + "/get-offers",
        sendLink: (appId) => process.env.REACT_APP_BACKEND_URL + "/agent/application/" + appId + "/send-link",
        getStatus: (appId) => process.env.REACT_APP_BACKEND_URL + "/agent/application/" + appId + "/status",
        update: (appId) => process.env.REACT_APP_BACKEND_URL + "/agent/application/" + appId + "/update",
        qrcode: process.env.REACT_APP_BACKEND_URL + "/agent/application/qr-code"
    },
    insurance: {
        search: process.env.REACT_APP_BACKEND_URL + "/agent/insurance/search",
        covers: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/covers/" + id,
        new: process.env.REACT_APP_BACKEND_URL + "/agent/insurance/new",
        mailQuotes: process.env.REACT_APP_BACKEND_URL + "/agent/insurance/mail-quotes",
        payWallet: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/pay/wallet",
        payPG: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/pay/pg/new",
        confirmPG: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/pay/pg/confirm",
        status: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/status",
        policy: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/policy",
        download: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/policy/download",
        cancel: id => process.env.REACT_APP_BACKEND_URL + "/agent/insurance/" + id + "/cancel",
        downloadInvoice: id => process.env.REACT_APP_BACKEND_URL + "/agent/invoice/" + id + "/download",
        groupInsurance: process.env.REACT_APP_BACKEND_URL + "/agent/insurance/group-insurance",
        scanPassport: process.env.REACT_APP_BACKEND_URL + "/util/scan/passport"
    },
    static: {
        searchAirports: process.env.REACT_APP_BACKEND_URL + "/agent/static/search-airports",
        calcEMI: process.env.REACT_APP_BACKEND_URL + "/agent/static/calculate-emi",
    },
    paymentCollection: {
        accountStatus: process.env.REACT_APP_BACKEND_URL + "/agent/payment-collection/account-status",
        new: process.env.REACT_APP_BACKEND_URL + "/agent/payment-collection/new",
        status: (paymentId) => process.env.REACT_APP_BACKEND_URL + "/agent/payment-collection/" + paymentId + "/status",
    },
    transaction: {
        application: {
            count: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/application/count",
            list: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/application/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/application/detail"
        },
        insurance: {
            count: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/insurance/count",
            list: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/insurance/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/insurance/detail"
        },
        wallet: {
            count: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/wallet/count",
            list: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/wallet/list",
        },
        paymentCollection: {
            count: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/payment-collection/count",
            list: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/payment-collection/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/agent/transactions/payment-collection/detail"
        }
    },
    wallet: {
        balance: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/balance",
        newRequest: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/new-request",
        pendingRequests: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/pending-requests",
        newPayment: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/new-payment",
        confirmPayment: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/confirm-payment",
        checkPromoCode: process.env.REACT_APP_BACKEND_URL + "/agent/wallet/promocode",
    },
    support: {
        rmDetails: process.env.REACT_APP_BACKEND_URL + "/agent/support/rm-details",
        createTicket: process.env.REACT_APP_BACKEND_URL + "/agent/support/create-ticket",
    },
    onboarding: {
        cashfree: process.env.REACT_APP_BACKEND_URL + "/agent/onboarding/cashfree",
        cashfreeURL: process.env.REACT_APP_BACKEND_URL + "/agent/onboarding/cashfree/link"
    },
    flights: {
        search: process.env.REACT_APP_BACKEND_URL + "/agent/flights/search"
    },
    customer: {
        check: process.env.REACT_APP_BACKEND_URL + "/customer/auth/check",
        sendOTP: (appId) => process.env.REACT_APP_BACKEND_URL + "/customer/auth/" + appId + "/send-otp",
        resendOTP: (appId) => process.env.REACT_APP_BACKEND_URL + "/customer/auth/" + appId + "/resend-otp",
        verifyOTP: (appId) => process.env.REACT_APP_BACKEND_URL + "/customer/auth/" + appId + "/verify-otp",
        getOffers: process.env.REACT_APP_BACKEND_URL + "/customer/application/get-offers",
        shareLead: process.env.REACT_APP_BACKEND_URL + "/customer/application/share-lead",
        refreshStatus: process.env.REACT_APP_BACKEND_URL + "/customer/application/refresh-status",
        newPayment: process.env.REACT_APP_BACKEND_URL + "/customer/application/new-payment",
        confirmPayment: process.env.REACT_APP_BACKEND_URL + "/customer/application/confirm-payment",
        complete: process.env.REACT_APP_BACKEND_URL + "/customer/application/complete",
        new: process.env.REACT_APP_BACKEND_URL + "/customer/application/new",
        moreDetails: process.env.REACT_APP_BACKEND_URL + "/customer/application/more-details",
    },
    backoffice: {
        summary: type => process.env.REACT_APP_BACKEND_URL + "/backoffice/metrics/summary/" + type,
        partner: {
            check: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/add/check",
            add: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/add",
            summary: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/summary",
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/list",
            detail: (id) => process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/" + id,
            search: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/search",
            bulkUpdate: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/bulk-update",
            listRMs: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/list-rms",
            qrCode: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner/qr-code",
        },
        supervisor: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/supervisor/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/supervisor/list"
        },
        agent: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/agent/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/agent/list",
            detail: (id) => process.env.REACT_APP_BACKEND_URL + "/backoffice/agent/" + id,
            add: process.env.REACT_APP_BACKEND_URL + "/backoffice/agent/add"
        },
        dashboards: {
            get: type => process.env.REACT_APP_BACKEND_URL + "/backoffice/dashboards/" + type
        },
        walletRequests: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/requests/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/requests/list",
            approve: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/requests/" + id + "/approve",
            reject: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/requests/" + id + "/reject"
        },
        walletTransactions: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/transactions/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/transactions/list",
            add: process.env.REACT_APP_BACKEND_URL + "/backoffice/wallet/transactions/add",
        },
        applicationTransactions: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/application/transactions/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/application/transactions/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/backoffice/application/transactions/detail",
            update: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/application/" + id + "/update",
            get: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/application/" + id + "/get",
            markDisbursed: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/application/" + id + "/mark-disbursed",
            disbursements: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/application/" + id + "/disbursements",
            disbursementTracker: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/application/" + id + "/disbursement-tracker",
        },
        insuranceTransactions: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/insurance/transactions/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/insurance/transactions/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/backoffice/insurance/transactions/detail",
            update: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/insurance/" + id + "/update",
        },
        groupInsuranceTransactions: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/group-insurance/transactions/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/group-insurance/transactions/list",
        },
        paymentCollectionTransactions: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/payment-collection/transactions/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/payment-collection/transactions/list",
            detail: process.env.REACT_APP_BACKEND_URL + "/backoffice/payment-collection/transactions/detail",
        },
        manage: {
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/manage/users/count",
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/manage/users/list",
            update: id => process.env.REACT_APP_BACKEND_URL + "/backoffice/manage/users/" + id,
            add: process.env.REACT_APP_BACKEND_URL + "/backoffice/manage/users/add",
        },
        partnerLeads: {
            list: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner-leads/list",
            count: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner-leads/count",
            details: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner-leads/details",
            markused: process.env.REACT_APP_BACKEND_URL + "/backoffice/partner-leads/mark-used"
        },
        partnerInsuranceConfig: {
            get: psk_id => process.env.REACT_APP_BACKEND_URL + `/backoffice/insurance/manage/${psk_id}/plans`,
            save: psk_id => process.env.REACT_APP_BACKEND_URL + `/backoffice/insurance/manage/${psk_id}/plans`
        }
    },

    agentOnboarding: {
        new: process.env.REACT_APP_BACKEND_URL + "/onboarding/new-signup",
        validate: process.env.REACT_APP_BACKEND_URL + "/onboarding/validate",
        sendOTP: process.env.REACT_APP_BACKEND_URL + "/onboarding/send-otp",
        verifyOTP: process.env.REACT_APP_BACKEND_URL + "/onboarding/verify-otp",
    },

    util: {
        uploadPartnerDocuments: process.env.REACT_APP_BACKEND_URL + "/util/upload/partner-documents",
        uploadBulkUpdate: process.env.REACT_APP_BACKEND_URL + "/util/upload/bulk-update",
        download: process.env.REACT_APP_BACKEND_URL + "/util/download",
        pincode: pincode => process.env.REACT_APP_BACKEND_URL + "/util/pincode/" + pincode,
        converter: {
            excelToJSON: process.env.REACT_APP_BACKEND_URL + "/util/convert/excel-to-json"
        }
    }
}

export default endpoints