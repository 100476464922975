import React, { useState } from "react"
import { IoIosAddCircle } from "react-icons/io"
import { CommonFormDateInput, CommonFormTextInput } from "../../.."

const AddDisbursementTracker = ({ index, disbursementId, updateNewDisbursementData }) => {
    const [shownInputs, setShownInputs] = useState(0)

    const updateData = (i, k, v) => {
        updateNewDisbursementData(disbursementId, i, k, v)
    }

    return (
        <>
            {new Array(shownInputs).fill(0).map((x, i) => <div key={i} className="flex gap-2 items-center justify-center mt-4 text-left">
                {/* <span className="flex-none text-sm">{i + 1}.</span> */}
                <CommonFormDateInput className="flex-1" id={"sk-backoffice-disbursement-tracker-date-" + disbursementId + "-" + index + "-" + i} label="Date" onChange={v => updateData(i, "date", v)} />
                <CommonFormTextInput className="flex-1" type="number" id={"sk-backoffice-disbursement-tracker-amount-" + disbursementId + "-" + index + "-" + i} label="Amount" min="100" max="10000000" onChange={v => updateData(i, "amount", v)} />
                <CommonFormTextInput className="flex-1" id={"sk-backoffice-disbursement-tracker-utr-" + disbursementId + "-" + index + "-" + i} label="UTR No" minLength="1" maxLength="99" onChange={v => updateData(i, "utrNo", v)} />
            </div>)}

            {shownInputs < 3 && <div className="flex gap-2 items-center justify-center mt-2 cursor-pointer" onClick={() => setShownInputs(x => x + 1)}>
                <IoIosAddCircle className="text-sk-blue w-5 h-5" />
                <span className="text-sm text-sk-blue underline underline-offset-2">Add New UTR</span>
            </div>}
        </>
    )
}

export default AddDisbursementTracker