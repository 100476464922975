import React, { useEffect, useState } from 'react'
import { CommonPopup, CommonLoader, CommonError, CommonFormCurrencyInput, CommonFormTextInput, CommonFormSelect } from '../../..'
import { IoIosAddCircle } from "react-icons/io"
import API from '../../../../apis'
import Constants from '../../../../config/constants'
import { IoCloseCircle } from "react-icons/io5"

const MarkDisbursed = (props) => {
    const [defaultLender, setDefaultLender] = useState(null)
    const [offlineLenders, setOfflineLenders] = useState({})
    const numOfflineLenders = Object.keys(offlineLenders).length
    const [error, setError] = useState(null)
    const [isRunning, setIsRunning] = useState(false)

    useEffect(() => {
        const fetchData = async function () {
            const response = await API.backoffice.applicationTransactions.get(props.application)
            if (response.status === 200 && response.data?.data) {
                const apiData = response.data.data
                const { offers, selected_offer, lender } = apiData
                if (Array.isArray(offers) && offers.length > 0 && lender) {
                    const amount = offers[0].approvedAmount
                    let tenure = null
                    if (selected_offer) {
                        const selectedOffer = offers.filter(x => x.id === selected_offer)
                        if (selectedOffer.length === 1) {
                            tenure = selectedOffer[0].tenure
                        }
                    }
                    setDefaultLender({ amount, tenure, lender, offers })
                } else {
                    setDefaultLender({ amount: 0, tenure: 0, lender: "NA", offers: [] })
                }
            } else {
                setError(response.error || "API Error!")
            }
        }
        fetchData()
    }, [props.application])

    function addOfflineLender() {
        setOfflineLenders(prev => {
            const length = Object.keys(prev).length
            return { ...prev, [length]: { amount: null, tenure: null, lender: null, deleted: false } }
        })
    }

    function removeOfflineLender(i) {
        setOfflineLenders(prev => {
            const newObj = { ...prev }
            newObj[i].deleted = true
            return newObj
        })
    }

    function updateDefaultLender(k, v) {
        setDefaultLender(prev => ({ ...prev, [k]: v }))
    }

    function updateOfflineLender(i, k, v) {
        setOfflineLenders(prev => {
            prev[i][k] = v
            return { ...prev }
        })
    }

    async function markDisbursed() {
        setError(null)
        if (isRunning) return
        setIsRunning(true)
        const defaultLenderCopy = { ...defaultLender }
        delete defaultLenderCopy.offers
        const offlineLendersCopy = { ...offlineLenders }
        for (let k in offlineLendersCopy) {
            if (offlineLendersCopy[k].deleted === true) delete offlineLendersCopy[k]
        }
        const lenders = { default: defaultLenderCopy, ...offlineLendersCopy }
        const response = await API.backoffice.applicationTransactions.markDisbursed(props.application, { lenders })
        if (response.status === 200) props.onOk()
        else setError(response.error || "API Error!")
        setIsRunning(false)
    }

    return (
        <CommonPopup onOk={markDisbursed} disableOk={isRunning} onCancel={props.onCancel} dimensions="w-full xl:w-2/3" sticky={true} showCancel={true}>
            <div className="flex flex-col gap-y-8 text-left">
                <div className="font-medium text-center">MARK DISBURSED <span>{props.application}</span></div>
                {defaultLender === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {defaultLender && defaultLender.offers.length > 0 &&
                    <div className="flex flex-col gap-y-2">
                        <p className="text-sm">Enter Disbursement Information for Approved Lender:</p>
                        <div className="gap-2 grid grid-cols-4 *:col-span-4 sm:*:col-span-2 lg:*:col-span-1">
                            <CommonFormTextInput id="sk-bo-markdis-approvedlender-lender" label="Lender" value={Constants.LENDERS[defaultLender.lender]} disabled={true} />
                            <CommonFormCurrencyInput id="sk-bo-markdis-approvedlender-amount" label="Disbursed Amount" value={defaultLender.amount} onChange={v => updateDefaultLender("amount", v)} />
                            <CommonFormSelect id="sk-bo-markdis-approvedlender-tenure" label="Tenure (months)" defaultValue={[defaultLender.tenure || null, defaultLender.tenure || null]} onChange={v => updateDefaultLender("tenure", v)}>
                                {defaultLender.offers.map(o => <option value={o.tenure}>{o.tenure}</option>)}
                            </CommonFormSelect>
                            <CommonFormSelect id="sk-bo-markdis-approvedlender-product" label="Product Type" onChange={v => updateDefaultLender("productType", v)}>
                                {Object.values(Constants.PRODUCT_TYPE).map((o, i) => <option key={i} value={o}>{o}</option>)}
                            </CommonFormSelect>
                        </div>
                    </div>
                }
                {
                    defaultLender && defaultLender.offers.length === 0 &&
                    <p className="text-center">No default lender found. Please add offline disbursement.</p>
                }
                {numOfflineLenders > 0 && Object.keys(offlineLenders).map(i =>
                    offlineLenders[i].deleted === false &&
                    <div key={i} className="flex flex-col gap-y-2">
                        <p className="text-sm">Enter Disbursement Information for Offline Lender:<span className="float-right"><IoCloseCircle className="text-gray-500" onClick={() => removeOfflineLender(i)} /></span></p>
                        <div className="gap-2 grid grid-cols-4 *:col-span-4 sm:*:col-span-2 lg:*:col-span-1">
                            <CommonFormSelect id={"sk-bo-markdis-offlinelender-lender-" + i} label="Lender" onChange={v => updateOfflineLender(i, "lender", v)}>
                                {Object.entries(Constants.LENDERS).map((lndr, j) => <option key={j} value={lndr[0]}>{lndr[1]}</option>)}
                            </CommonFormSelect>
                            <CommonFormCurrencyInput id={"sk-bo-markdis-offlinelender-amount" + i} label="Disbursed Amount" onChange={v => updateOfflineLender(i, "amount", v)} />
                            <CommonFormTextInput id={"sk-bo-markdis-offlinelender-tenure" + i} type="number" label="Tenure (months)" regex="[^\d]" onChange={v => updateOfflineLender(i, "tenure", v)} min="3" max="60" />
                            <CommonFormSelect id="sk-bo-markdis-offlinelender-product" label="Product Type" onChange={v => updateOfflineLender(i, "productType", v)}>
                                {Object.values(Constants.PRODUCT_TYPE).map((o, i) => <option key={i} value={o}>{o}</option>)}
                            </CommonFormSelect>
                        </div>
                    </div>
                )}
                {error && <p className="text-center"><CommonError error={error} /></p>}
                {defaultLender && <div className="flex items-center gap-x-2">
                    <IoIosAddCircle className="text-sk-blue w-5 h-5 cursor-pointer" onClick={addOfflineLender} />
                    <span className="text-sm cursor-pointer" onClick={addOfflineLender}>Add Offline Disbursement</span>
                </div>}
            </div>
        </CommonPopup>
    )
}

export default MarkDisbursed