import callapi from "./callapi"
import endpoints from "../config/endpoints"

const airportCache = {}

const Static = {
    searchAirports: async function ({ type, search }) {
        if (search && search.length === 3 && search in airportCache) {
            return { status: 200, data: { data: [airportCache[search]] } }
        }

        const response = await callapi({
            url: endpoints.static.searchAirports,
            method: "get",
            params: {
                type,
                search
            }
        })

        setTimeout(async () => {
            if (response.status === 200 && response.data?.data && Array.isArray(response.data.data) && response.data.data.length > 0) {
                for (let x of response.data.data) airportCache[x.code] = x
            }
        }, 0)

        return response
    },
    calcEMI: async function ({ amount }) {
        return await callapi({
            url: endpoints.static.calcEMI,
            method: "post",
            data: { amount }
        })
    }
}

export default Static