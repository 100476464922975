import React, { useState } from 'react'
import { CommonFormButton, CommonFormCurrencyInput, CommonFormDateInput, CommonFormTextInput, CommonFormSelect, CommonPopup } from '../../..'

const Netbanking = (props) => {
    const [data, setData] = useState({ type: "netbanking" })
    const updateData = (k, v) => setData(prev => ({ ...prev, [k]: v }))
    const [alert, setAlert] = useState(null)

    async function onSubmit() {
        if (!data.amount) props.onError("Please enter amount.")
        else if (!data.account) props.onError("Please choose SanKash account.")
        // else if (!data.utr) props.onError("Please enter UTR no. or transaction id of the transfer.")
        else if (!data.date) props.onError("Please select date when transfer was done.")
        else await props.onSubmit(data)
    }

    return (
        <div className="grid grid-cols-2 xl:w-2/3 xs:w-full gap-y-6 gap-x-4">
            <p className="col-span-full font-semibold">Bank Transfer / Cash Deposit</p>
            <p className="text-sm col-span-2 text-sk-blue text font-semibold underline underline-offset-4 cursor-pointer" onClick={() => setAlert(true)}>View SanKash Bank Accounts</p>
            <p className="text-xs col-span-2">
                <span>As cash has already been deposited into our account, kindly provide the transaction details and proceed with submitting the request to update the wallet balance.</span>
            </p>
            <CommonFormCurrencyInput label="Amount" id="sk-wallet-netbanking-amount" onChange={(v) => updateData("amount", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormSelect label="Select SanKash Bank" id="sk-wallet-cash-account" onChange={(v) => updateData("account", v)} className="col-span-2 lg:col-span-1" >
                <option value="ICICI">ICICI a/c ending 9631</option>
                <option value="HDFC">HDFC a/c ending 0034</option>
            </CommonFormSelect>
            <CommonFormDateInput label="Transfer Date" id="sk-wallet-netbanking-date" onChange={(v) => updateData("date", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormTextInput label="UTR No. (For NEFT/RTGS/IMPS)" id="sk-wallet-netbanking-utr" onChange={(v) => updateData("utr", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormTextInput label="Remarks" id="sk-wallet-netbanking-remarks" onChange={(v) => updateData("remarks", v)} className="col-span-2 lg:col-span-1" />
            <CommonFormButton text="Cancel" className="px-4" isOutline={true} onClick={props.onCancel} />
            <CommonFormButton text="Submit" className="px-4" onClick={onSubmit} />
            {alert &&
                <CommonPopup onOk={() => setAlert(null)} onCancel={() => setAlert(null)} wide={true}>
                    <div className="flex items-center justify-between flex-col lg:flex-row gap-y-8 lg:gap-y-0">
                        <dl className="w-1/2 font-medium">
                            <p className="mb-2">Bank 1</p>
                            <dt className="text-sm text-gray-500">Bank Name</dt>
                            <dd className="border-b border-gray-300 mb-2">ICICI Bank Ltd.</dd>
                            <dt className="text-sm text-gray-500">Bank Branch</dt>
                            <dd className="border-b border-gray-300 mb-2">Gurgaon - DLF</dd>
                            <dt className="text-sm text-gray-500">Account Type</dt>
                            <dd className="border-b border-gray-300 mb-2">Current</dd>
                            <dt className="text-sm text-gray-500">Account Name</dt>
                            <dd className="border-b border-gray-300 mb-2">SanKash Private Limited</dd>
                            <dt className="text-sm text-gray-500">Account Number</dt>
                            <dd className="border-b border-gray-300 mb-2">017705009631</dd>
                            <dt className="text-sm text-gray-500">IFSC Code</dt>
                            <dd className="border-b border-gray-300 mb-2">ICIC0000177</dd>
                        </dl>
                        <dl className="w-1/2 font-medium">
                            <p className="mb-2">Bank 2</p>
                            <dt className="text-sm text-gray-500">Bank Name</dt>
                            <dd className="border-b border-gray-300 mb-2">HDFC Bank Ltd.</dd>
                            <dt className="text-sm text-gray-500">Bank Branch</dt>
                            <dd className="border-b border-gray-300 mb-2">Millennium Tower Gurgaon</dd>
                            <dt className="text-sm text-gray-500">Account Type</dt>
                            <dd className="border-b border-gray-300 mb-2">Current</dd>
                            <dt className="text-sm text-gray-500">Account Name</dt>
                            <dd className="border-b border-gray-300 mb-2">SanKash Private Limited</dd>
                            <dt className="text-sm text-gray-500">Account Number</dt>
                            <dd className="border-b border-gray-300 mb-2">50200045340034</dd>
                            <dt className="text-sm text-gray-500">IFSC Code</dt>
                            <dd className="border-b border-gray-300 mb-2">HDFC0009141</dd>
                        </dl>
                    </div>
                </CommonPopup>
            }
        </div>
    )
}

export default Netbanking