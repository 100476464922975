import callapi from "./callapi"
import endpoints from "../config/endpoints"

const Auth = {
    login: async function (data, isEmail) {
        const { mobile, email, password, base } = data

        // validations
        if (!isEmail) {
            if (!mobile) return { error: "Please enter your mobile number." }
            if (mobile.length !== 10) return { error: "Mobile number should be 10 digits." }
            if (isNaN(mobile)) return { error: "Mobile number is invalid." }
        } else {
            if (!email) return { error: "Please enter your email address." }
        }
        if (!password) return { error: "Please enter your password." }
        if (!base) return { error: "Invalid parameters." }

        return await callapi({
            url: endpoints.auth.login,
            method: "post",
            data: {
                mobile, email, password: btoa(password), base
            }
        })
    },
    logout: async function (cb) {
        await callapi({
            url: endpoints.auth.logout,
            method: "post"
        })

        if (cb && typeof cb === "function") cb()
    },
    check: async function () {
        const response = await callapi({
            url: endpoints.auth.check,
            method: "post"
        })

        if (response.status === 200 && response.data?.data) return response.data.data
        else return false
    },
    checkAsync: async function (cb) {
        const loggedIn = await this.check()
        if (cb && typeof cb === "function") cb(loggedIn)
    },
    sendOTP: async function (data, isEmail) {
        const { mobile, email } = data

        // validations
        if (!isEmail) {
            if (!mobile) return { error: "Please enter your mobile number." }
            if (mobile.length !== 10) return { error: "Mobile number should be 10 digits." }
            if (isNaN(mobile)) return { error: "Mobile number is invalid." }
        } else {
            if (!email) return { error: "Please enter your email address." }
        }

        return await callapi({
            url: endpoints.auth.forgotPassword.sendOTP,
            method: "post",
            data
        })
    },
    verifyOTP: async function (data) {
        const { otp } = data
        if (!otp) return { error: "Please enter OTP." }

        return await callapi({
            url: endpoints.auth.forgotPassword.verifyOTP,
            method: "post",
            data
        })
    },
    setPassword: async function (data) {
        const { password, confirmPassword } = data
        if (!password) return { error: "Please enter new password." }
        if (!confirmPassword) return { error: "Please enter confirm password." }
        if (password !== confirmPassword) return { error: "Passwords do not match." }

        data.password = btoa(data.password)
        data.confirmPassword = btoa(data.confirmPassword)

        return await callapi({
            url: endpoints.auth.forgotPassword.setPassword,
            method: "post",
            data
        })
    },
    changePassword: async function (data) {
        const { current, new: _new, re } = data
        if (!current) return { error: "Please enter current password." }
        if (!_new) return { error: "Please enter new password." }
        if (!re) return { error: "Please re-enter new password." }
        if (_new !== re) return { error: "Passwords do not match." }

        return await callapi({
            url: endpoints.user.changePassword,
            method: "post",
            data: {
                current_password: btoa(current),
                new_password: btoa(_new)
            }
        })
    }
}

export default Auth