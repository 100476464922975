import React, { useState, useEffect } from 'react'
import { CommonFormTextInput, CommonFormButton, CommonFormSelect, CommonPopup, CommonFormFileInput, CommonFormRadio } from '../../../index.js'
import { default as PGChargesCashfree } from './pg-charges-cashfree.jsx'
import { FaStar } from "react-icons/fa";
import API from '../../../../apis/index.js';
import util from '../../../../apis/util.js';
import paymentCollection from '../../../../apis/payment-collection.js';
import CashfreePending from './cashfree-submitted.jsx';

const CashfreeNotActive = ({ props }) => {
    const [originalData, setOriginalData] = useState({})
    const [data, setData] = useState({})
    // eslint-disable-next-line
    const [url, setUrl] = useState(null)
    const [file, setFile] = useState(null)
    const [showPGCharges, setShowPGCharges] = useState(false)
    const [pageState, setPageState] = useState(0)
    const [gstSelectedOption, setGSTSelectedOption] = useState(null)
    let [error, setError] = useState(null)
    // eslint-disable-next-line
    const [interval, setIntervalNum] = useState(null)

    useEffect(() => {
        const setValues = async function () {
            const response = await API.onboarding.cashfree();
            if (response.status === 200 && response?.data.data) {
                const apiData = response?.data.data
                setData(apiData)
                setOriginalData(Object.assign({}, apiData))
                if (apiData?.business_gst) setGSTSelectedOption('Yes')
                else if (apiData?.business_no_gst_dec) setGSTSelectedOption('No')
            } else { setError(response.error || "API Error!") }
        }
        setValues()
    }, [props])


    const handleInputChange = async (k, v) => {
        setData((prevData) => {
            const newData = { ...prevData, [k]: v }
            if (k === "business_gst") newData.business_no_gst_dec = null
            else if (k === "business_no_gst_dec") newData.business_gst = null
            return newData
        })
    }

    useEffect(() => {
        if (pageState === 1) {
            setData((prevData) => {
                return {
                    ...prevData,
                    business_city: null,
                    business_state: null
                }
            })
            document.getElementById("sk-business-city").value = ""
            document.getElementById("sk-business-state").value = ""
            if (data.business_pincode && data.business_pincode?.length === 6) {
                const setStateCity = async (pincode) => {
                    const response = await API.util.pincode(pincode)
                    if (response.status === 200 && response.data?.data) {
                        setData((prevData) => {
                            return {
                                ...prevData,
                                business_city: response?.data?.data?.district,
                                business_state: response?.data?.data?.state
                            }
                        })
                        document.getElementById("sk-business-city").value = response?.data?.data?.district
                        document.getElementById("sk-business-state").value = response?.data?.data?.state
                    }
                }
                setStateCity(data.business_pincode)
            }
        }
    }, [data.business_pincode, pageState])

    const onSignup = async function () {
        const response = await API.onboarding.cashfreeData({ ...data, page: 1 })
        if (response.status === 200) {
            setPageState(1)
        } else {
            setError(response.error || "API Error!")
        }
    }

    const onNext = async function () {
        const response = await API.onboarding.cashfreeData({ ...data, page: 2 })
        if (response.status === 200) {
            setPageState(2)
        } else {
            setError(response.error || "API Error!")
        }
    }

    // eslint-disable-next-line
    const checkAccountStatus = async function () {
        const response = await paymentCollection.accountStatus();
        if (response.status === 200 && response.data?.data?.pgStatus === "Submitted") {
            if (interval) clearInterval(interval)
            setPageState(4)
        } else {

        }
    }

    const verificationSubmit = async function () {
        let currData = Object.assign({}, data)
        if (
            (data?.business_type === "Proprietary" && data?.business_no_gst_dec) ||
            data?.business_type === "Partnership"
        ) {
            if (!file) return setError("Please select a file.")
            const response = await util.uploadPartnerDocuments({
                file: file[0],
                ext: file[0].name.split(".").pop(),
                size: file[0].size,
                type: file[0].type
            })

            if (response.status === 200 && response.data?.data) {
                handleInputChange("business_proof", response.data.data)
                currData.business_proof = response.data.data
            } else if (response.status === 413) {
                setError("Max file size can be 5MB. Please check uploaded file(s).")
                return false
            } else {
                setError(response.error)
                return false
            }
        }

        const response2 = await API.onboarding.cashfreeData({ ...currData, page: 3 })
        if (response2.status === 200) {
            // force delay
            await new Promise(res => setTimeout(res, 5000))
            const response3 = await API.onboarding.cashfreeURL()
            if (response3.status === 200 && response3?.data.data) {
                setUrl(response3?.data.data)
                window.location.href = response3?.data.data
                // setPageState(3)
                //  setIntervalNum(setInterval(checkAccountStatus, 10000))
            } else { setError(response3.error || "API Error!") }
        } else {
            setError(response2.error || "API Error!")
            return
        }
    }

    return (
        <>
            {error && <CommonPopup onOk={() => setError(null)}><div>{error}</div></CommonPopup>}
            {pageState === 0 && (
                <div className="mt-4 lg:mt-8 select-none flex flex-col gap-y-8">
                    <p className='text-2xl font-semibold'><span className='text-sk-blue font-bold'>Seamless Payments,</span> Secure Transactions with SanKash!</p>
                    <div className="flex flex-col-reverse lg:flex-row gap-4 gap-y-8 items-center">
                        <div className='flex flex-col gap-y-4 w-full sm:w-auto'>
                            <div className='flex flex-col gap-y-4 lg:flex-row gap-x-4'>
                                <CommonFormTextInput placeholder="Full Name" className="w-full" id="sk-business-name" name="name" label="Your Name" maxLength="64" minLength="3" value={data.name} onChange={v => handleInputChange("name", v)} />
                                <CommonFormTextInput disabled={originalData.mobile ? true : false} className="w-full" id="sk-mobile-number" name="mobile" type="tel" label="Mobile Number" maxLength="64" minLength="3" value={data.mobile} onChange={handleInputChange} />
                            </div>
                            <div>
                                <CommonFormTextInput disabled={originalData.email ? true : false} className="w-full" id="sk-email-id" name="email" maxLength="64" minLength="3" type="email" label="E-mail ID" value={data.email} onChange={v => handleInputChange("email", v)} />
                            </div>
                            <CommonFormButton text="Sign Up Now" className="w-full" onClick={onSignup} />
                        </div>
                        <div className='flex flex-col text-lg gap-2 lg:ml-16'>
                            <div className='flex lg:items-center'><FaStar className='inline-block text-sk-blue mr-2 mt-1 lg:mt-0' /> <p><span className='text-sk-blue font-bold'>Quick</span> Registration Process</p></div>
                            <div className='flex lg:items-center'><FaStar className='inline-block text-sk-blue mr-2 mt-1 lg:mt-0' /><p><span className='text-sk-blue font-bold'>T+1 Settlement</span> In Your Account</p></div>
                            {/* <div className='flex lg:items-center'><FaStar className='inline-block text-sk-blue mr-2 mt-1 lg:mt-0' /><p><span className='text-sk-blue font-bold'>0%</span> Payment Gateway Charges On UPI</p></div> */}
                            {/* <div className='flex lg:items-center'><FaStar className='inline-block text-sk-blue mr-2 mt-1 lg:mt-0' /><p><span className='text-sk-blue font-bold'>1%</span> Credit Card Charges Post ₹10 Lakhs*</p></div> */}
                        </div>
                    </div>
                    <div className="flex flex-col gap-2">
                        <p>Exclusive PG charges for you. <span className="text-sk-blue font-medium underline-offset-2 underline cursor-pointer" onClick={() => setShowPGCharges(true)}>{"Click here"}</span></p>
                        <p>Powered By <img src="/assets/cashfree-logo.svg" alt="cashfree payments logo" className="inline-block h-8 ml-2" /></p>
                        {showPGCharges && <PGChargesCashfree hide={() => setShowPGCharges(false)} />}
                    </div>
                </div>
            )}
            {pageState === 1 && (
                <div className='flex flex-col select-none sm:w-[70%] gap-y-4 mt-4'>
                    <p className='text-black font-medium'>Business Type</p>
                    <div className='grid grid-cols-2 space-y-4 gap-x-4'>
                        <CommonFormTextInput placeholder="Business Name" className="col-span-full" id="sk-business-name" name="businessName" label="Business Name (As per PAN)" maxLength="64" minLength="3" value={data.business_name} onChange={v => handleInputChange('business_name', v)} />

                        <CommonFormSelect id="sk-business-type" label="Business Type" className="col-span-2 lg:col-span-1" disabled={originalData?.business_type ? true : false} onChange={(v) => handleInputChange("business_type", v)} defaultValue={originalData?.business_type ? [originalData.business_type, originalData.business_type] : null}>
                            {React.Children.map(["Proprietary", "Private Limited", "Public Limited", "LLP", "Partnership"], (x, i) =>
                                <option key={i} value={x}>{x}</option>
                            )}
                        </CommonFormSelect>

                        <CommonFormTextInput placeholder="PAN" disabled={originalData.business_pan ? true : false} className="col-span-2 lg:col-span-1" id="sk-pan-number" name="businessPan" label="Business PAN" maxLength="10" minLength="10" value={data.business_pan} onChange={v => handleInputChange('business_pan', v)} />
                    </div>

                    <div className="mt-4">
                        <p className='pb-4 font-medium'>Business Address</p>
                        <div className='grid grid-cols-2 *:col-span-2 *:lg:col-span-1 gap-4'>
                            <CommonFormTextInput placeholder="Address" className="w-full" id="sk-business-address" name="businessAddress" label="Address" maxLength="64" minLength="4" value={data.business_address} onChange={v => handleInputChange('business_address', v)} />
                            <CommonFormTextInput placeholder="Postal Code" className="w-full" id="sk-business-pincode" name="postalCode" type="number" label="PIN Code" min="100000" max="999999" value={data.business_pincode} onChange={v => handleInputChange('business_pincode', v)} />
                            <CommonFormTextInput placeholder="City" className="w-full" disabled={true} id="sk-business-city" name="city" label="City" value={data?.business_city || ""} />
                            <CommonFormTextInput placeholder="State" className="w-full" disabled={true} id="sk-business-state" name="state" label="State" value={data?.business_state || ""} />
                        </div>
                    </div>

                    <div className="mt-4">
                        <p className='pb-4 font-medium'>Business GST</p>
                        <div className="flex flex-col lg:flex-row lg:items-center gap-y-8 lg:gap-x-16">
                            <CommonFormRadio id="sk-business-gst-options" name="business_gst_option" options={[{ key: "Yes", text: "Yes" }, { key: "No", text: "No" }]} onChange={setGSTSelectedOption}
                                defaultValue={originalData?.business_gst ? "Yes" : (originalData?.business_no_gst_dec ? "No" : null)}
                                disabled={originalData?.business_gst ? true : (originalData?.business_no_gst_dec ? true : false)}
                            />

                            {gstSelectedOption === "Yes" && <CommonFormTextInput className="flex-1" disabled={originalData?.business_gst || false} label="GST Number" id="sk-gst-number" minLength="15" maxLength="15" onChange={v => handleInputChange('business_gst', v)} value={data?.business_gst} />}
                            {gstSelectedOption === "No" && <CommonFormRadio id="sk-business-no-gst-dec" name="business_no_gst_dec" options={[
                                { key: 'exempted_from_gst', text: 'My business category is exempted from GST.' },
                                { key: 'turnover_less_than_gst_limit', text: 'My annual turnover is less than GST prescribed limit.' }
                            ]} onChange={v => handleInputChange('business_no_gst_dec', v)} defaultValue={originalData?.business_no_gst_dec || null} disabled={originalData?.business_no_gst_dec || false} />}
                        </div>
                    </div>

                    <CommonFormButton onClick={onNext} text="Next" className="mt-4" />
                </div>
            )}
            {pageState === 2 && (<div className='flex flex-col select-none sm:w-[65%] mt-4'>
                <p className='text-black font-bold'>Bank Details</p>
                <div className='flex flex-col sm:flex-row gap-4 mt-4'>
                    <CommonFormTextInput placeholder="Account Number" className="w-full" label="Accout Number" type="number" id="sk-business-bank-account" name="businessBankAccout" maxLength="64" minLength="3" value={data?.business_bank_account} onChange={v => handleInputChange('business_bank_account', v)} />
                    <CommonFormTextInput placeholder="IFSC Code" className="w-full" label="IFSC Code" id="sk-business-bank-ifsc" name="businessBankIfsc" maxLength="11" minLength="11" value={data?.business_bank_ifsc} onChange={v => handleInputChange('business_bank_ifsc', v)} />
                </div>

                {data?.business_type === "Proprietary" && data?.business_no_gst_dec &&
                    <div className='mt-8'>
                        <p className='text-black font-bold'>Documents Upload</p>
                        <div className='flex flex-col mt-4 gap-y-4'>
                            <CommonFormSelect id="sk-document-type" label="Document Type" className="w-full" onChange={(i) => handleInputChange("business_proof_type", i)}>
                                {[
                                    { i: 'businessproof_saecertificate', x: "Certificate/License issued by the appropriate authorities under Shops & Establishment Act" },
                                    { i: 'businessproof_regproof', x: "Municipal Certificate" },
                                    { i: 'businessproof_municipal', x: "Udyog Aadhaar" },
                                    { i: 'businessproof_udyog', x: "Other Government-Issued Registration Proof" }
                                ].map((item, index) => <option key={index} value={item.i} display={item.x}>{item.x}</option>)}
                            </CommonFormSelect>
                            <CommonFormFileInput id="sk-business-proof" label="Upload Certificate" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" onChange={setFile} />
                        </div>
                    </div>
                }

                {data?.business_type === "LLP" && <div className='mt-4 flex gap-y-4 flex-col'>
                    <p className='text-black font-bold mt-8'>Other Details</p>

                    <CommonFormTextInput id="sk-llpin" label="LLPIN Number" maxLength="8" minLength="8" placeholder="ABC-1234" value={data.business_llpin} disabled={originalData?.business_llpin ? true : false} onChange={(v) => handleInputChange("business_llpin", v)} />

                    <div className="flex flex-col gap-y-2">
                        <p>Are you the authorised signatory of this organisation ?</p>
                        <CommonFormRadio id="sk-authorised-signatory" name="sk-authorised-signatory" options={[{ key: "Yes", text: "Yes" }, { key: "No", text: "No" }]} onChange={(v) => handleInputChange("authorised_signatory_type", v)} />
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4'>
                        <CommonFormTextInput id="sk-authorised-signatory-name" value={data.authorised_signatory_name} label="Authorised Signatory Name (As Per PAN)" onChange={v => handleInputChange("authorised_signatory_name", v)} className="w-full" />
                        <CommonFormTextInput id="sk-authorised-signatory-pan" value={data.authorised_signatory_pan} label="Authorised Signatory PAN" onChange={v => handleInputChange("authorised_signatory_pan", v)} className="w-full" />

                    </div>
                </div>
                }

                {(data?.business_type === "Private Limited" || data?.business_type === "Public Limited") && (<div className='mt-4 flex gap-y-4 flex-col'>
                    <p className='text-black font-bold mt-8'>Other Details</p>
                    <CommonFormTextInput id="sk-cin-number" label="CIN Number" maxLength="21" minLength="21" disabled={originalData.business_cin ? true : false} value={data?.business_cin} onChange={(v) => handleInputChange("business_cin", v)} />
                    <div className='flex flex-col gap-y-2'>
                        <p>Are you the authorised signatory of this organisation ?</p>
                        <CommonFormRadio id="sk-authorised-signatory" name="sk-authorised-signatory" options={[{ key: "Yes", text: "Yes" }, { key: "No", text: "No" }]} onChange={(v) => handleInputChange("authorised_signatory_type", v)} />
                    </div>
                    <div className='flex flex-col lg:flex-row gap-4'>
                        <CommonFormTextInput id="sk-authorised-signatory-name" className="w-full" value={data.authorised_signatory_name} label="Authorised Signatory Name(As Per PAN)" onChange={v => handleInputChange("authorised_signatory_name", v)} />
                        <CommonFormTextInput id="sk-authorised-signatory-pan" className="w-full" value={data.authorised_signatory_pan} label="Authorised Signatory PAN" onChange={v => handleInputChange("authorised_signatory_pan", v)} />
                    </div>
                </div>)}

                {data?.business_type === "Partnership" &&
                    <div className='mt-8'>
                        <p className='text-black font-bold'>Documents Upload</p>
                        <div className='flex flex-col mt-4 gap-y-4'>
                            <CommonFormSelect id="sk-document-type" label="Document Type" className="w-full" onChange={(i) => handleInputChange("business_proof_type", i)}>{[{ i: 'entityproof_partnershipdeed', x: "Partnership Deed" }, { i: 'businessproof_roc', x: "Registration Certificate" }].map((item, index) => (<option key={index} value={item.i}>{item.x}</option>))}
                            </CommonFormSelect>

                            <CommonFormFileInput id="sk-business-proof" label="Upload Certificate" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" onChange={setFile} />
                            <p>Are you the authorised signatory of this organisation ?</p>
                            <CommonFormRadio name="sk-authorised-signatory" id="sk-authorised-signatory" options={[{ key: "Yes", text: "Yes" }, { key: "No", text: "No" }]} onChange={(v) => handleInputChange("authorised_signatory_type", v)} />
                            <div className='flex flex-col lg:flex-row gap-8'>
                                <CommonFormTextInput id="sk-authorised-signatory-name" className="w-full" label="Authorised Signatory Name (As Per PAN)" value={data.authorised_signatory_name} onChange={v => handleInputChange("authorised_signatory_name", v)} />
                                <CommonFormTextInput id="sk-authorised-signatory-pan" className="w-full" label="Authorised Signatory PAN" value={data.authorised_signatory_pan} onChange={v => handleInputChange("authorised_signatory_pan", v)} />
                            </div>
                        </div>
                    </div>
                }
                <CommonFormButton onClick={verificationSubmit} text="Submit" className="mt-8" />
            </div>)
            }
            {/* {pageState === 3 && <iframe title="cashfree-embed" src={url} className='w-full h-full mt-4 flex-1 min-h-[80vh]' />
            } */}

            {pageState === 4 && <CashfreePending />}
        </>
    )
}

export default CashfreeNotActive
