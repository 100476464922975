import React, { useEffect } from 'react'
import { CommonFormButton } from '../../../index'
import MixPanel from '../../../../config/mixpanel';

const NotEligible = (props) => {
    async function onSubmit() {
        await new Promise(resolve => setTimeout(resolve, 1000));
        props.next(0)
    }

    useEffect(() => {
        MixPanel.track(MixPanel.types.agent.application.rejected)
    }, [])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Eligibility Check</h6>
            <hr className="mt-3 text-gray" />
            <img src="/assets/icons/exclamation.svg" alt="alert" className="inline ml-2 w-7 h-7 mt-6" />
            <p className="text-xl font-medium flex items-center mt-2">
                Thank you for your loan application.
            </p>
            {/* We are unable to generate an eligibility limit for this customer, digitally.<br /> */}
            <p className="mt-6 text-red">Our customer service team will contact the customer directly to process the application.</p>
            <div className="grid grid-cols-3">
                <CommonFormButton id="sk-eg-back-home" text="Back to Home" className="col-span-3 lg:col-span-2 xl:col-span-1 mt-32" onClick={onSubmit} />
            </div>
        </div>
    )
}

export default NotEligible