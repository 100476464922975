import React from 'react'
import { CommonPopup } from '../../..'

const PGChargesCashfree = (props) => {
    return (
        <CommonPopup onOk={props.hide} onCancel={props.hide} wide={true}>
            <p className="mb-2 font-medium">PG Charges</p>
            <table className="table-auto w-full border-collapse">
                <thead className="bg-sk-yellow">
                    <tr className="*:px-2 *:py-3 *:text-sm *:border *:border-white *:select-none">
                        <th>Payment Method</th>
                        <th>Conditions</th>
                        <th>Charges *</th>
                    </tr>
                </thead>
                <tbody className="xs:*:text-xs *:text-sm *:text-center">
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">UPI</td>
                        <td></td>
                        <td>0.10%</td>
                    </tr>


                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="2">Debit Cards</td>
                        <td>Less than or equal to &#8377; 2,000</td>
                        <td>0.50%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>More than &#8377; 2,000</td>
                        <td>1.30%</td>
                    </tr>


                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="5">Netbanking</td>
                        <td>HDFC</td>
                        <td>1.70%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>SBI, Axis, Yes</td>
                        <td>1.10%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>Kotak</td>
                        <td>1.45%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>ICICI</td>
                        <td>1.70%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>Others</td>
                        <td>1.10%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">Credit Cards</td>
                        <td></td>
                        <td>1.85%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">AMEX</td>
                        <td></td>
                        <td>2.60%</td>
                    </tr>
                </tbody>
            </table>
            <p className='mt-4 font-medium text-sm flex'>* GST Additional</p>
        </CommonPopup>
    )
}

export default PGChargesCashfree