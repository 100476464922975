import React from 'react'

const CashfreePending = () => {
    return (
        <div className='flex flex-col text-center justify-center items-center'>
            <img src='/assets/submitted.jpg' alt='pending document verification' className='max-h-[300px] w-auto my-4' />
            <p className='xs:text-lg text-xl font-medium'>Thank you for submitting the details.</p>
            <p className='mt-2'>Your documents are under review. We will get back to you shortly.</p>
            <p className='text-sm mt-8'>Want To Know More? Reach out to us at <a href="mailto:support@sankash.in" className='underline text-sk-blue underline-offset-2'>support@sankash.in</a>.</p>
        </div>
    )
}

export default CashfreePending