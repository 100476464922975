import React, { useState } from 'react'
import { CommonFormTextInput, CommonFormSelect, CommonFormDateInput, CommonFormAirportInput } from '../../../index'

const ItineraryDetails = (props) => {
    const [showReturn, setShowReturn] = useState(false)

    function onChange(k, v) {
        if (k === "round_trip") {
            if (v === "Yes") {
                setShowReturn(true)
                props.onChange(k, v)
            } else {
                setShowReturn(false)
                props.onChange([k, "return_date"], [v, ""])
            }
        } else {
            props.onChange(k, v)
        }
    }

    return (
        <form className="grid grid-cols-2 *:col-span-2 lg:*:col-span-1 gap-4" autoComplete="off">
            <CommonFormTextInput type="number" id="sk-eg-it-num-travelers" label="Number of Travellers*" min="1" max="10" onChange={(v) => onChange("num_travellers", v)} />
            <CommonFormSelect id="sk-eg-it-destination-type" label="Destination Type*" onChange={(v) => onChange("destination_type", v)} >
                <option value="Domestic">Domestic</option>
                <option value="International">International</option>
            </CommonFormSelect>
            <CommonFormAirportInput id="sk-eg-it-source" label="Source*" getDestinationType={() => "Domestic"} onChange={(v) => onChange("source", v)} />
            <CommonFormAirportInput id="sk-eg-it-destination" label="Destination*" getDestinationType={props.getDestinationType} onChange={(v) => onChange("destination", v)} />
            <CommonFormSelect id="sk-eg-it-includes-flights" label="Package includes Flights*" onChange={(v) => onChange("includes_flights", v)}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </CommonFormSelect>
            <CommonFormSelect id="sk-eg-it-round-trip" label="Round Trip*" onChange={(v) => onChange("round_trip", v)}>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </CommonFormSelect>
            <CommonFormDateInput id="sk-eg-it-start-date" label="Start Date*" onChange={(v) => onChange("start_date", v)} />
            {showReturn && <CommonFormDateInput id="sk-eg-it-return-date" label="Return Date*" onChange={(v) => onChange("return_date", v)} />}
        </form>
    )
}

export default ItineraryDetails