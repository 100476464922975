import React, { useState, useEffect, useCallback } from 'react'
import { CommonError, CommonFormButton, CommonLinkButton, CommonLoader, CommonTableKeyValue, CommonRemarks } from '../../../'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const Details = (props) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [remarks, setRemarks] = useState({ show: false, action: null, callback: null, cancel: null })


    const fetchDetails = useCallback(async function () {
        const response = await API.backoffice.partner.details(props.id)
        if (response.status === 200 && response.data.data) {
            setData(response.data.data)
            MixPanel.track(MixPanel.types.backoffice.partner.detail, { id: props.id })
        } else {
            setError(response.error || "API Error!")
        }
    }, [props.id])

    useEffect(() => {
        setData(null)
        fetchDetails()
    }, [fetchDetails, props.id])


    const update = async function (key, value, remarks) {
        setError(null)
        if (key === "active") {
            if (!remarks) {
                setRemarks({
                    show: true,
                    cancel: () => setRemarks({ show: false, action: null, callback: null }),
                    action: value ? "Enabling Partner" : "Disabling Partner",
                    callback: update.bind(this, key, value)
                })
                return null
            } else {
                setRemarks({ show: false, action: null, callback: null })
            }
        }
        const response = await API.backoffice.partner.update(props.id, { [key]: value, remarks })
        if (response.status === 200) {
            setData(null)
            setTimeout(function () {
                fetchDetails()
            }, 200)
            MixPanel.track(MixPanel.types.backoffice.partner.update, { type: key })
            return true
        } else {
            return false
        }
    }
    const getDropdownList = async function (list, options) {
        if (list === "profile.rm.id") {
            const response = await API.backoffice.partner.listRMs()
            if (response.status === 200 && response.data?.data && Array.isArray(response.data.data)) {
                return response.data.data
            } else {
                return []
            }
        } else if (list === "fibe_product") {
            return options
        }
    }

    return (
        <div className="z-20 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto shadow-2xl bg-gray-900 bg-opacity-50 flex flex-row-reverse" onClick={() => !remarks.show && typeof props.onClose === "function" && props.onClose()}>
            <div className={"w-1/3 bg-white flex flex-col slide-in-right " + ((error || !data) && "justify-center items-center")} onClick={(e) => e.stopPropagation()}>
                {data === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {!data && error && <p className="text-center"><CommonError error={error} /></p>}
                {data &&
                    <div className="flex flex-col p-4 overflow-y-scroll">
                        <img src="/assets/icons/cross.svg" alt="close" className="h-7 pr-4 pb-4 inline-block self-start opacity-50 cursor-pointer" onClick={() => typeof props.onClose === "function" && props.onClose()} />
                        <div className="mt-4 flex flex-wrap *:flex-none *:mr-4 *:mb-4">
                            <CommonLinkButton text="View Applications" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/tnpl/transactions?partner=" + props.id} />
                            <CommonLinkButton text="View Insurance" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/ti/transactions?partner=" + props.id} />
                            {/* <CommonLinkButton text="View Supervisors" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/supervisors/manage?partner=" + props.id} /> */}
                            <CommonLinkButton text="View Agents" isOutline={true} short={true} className="text-sm px-2" to={"/backoffice/users/agents/manage?partner=" + props.id} />
                            {data.active === 1 ? <CommonFormButton text="Mark Inactive" isOutline={true} short={true} className="text-sm px-2" onClick={update.bind(this, "active", false)} showResult={true} /> : <CommonFormButton text="Mark Active" isOutline={true} short={true} className="text-sm px-2" onClick={update.bind(this, "active", true)} showResult={true} />}
                        </div>
                        {Object.keys(data).map((field, i) =>
                            Array.isArray(data[field]) && <CommonTableKeyValue key={i} className="mt-8" heading={field}>
                                {data[field].map((row, j) =>
                                    row.list
                                        ? <span key={row.key} rowclassname={row.class || ""} value={row.value} list={row.list ? async (v) => { return await update(row.list, v) } : null} listAPI={() => getDropdownList(row.list, row.options)}>{row.value}</span>
                                        : <span key={row.key} rowclassname={row.class || ""} value={row.value} download={row.download || null} update={row.update ? async (v) => { return await update(row.update, v) } : null} switch={row.switch ? true : undefined} copy={row.copy ? row.value : undefined} file={row.file ? async (v) => { return await update(row.file, v) } : null}>{row.value}</span>
                                )}
                            </CommonTableKeyValue>
                        )}
                    </div>
                }
            </div>
            {remarks.show && <CommonRemarks cancel={remarks.cancel} action={remarks.action} callback={remarks.callback} />}
        </div>
    )
}

export default Details