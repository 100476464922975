import React, { useState } from 'react'
import { BsThreeDotsVertical } from 'react-icons/bs'
import { CommonDropdown, CommonLoader } from '..'
import PropTypes from 'prop-types'

const Table = (props) => {
    const [actionInProgress, setActionInProgress] = useState(null)

    async function actionsHandler(id, action) {
        if (typeof props.actionsHandler === "function") {
            setActionInProgress(id)
            await props.actionsHandler(id, action)
            setActionInProgress(null)
        }
    }

    return (
        <div className={" " + (props.nowrap ? "" : "max-w-[calc(100vw_-_4rem)] overflow-x-auto")}>
            <table className={"lg:table-auto lg:w-full border-collapse " + (props.className || "")}>
                <thead className="bg-sk-yellow">
                    <tr className="*:px-2 *:py-3 *:text-sm *:border *:border-white *:select-none">
                        {props.data.display.map((x, i) => <th key={i}>{x}</th>)}
                        {props.data.actions && Array.isArray(props.data.actions) && props.data.actions.length > 0 && <th>&nbsp;</th>}
                    </tr>
                </thead>
                <tbody className="*:text-sm *:text-center">
                    {props.data.data.map((y, j) =>
                        <tr key={j} className={"hover:bg-gray-100 *:border *:border-gray-100 *:py-2 *:px-2 " + (props.highlighted && props.highlighted === y[props.data.details] ? "bg-sk-blue bg-opacity-30" : j % 2 === 1 ? "bg-gray-50" : "") + " " + (typeof props.showDetails === "function" && "cursor-pointer")} onClick={() => typeof props.showDetails === "function" && props.showDetails(y[props.data.details])}>
                            {props.data.fields.map((x, i) =>
                                <td key={i} className={!isNaN(props.linked) && i === props.linked && props.showDetails && typeof props.showDetails === "function" ? "text-sk-blue underline underline-offset-2" : ""}>{y[x]}</td>
                            )}
                            {props.data.actions && Array.isArray(props.data.actions) && props.data.actions.length > 0 && <td>
                                {y["actions"].some(x => x) ?
                                    <CommonDropdown>
                                        {actionInProgress && actionInProgress === y[props.data.fields[0]] ? <CommonLoader className="h-4 text-gray-500 inline-block" /> : <BsThreeDotsVertical />}
                                        {props.data.actions.map((z, k) =>
                                            y["actions"][k] && <p key={k} onClick={() => actionsHandler(y[props.data.fields[0]], z)}><span className="block px-4 py-3 hover:bg-gray-100 select-none">{z}</span></p>
                                        )}
                                    </CommonDropdown>
                                    : ""
                                }
                            </td>}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}

Table.propTypes = {
    actionsHandler: PropTypes.func,
    className: PropTypes.string,
    data: PropTypes.object,
    showDetails: PropTypes.func,
    highlighted: PropTypes.string,
    nowrap: PropTypes.bool,
    linked: PropTypes.number
}

export default Table