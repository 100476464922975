import React, { useState, useEffect } from 'react'
import Applications from './pages/applications'
import Wallet from './pages/wallet'
import Insurance from './pages/insurance'
import PaymentCollection from './pages/payment-collections'
import { CommonFormSelect } from '../..'
import { useSearchParams } from "react-router-dom";

const Status = () => {
    const [view, setView] = useState(0)
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get("tab")

    useEffect(() => {
        setSearchParams({ tab: getViewName(view) });
    }, [view, setSearchParams]);

    const getViewName = (view) => {
        switch (view) {
            case 0:
                return 'applications';
            case 1:
                return 'travel-insurance';
            case 2:
                return 'wallet-transactions';
            case 3:
                return 'payment-collections';
            default:
                return '';
        }
    };

    useEffect(() => {
        if (tab) {
            if (tab === "travel-insurance") setView(curr => curr !== 1 ? 1 : curr)
            else if (tab === "wallet-transactions") setView(curr => curr !== 2 ? 2 : curr)
            else if (tab === "payment-collections") setView(curr => curr !== 3 ? 3 : curr)
        }
    }, [tab])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing lg:hidden">My Transactions</h6>
            <CommonFormSelect id="sk-transactions-select" className="mt-4 w-full lg:hidden" onChange={v => setView(v * 1)} defaultValue={[0, "Applications"]}>
                <option value="0">Applications</option>
                <option value="1">Travel Insurance</option>
                <option value="2">Wallet Transactions</option>
                <option value="3">Payment Collections</option>
            </CommonFormSelect>

            <p className="flex-1 border-b pb-4 border-gray-300 lg:hidden"></p>

            <div className="hidden lg:flex flex-row *:font-semibold *:px-4 *:pb-3 hamburger-spacing *:text-center">
                <h6 onClick={() => setView(0)} className={"cursor-pointer " + (view === 0 ? "text-sk-blue border-sk-blue border-b-2" : "border-gray-300 border-b")}>Applications</h6>

                <h6 onClick={() => setView(1)} className={"cursor-pointer " + (view === 1 ? "text-sk-blue border-sk-blue border-b-2" : "border-gray-300 border-b")}>Travel Insurance</h6>

                <h6 onClick={() => setView(2)} className={"cursor-pointer " + (view === 2 ? "text-sk-blue border-sk-blue border-b-2" : "border-gray-300 border-b")}>Wallet Transactions</h6>

                <h6 onClick={() => setView(3)} className={"cursor-pointer " + (view === 3 ? "text-sk-blue border-sk-blue border-b-2" : "border-gray-300 border-b")}>Payment Collections</h6>

                <p className="flex-1 border-b pb-3 border-gray-300"></p>
            </div>
            {view === 0 && <Applications />}
            {view === 1 && <Insurance />}
            {view === 2 && <Wallet />}
            {view === 3 && <PaymentCollection />}
        </div>
    )
}

export default Status