import React, { useEffect } from 'react'

const Reports = (props) => {
    useEffect(() => {

    }, [props.type])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">{props.type.toUpperCase()} Reports</h6>
            <hr className="mt-3 text-gray" />
        </div>
    )
}

export default Reports