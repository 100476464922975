import React, { useEffect, useState } from 'react'
import { default as NewPayment } from './pages/new-collection'
import { CommonLoader } from '../../index'
import paymentCollection from '../../../apis/payment-collection'
import { useNavigate } from 'react-router-dom'
import { default as RazorpayNotActive } from './pages/not-active-razorpay'
import { default as CashfreeNotActive } from './pages/cashfree-not-active'
import { default as CashfreePending } from './pages/cashfree-pending'
import { default as CashfreeSubmitted } from './pages/cashfree-submitted'
import { default as CashfreeEsclation } from './pages/cashfree-esclation'

const Collect = () => {
    const [pageState, setPageState] = useState(0)
    const navigate = useNavigate()
    const [pgData, setPgData] = useState({})

    useEffect(() => {
        const checkAccountStatus = async function () {
            const response = await paymentCollection.accountStatus();

            if (response.status === 200) {
                if (response.data?.data.pg === "Razorpay" && response.data?.data.pgStatus === "Active") {
                    setPgData(response.data?.data)
                    setPageState(1)
                } else if (response.data?.data.pg === "Razorpay" && response.data?.data.pgStatus === "Pending") {
                    setPageState(3);
                } else if (response.data?.data.pg === "Cashfree" && response.data?.data.pgStatus === "Active") {
                    setPgData(response.data?.data)
                    setPageState(1)
                } else if (response.data?.data.pg === "Cashfree" && response.data?.data.pgStatus === "Not Started") {
                    setPageState(4);
                } else if (response.data?.data.pg === "Cashfree" && response.data?.data.pgStatus === "Submitted") {
                    setPageState(6)
                } else if (response.data?.data.pg === "Cashfree" && response.data?.data.pgStatus === "Pending") {
                    setPageState(5)
                } else if (response.data?.data.pg === "Cashfree" && response.data?.data.pgStatus === "Escalation") {
                    setPageState(7)
                }
            } else if (response.status === 401) {
                navigate("/agent/auth");
            } else {
                navigate("/agent/internal-server-error");
            }
        }

        checkAccountStatus()
    }, [navigate])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Collect Payment</h6>
            <hr className="mt-3 text-gray" />
            {pageState === 0 && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
            {pageState === 1 && <NewPayment pgData={pgData} />}
            {pageState === 3 && <RazorpayNotActive />}
            {pageState === 4 && <CashfreeNotActive />}
            {pageState === 5 && <CashfreePending />}
            {pageState === 6 && <CashfreeSubmitted />}
            {pageState === 7 && <CashfreeEsclation />}
        </div>
    )
}

export default Collect