import React, { useState } from 'react'
import { CommonFormButton, CommonFormCurrencyInput, CommonFormSelect, CommonFormTextInput, CommonPopup, CommonError } from '../..'
import API from '../../../apis'
import { useNavigate } from 'react-router-dom'

const WalletManualEntry = () => {
    const [error, setError] = useState(null)
    const [data, changeData] = useState({})
    const [completed, setCompleted] = useState(false)
    const navigate = useNavigate()

    const setData = function (k, v) {
        changeData(prev => ({ ...prev, [k]: v }))
    }

    const submit = async function () {
        setError(null)
        const response = await API.backoffice.walletTransactions.add(data)
        if (response.status === 200) {
            setCompleted(true)
        } else {
            setError(response.error || "API Error!")
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Manual Wallet Entry</h6>
            <hr className="mt-3 text-gray" />
            {completed && <CommonPopup onOk={() => navigate("./../")} onCancel={() => navigate("./../")}><div className="whitespace-pre-line">Manual entry has been added.</div></CommonPopup>}
            <div className="flex flex-col w-full md:w-3/4 lg:w-1/2 xl:w-1/3 mt-4 space-y-4">
                {error && <CommonError error={error} />}
                <CommonFormTextInput id="sk-backoffice-wallet-manual-entry-agent" label="Agent PSK ID" onChange={v => setData("agent", v)} placeholder="PSK-XXXXXXX" />
                <CommonFormSelect id="sk-backoffice-wallet-manual-entry-type" label="Type" onChange={v => setData("type", v)}>
                    <option value="credit">Credit</option>
                    <option value="debit">Debit</option>
                </CommonFormSelect>
                <CommonFormCurrencyInput id="sk-backoffice-wallet-manual-entry-amount" label="Amount" onChange={v => setData("amount", v)} />
                <CommonFormTextInput id="sk-backoffice-wallet-manual-entry-remarks" label="Remarks" onChange={v => setData("remarks", v)} maxLength="200" />
                <CommonFormButton text="Submit" onClick={submit} />
            </div>
        </div>
    )
}

export default WalletManualEntry