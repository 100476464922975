import React from "react"
import Flight from "./tile-flight"

const FlightGroup = ({ type, horizontal, bigger, flightData, searchData }) => {
    return (
        <>{
            flightData && flightData?.onward && flightData?.return
                ? <div className="flex flex-col">
                    <Flight type={type} horizontal={horizontal} bigger={bigger} flightData={flightData.onward} searchData={searchData} />
                    <Flight type={type} horizontal={horizontal} bigger={bigger} flightData={flightData.return} searchData={searchData} />
                </div>
                : flightData?.onward && <Flight type={type} horizontal={horizontal} bigger={bigger} flightData={flightData.onward} searchData={searchData} />
        }</>
    )
}

export default FlightGroup