import React from "react"
import { CommonFormButton } from "../../.."
import utils from "../../../common/utils"
import { isMobile } from "react-device-detect"

const SearchNav = ({ data, showEdit, searchEnabled, type }) => {
    const travellerClassValue = () => {
        if (!data.num_travellers && !data.travel_class) return null
        let val = null, num_travellers = data.adult
        num_travellers += data.child ?? 0
        num_travellers += data.infant ?? 0
        val = num_travellers + " PAX"
        if (data.travel_class) {
            if (val) val += ", " + data.travel_class
            else val = data.travel_class
        }
        return val
    }

    const travellerClassValueMobile = () => {
        if (!data.num_travellers && !data.travel_class) return []
        let val = [], num_travellers = data.adult
        num_travellers += data.child ?? 0
        num_travellers += data.infant ?? 0
        val.push(num_travellers + " PAX")
        if (data.travel_class) val.push(data.travel_class)
        if (data.fare_type) val.push(data.fare_type)
        return val
    }

    return (
        <>
            {
                isMobile ?
                    <div className="w-full bg-gray-100 px-4 py-2 flex items-center justify-between">
                        <div className="flex flex-col">
                            <span className="text-sm font-semibold">{type === 1 ? data.from : data.to} - {type === 1 ? data.to : data.from}</span>
                            <span className="text-xs font-medium">{travellerClassValueMobile().join(" \u2022 ")}</span>
                        </div>
                        <CommonFormButton text="EDIT" short={true} className="px-4 col-span-full" onClick={showEdit} disabled={!searchEnabled} />
                    </div>
                    : <div className="w-full bg-gray-100 p-2 md:p-4 grid grid-cols-2 sm:grid-cols-3 md:flex md:flex-wrap gap-2 md:gap-4 items-center justify-center md:pb-12 z-10">
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">TRIP TYPE</p>
                            <p className="text-sm lg:text-base font-medium">{data.round_trip ? "Round Trip" : "One Way"}</p>
                        </div>
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">FROM</p>
                            <p className="text-sm lg:text-base font-medium">{data.from}</p>
                        </div>
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">TO</p>
                            <p className="text-sm lg:text-base font-medium">{data.to}</p>
                        </div>
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">DEPART</p>
                            <p className="text-sm lg:text-base font-medium">{utils.formatDate(data.departure_date)}</p>
                        </div>
                        {data.round_trip && <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">RETURN</p>
                            <p className="text-sm lg:text-base font-medium">{utils.formatDate(data.return_date)}</p>
                        </div>}
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">PAX & CLASS</p>
                            <p className="text-sm lg:text-base font-medium line-clamp-1">{travellerClassValue()}</p>
                        </div>
                        <div className="bg-white py-2 px-3 flex flex-col rounded col-span-1">
                            <p className="text-xs text-sk-blue font-semibold">FARE TYPE</p>
                            <p className="text-sm lg:text-base font-medium line-clamp-1">{data.fare_type}</p>
                        </div>
                        <CommonFormButton text="EDIT" short={true} className="px-4 col-span-full" onClick={showEdit} disabled={!searchEnabled} />
                    </div>
            }
        </>
    )
}

export default SearchNav