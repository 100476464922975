import React from 'react'
import PropTypes from 'prop-types'

const EmailInput = (props) => {

    function focusInput() {
        document.getElementById(props.id).focus()
    }

    function onChange(e) {
        if (typeof props.onChange === "function") props.onChange(e.target.value)
    }

    return (
        <div className={"relative border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col-reverse " + (props.className ? props.className : "")} onClick={focusInput}>
            <input ref={props.inputRef || null} id={props.id} type="email" className="peer focus:outline-none font-medium inline-block w-full" onChange={onChange} autoComplete="off" disabled={props.disabled ? true : false} defaultValue={props.value || ""} />
            <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red">{props.label}</label>
        </div>
    )
}

EmailInput.propTypes = {
    id: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    inputRef: PropTypes.any,
    disabled: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string
}

export default EmailInput