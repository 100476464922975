import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

const sizes = {
    "xs": "xs",
    "sm": "sm",
    "lg": "lg",
    "xl": "xl"
}

const ButtonRadio = ({ className, options, defaultValue, size, onChange, allowDeselect }) => {
    const [selected, setSelected] = useState(null)

    useEffect(() => {
        setSelected(defaultValue)
    }, [defaultValue])

    const getStyles = (txt, size) => {
        let respClass = ""

        if (size) respClass += "text-" + size + " "
        else respClass += "text-base "

        if (selected === txt) respClass += "font-semibold text-sk-blue border-sk-blue border "
        else respClass += "font-semibold text-gray-900 border-gray-100 border "

        return respClass
    }

    async function onClickInternal(val) {
        if (allowDeselect && selected === val) {
            setSelected(null)
            if (typeof onChange === "function") onChange(null)
        } else {
            setSelected(val)
            if (typeof onChange === "function") onChange(val)
        }
    }

    return (
        <div className={"space-x-2 " + (className || "")}>
            {options.map((opt, i) =>
                <button key={i} type="button" className={"px-2 py-1 rounded-full cursor-pointer bg-white " + getStyles(opt.key, size)} onClick={() => onClickInternal(opt.key)}>{opt.text}</button>
            )}
        </div>
    )
}

ButtonRadio.propTypes = {
    size: PropTypes.string,
    className: PropTypes.string,
    options: PropTypes.array,
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    allowDeselect: PropTypes.bool
}

ButtonRadio.sizes = sizes

export default ButtonRadio