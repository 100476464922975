// eslint-disable-next-line
import React, { useState, useEffect, useCallback } from 'react'
import { CommonFormButton, CommonFormDateInput, CommonFormEmailInput, CommonFormFileInput, CommonFormRadio, CommonFormSelect, CommonFormTextInput, CommonPopup } from '../..'
import API from '../../../apis'
import { useNavigate, useSearchParams } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const Add = () => {
    const [error, setError] = useState(null)
    const [data, changeData] = useState({})
    const [onboardingData, setOnboardingData] = useState({})
    let [alertFunc, setAlertFunc] = useState(() => () => setError(null))
    const navigate = useNavigate()
    const [RMs, setRMs] = useState([])
    const [searchParams] = useSearchParams()
    // useEffect(() => console.log(data), [data])

    useEffect(() => {
        const fetchRMs = async function () {
            const response = await API.backoffice.partner.listRMs()
            if (response.status === 200 && response.data?.data && Array.isArray(response.data.data)) {
                setRMs(response.data.data)
            }
        }

        fetchRMs()
    }, [])

    useEffect(() => {
        const fetchDetails = async function () {
            const id = searchParams.get("q")
            if (id) {
                const response = await API.backoffice.partnerLeads.details(id)
                if (response.status === 200 && response.data.data) {
                    setOnboardingData(response.data.data)
                    setDataArray(["login.mobile", "login.password", "login.confirmpassword", "company.name", "designated.name", "designated.mobile", "designated.email"], [response.data.data.mobile, response.data.data.password, response.data.data.password, response.data.data.company, response.data.data.name, response.data.data.mobile, response.data.data.email])
                } else {
                    setError(response.error || "API Error!")
                }
            }
        }
        fetchDetails()
    }, [searchParams])


    useEffect(() => {
        changeData(prev => ({ ...prev, "login.type": "agent" }));
    }, []);



    function setData(k = "", v) {
        changeData(prev => {
            const current = { ...prev, [k]: v }
            checkData(prev, current)
            return current
        })
    }

    function setDataArray(k = [], v = []) {
        changeData(prev => {
            const current = { ...prev }
            for (let i = 0; i < k.length; i++) current[k[i]] = v[i]
            return current
        })
    }

    async function checkData(prev, current) {
        const check = []

        const curMobile = current["login.mobile"]
        const preMobile = prev["login.mobile"]
        if (curMobile !== preMobile && curMobile.length === 10) check.push("login.mobile")

        const curIFSC = current["account.ifsc"]
        const preIFSC = prev["account.ifsc"]
        if (curIFSC !== preIFSC) {
            if (curIFSC.length === 11) check.push("account.ifsc")
            document.getElementById("sk-new-partner-bank-name").value = ""
            document.getElementById("sk-new-partner-bank-branch").value = ""
            setData("account.bank", null)
            setData("account.branch", null)
        }

        const curPincode = current["company.pincode"]
        const prePincode = prev["company.pincode"]
        if (curPincode !== prePincode) {
            if (curPincode.length === 6) check.push("company.pincode")
            document.getElementById("sk-new-partner-city").value = ""
            document.getElementById("sk-new-partner-state").value = ""
            setData("company.city", null)
            setData("company.state", null)
        }

        if (check.length === 0) return
        const response = await API.backoffice.partner.check(current, check)
        if (response.status === 200) {
            if (response.data?.error) {
                setError(response.data?.error)
            } else if (response.data?.data) {
                if ("account.ifsc" in response.data.data) {
                    const { bank, branch } = response.data.data["account.ifsc"]
                    document.getElementById("sk-new-partner-bank-name").value = bank
                    document.getElementById("sk-new-partner-bank-branch").value = branch
                    setData("account.bank", bank)
                    setData("account.branch", branch)
                }

                if ("company.pincode" in response.data.data) {
                    const { district, state } = response.data.data["company.pincode"]
                    document.getElementById("sk-new-partner-city").value = district
                    document.getElementById("sk-new-partner-state").value = state
                    setData("company.city", district)
                    setData("company.state", state)
                }
            }
        }
    }

    async function submitData() {
        const complete = await API.backoffice.partner.add(data, setData, setError)
        if (complete) {
            MixPanel.track(MixPanel.types.backoffice.partner.add)
            const id = searchParams.get("q")
            if (searchParams.get('q')) await API.backoffice.partnerLeads.markused(id)
            setAlertFunc(() => () => navigate("/backoffice/users/partners/manage"))
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Add Partner</h6>
            <hr className="mt-3 text-gray" />
            {error && <CommonPopup onOk={alertFunc} onCancel={alertFunc}><div className="whitespace-pre-line">{error}</div></CommonPopup>}
            <div className="grid grid-cols-3 gap-x-4 gap-y-4 mb-8">
                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Login Type</p>
                <div className="col-span-3">
                    <CommonFormRadio id="sk-new-partner-add-type" name="sk-new-partner-add-type" options={[{ key: "partner", text: "Create login as Partner", helper: "A new partner will be added with login credentials and no agent will be created by default.", }, { key: "agent", text: "Create login as Agent", helper: "Partner will be added without login credentials and an agent login will be created automatically.", }]} defaultValue={"agent"} onChange={(v) => setData("login.type", v)} />
                </div>

                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Login Credentials</p>
                <CommonFormTextInput id="sk-new-partner-mobile" value={onboardingData.mobile ? onboardingData.mobile : ""} type="tel" minLength="10" maxLength="10" label="Mobile Number*" onChange={(v) => setData("login.mobile", v)} />
                <CommonFormTextInput id="sk-new-partner-password" type="password" value={onboardingData.password ? onboardingData.password : ""} minLength="6" label="Password*" onChange={(v) => setData("login.password", v)} />
                <CommonFormTextInput id="sk-new-partner-confirm-password" type="password" value={onboardingData.password ? onboardingData.password : ""} minLength="6" label="Confirm Password*" onChange={(v) => setData("login.confirmpassword", v)} />

                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Company Details</p>
                <CommonFormTextInput id="sk-new-partner-name" label="Company Name*" value={onboardingData.company ? onboardingData.company : ""} minLength="3" onChange={(v) => setData("company.name", v)} />
                <CommonFormSelect id="sk-new-partner-name" label="Company Type" onChange={(v) => setData("company.type", v)}>
                    {React.Children.map(["Private Limited", "Public Limited", "Proprietary", "LLP", "Partnership", "Joint Venture", "HUF"], (x, i) =>
                        <option key={i} value={x}>{x}</option>
                    )}
                </CommonFormSelect>
                <CommonFormDateInput id="sk-new-partner-doi" label="Date of Incorporation" onChange={(v) => setData("company.doi", v)} />
                <CommonFormTextInput id="sk-new-partner-designated-person-name" label="Designated Person Name" value={onboardingData.name ? onboardingData.name : ""} onChange={(v) => setData("designated.name", v)} />
                <CommonFormTextInput id="sk-new-partner-designated-person-mobile" type="tel" label="Designated Person Mobile" value={onboardingData.mobile ? onboardingData.mobile : ""} minLength="10" maxLength="10" onChange={(v) => setData("designated.mobile", v)} />
                <CommonFormEmailInput id="sk-new-partner-email" label="Designated Person Email*" value={onboardingData.email ? onboardingData.email : ""} onChange={(v) => setData("designated.email", v)} />
                <CommonFormTextInput id="sk-new-partner-address" label="Company Address*" minLength="3" onChange={(v) => setData("company.address", v)} />
                <CommonFormTextInput id="sk-new-partner-pincode" label="Pincode*" minLength="6" maxLength="6" onChange={(v) => setData("company.pincode", v)} />
                <CommonFormTextInput id="sk-new-partner-city" label="City" disabled={true} />
                <CommonFormTextInput id="sk-new-partner-state" label="State" disabled={true} />
                <CommonFormTextInput id="sk-new-partner-pan-number" label="PAN*" minLength="10" maxLength="10" onChange={(v) => setData("company.pan", v)} />
                <CommonFormTextInput id="sk-new-partner-gst-number" label="GST Number" minLength="15" maxLength="15" onChange={(v) => setData("company.gst", v)} />

                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Banking Details</p>
                <CommonFormTextInput id="sk-new-partner-ops-name" label="Operations Contact Name" onChange={(v) => setData("ops.name", v)} />
                <CommonFormTextInput id="sk-new-partner-ops-mobile" type="tel" label="Operations Contact Mobile" minLength="10" maxLength="10" onChange={(v) => setData("ops.mobile", v)} />
                <CommonFormTextInput id="sk-new-partner-ops-email" label="Operations Contact Email" onChange={(v) => setData("ops.email", v)} />
                <CommonFormTextInput id="sk-new-partner-bank-account-name" label="Beneficiary Account Name*" onChange={(v) => setData("account.name", v)} />
                <CommonFormTextInput id="sk-new-partner-bank-account" label="Bank Account Number*" onChange={(v) => setData("account.number", v)} regex="[^\d]" />
                <CommonFormTextInput id="sk-new-partner-bank-ifsc" label="IFSC Code*" onChange={(v) => setData("account.ifsc", v)} maxLength="11" />
                <CommonFormTextInput id="sk-new-partner-bank-name" label="Bank Name" disabled={true} />
                <CommonFormTextInput id="sk-new-partner-bank-branch" label="Bank Branch" disabled={true} />

                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Relationship Manager Details</p>
                <CommonFormSelect id="sk-new-partner-rm-id" label="Relationship Manager" onChange={v => setData("rm.id", v)}>
                    {RMs.map(x => { if (!x.name) x.name = x.id; return x }).sort((a, b) => a.name.localeCompare(b.name)).map(rm => <option key={rm.id} value={rm.id}>{rm.name + " (" + rm.id + ")"}</option>)}
                </CommonFormSelect>

                <p className="mt-8 col-span-full uppercase text-sm font-semibold">Upload Documents</p>
                <CommonFormFileInput id="sk-new-partner-pan" label="Upload PAN Card" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" onChange={(v) => setData("files.pan", v)} />
                <CommonFormFileInput id="sk-new-partner-gst" label="Upload GST Certificate" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" onChange={(v) => setData("files.gst", v)} />
                <CommonFormFileInput id="sk-new-partner-logo" label="Upload Company Logo" accept=".png,.jpg,.jpeg,.svg" onChange={(v) => setData("files.logo", v)} />
                <CommonFormFileInput id="sk-new-partner-bank-cheque" label="Cancelled Cheque Copy" accept=".doc,.docx,.pdf,.png,.jpg,.jpeg" onChange={(v) => setData("files.cheque", v)} />

                <p className="col-span-full mt-6"></p>
                <p></p>
                <CommonFormButton className="w-full" text="Submit" onClick={submitData} />
                <p></p>
            </div>
        </div>
    )
}

export default Add