import React, { useEffect, useState } from 'react'
import API from '../../../apis'
import { CommonLoader } from '../..'

const Dashboard = (props) => {
    const [URL, setURL] = useState(null)
    useEffect(() => {
        const getDashboard = async function () {
            const response = await API.dashboards.get(props.id)
            if (response.status === 200 && response.data?.data) {
                setURL(response.data.data)
            }
        }
        getDashboard()
    }, [props.id])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">{props.heading || "Sample Heading"}</h6>
            <hr className="mt-3 text-gray" />
            {!URL && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
            {URL && <iframe
                className="my-6"
                title="tnple-applications"
                src={URL}
                width="100%"
                height="600"
                allowtransparency="true"
            ></iframe>}
        </div>
    )
}

export default Dashboard