import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import API from '../../../apis'
import { CommonTable } from '../..'

const Covers = () => {
    const [searchParams] = useSearchParams()
    const [detail, setDetail] = useState(null)

    useEffect(() => {
        const showDetails = async function () {
            const plan_id = searchParams.get("plan") * 1
            if (!plan_id) return
            const response = await API.insurance.covers(plan_id)
            if (response.status === 200 && response.data?.data) {
                setDetail(response.data.data)
            }
        }
        showDetails()
    }, [searchParams])

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Cover Details</h6>
            <hr className="mt-3 text-gray" />
            {detail && <CommonTable data={detail} className="mt-4" />}
        </div>
    )
}

export default Covers