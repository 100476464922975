import React from "react"
import { CommonPopup, CommonFormRadioButtons } from "../../.."

const TravelClass = (props) => {
    return (
        <CommonPopup sticky={true} wide={true} onOk={props.hide} onCancel={props.hide}>
            <div className="flex items-center gap-4 justify-center mb-4">
                <span className="font-medium text-sm">ADULT (12Y+)</span>
                <CommonFormRadioButtons allowDeselect={true} className="col-span-full" options={[1, 2, 3, 4, 5, 6, 7, 8, 9].map(x => ({ key: x, text: x }))} onChange={v => props.updateData("adult", v)} size={CommonFormRadioButtons.sizes.sm} defaultValue={props.data.adult || null} />
            </div>
            <div className="flex items-center gap-4 justify-center mb-4">
                <span className="font-medium text-sm">CHILDREN (2-12Y)</span>
                <CommonFormRadioButtons allowDeselect={true} className="col-span-full" options={[1, 2, 3, 4, 5, 6].map(x => ({ key: x, text: x }))} onChange={v => props.updateData("child", v)} size={CommonFormRadioButtons.sizes.sm} defaultValue={props.data.child || null} />
            </div>
            <div className="flex items-center gap-4 justify-center mb-4">
                <span className="font-medium text-sm">INFANT (below 2Y)</span>
                <CommonFormRadioButtons allowDeselect={true} className="col-span-full" options={[1, 2, 3, 4, 5, 6].map(x => ({ key: x, text: x }))} onChange={v => props.updateData("infant", v)} size={CommonFormRadioButtons.sizes.sm} defaultValue={props.data.infant || null} />
            </div>
            <div className="flex items-center gap-4 mt-4 justify-center">
                <span className="font-medium text-sm">TRAVEL CLASS</span>
                <CommonFormRadioButtons allowDeselect={true} className="col-span-full" options={["ECONOMY", "PREMIUM ECONOMY", "BUSINESS", "FIRST"].map(x => ({ key: x, text: x }))} onChange={v => props.updateData("travel_class", v)} size={CommonFormRadioButtons.sizes.sm} defaultValue={props.data.travel_class || null} />
            </div>
        </CommonPopup>
    )
}

export default TravelClass