import React, { useEffect } from 'react'
import MixPanel from '../../../../config/mixpanel'

const Rejection = () => {
    useEffect(() => {
        MixPanel.track(MixPanel.types.customer.application.rejected)
    }, [])

    return (
        <div className="px-4 flex-1 flex flex-col items-center justify-center *:text-center space-y-6">
            <img src="/assets/icons/exclamation.svg" alt="" />
            <p className="text-center text-xl">Application Under Process</p>
            <p>Your application is under review. We will reach out to you for further processing.</p>
        </div>
    )
}

export default Rejection