import React from "react";
import Constants from "../../../../config/constants";
import { IoIosArrowBack } from "react-icons/io"
import { isMobile } from "react-device-detect";

const partnerLogos = Constants.FLIGHTS.PARTNER_LOGOS

const PartnerHeader = ({ partnerSortOrder, partnerDisplayOffset, setPartnerDisplayOffset }) => {
    const partnersToDisplay = partnerSortOrder.slice(partnerDisplayOffset, partnerDisplayOffset + 4)
    const showLeftArrow = partnerSortOrder.length > 4 && partnerDisplayOffset > 0
    const showRightArrow = partnerSortOrder.length > 4 && partnerDisplayOffset < (partnerSortOrder.length - 4)

    return (
        <>
            {
                isMobile
                    ? partnersToDisplay.map((partner, i) =>
                        <div key={i} className="w-32 bg-gray-100 text-center align-middle">
                            <img src={partnerLogos[partner]} alt={partner + "-logo"} className="h-6 inline-block" />
                        </div>
                    )
                    : partnersToDisplay.map((partner, i) =>
                        <div key={i} className={"bg-gray-100 text-center align-middle relative " + (i === 0 ? "rounded-l-xl" : "border-l border-l-white") + " " + (i === (partnersToDisplay.length - 1) && "rounded-r-xl")}>
                            <img src={partnerLogos[partner]} alt={partner + "-logo"} className="h-8 inline-block" />
                            {showLeftArrow && i === 0 && <span className="absolute left-0 -translate-x-full pr-1 cursor-pointer" onClick={() => setPartnerDisplayOffset(partnerDisplayOffset - 1)}><IoIosArrowBack className="w-4 h-8" /></span>}
                            {showRightArrow && i === (partnersToDisplay.length - 1) && <span className="absolute right-0 translate-x-full pl-1 cursor-pointer" onClick={() => setPartnerDisplayOffset(partnerDisplayOffset + 1)}><IoIosArrowBack className="w-4 h-8 rotate-180" /></span>}
                        </div>
                    )
            }
        </>
    )
}

export default PartnerHeader