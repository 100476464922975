import React from 'react'

import { CommonPopup } from '../../..'

const PGChargesRazorpay = (props) => {
    return (
        <CommonPopup onOk={props.hide} onCancel={props.hide} wide={true}>
            <p className="mb-2">PG Charges</p>
            <table className="table-auto w-full border-collapse">
                <thead className="bg-sk-yellow">
                    <tr className="*:px-2 *:py-3 *:text-sm *:border *:border-white *:select-none">
                        <th>Payment Method</th>
                        <th>Conditions</th>
                        <th>Charges</th>
                    </tr>
                </thead>
                <tbody className="*:text-sm *:text-center">
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="2">UPI</td>
                        <td>Less than or equal to &#8377; 2,000</td>
                        <td>0.31%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>More than &#8377; 2,000</td>
                        <td>0.31%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="3">Debit Cards</td>
                        <td>Less than or equal to &#8377; 2,000</td>
                        <td>0.64%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>More than &#8377; 2,000</td>
                        <td>1.08%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>Rupay Debit Cards</td>
                        <td>0.31%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="2">Netbanking</td>
                        <td>HDFC, ICICI, Axis, SBI, Kotak and Yes Bank</td>
                        <td>1.79%</td>
                    </tr>
                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td>All others</td>
                        <td>1.46%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">Mobile Wallets</td>
                        <td>All</td>
                        <td>1.96%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">Credit Cards</td>
                        <td>All</td>
                        <td>1.96%</td>
                    </tr>

                    <tr className="*:border *:border-gray-100 *:py-2 *:px-2">
                        <td rowSpan="1">Corporate Cards / International</td>
                        <td>All</td>
                        <td>2.78%</td>
                    </tr>
                </tbody>
            </table>
        </CommonPopup>
    )
}

export default PGChargesRazorpay