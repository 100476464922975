import React, { useEffect, useState, useCallback } from 'react'
import { CommonError, CommonLoader, CommonSummary, CommonTableKeyValue, CommonFormButton } from '../../../'
import { useNavigate } from 'react-router-dom'
import API from '../../../../apis'
import MixPanel from '../../../../config/mixpanel'

const Details = (props) => {
    // eslint-disable-next-line
    const navigate = useNavigate()
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    const fetchDetails = useCallback(async function () {
        const response = await props.detailAPI({ id: props.id })
        if (response.status === 200 && response.data.data) {
            setData(response.data.data)
            MixPanel.track(MixPanel.types.agent.transactions.insurance_detail)
        } else {
            setError(response.error || "API Error!")
        }
    }, [props])

    useEffect(() => {
        setData(null)
        fetchDetails()
    }, [props, fetchDetails])

    const requestCancellation = async function () {
        const response = await API.insurance.cancel(props.id)
        if (response.status === 200) {
            MixPanel.track(MixPanel.types.agent.transactions.insurance_detail_action, { type: "CANCEL" })
            return true
        } else return false
    }

    const update = async function (type) {
        setError(null)
        await props.actionsTrigger(type)
        setData(null)
        setTimeout(fetchDetails, 200)
    }

    return (
        <div className="z-20 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto shadow-2xl bg-gray-900 bg-opacity-50 flex flex-row-reverse" onClick={() => typeof props.onClose === "function" && props.onClose()}>
            <div className={"w-full md:w-2/3 lg:w-1/2 xl:w-1/3 bg-white flex flex-col slide-in-right " + ((error || !data) && "justify-center items-center")} onClick={(e) => e.stopPropagation()}>
                {data === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {data === null && error && <p className="text-center"><CommonError error={error} /></p>}
                {data &&
                    <div className="flex flex-col p-4 overflow-y-scroll gap-y-4">
                        <img src="/assets/icons/cross.svg" alt="close" className="h-5 pr-4 pb-2 inline-block self-start opacity-50 cursor-pointer" onClick={() => typeof props.onClose === "function" && props.onClose()} />
                        <CommonSummary data={[["INSURANCE ID", props.id, true], ["SELECTED PLAN", data.plan]]} />

                        {props.showActions && data.actions && Object.keys(data.actions).length > 0 && <div className="flex justify-center space-x-4 flex-wrap gap-y-4">
                            {data.actions["CANCEL"] && <CommonFormButton text="Cancel" short={true} className="text-sm px-2" onClick={requestCancellation} showResult={true} />}

                            {data.actions["CANCELLATION_COMPLETED"] && <CommonFormButton text="Cancel Complete" short={true} className="text-sm px-2" onClick={async () => await update("CANCELLATION_COMPLETED")} showResult={true} />}

                            {data.actions["CANCELLATION_REJECTED"] && <CommonFormButton text="Cancel Rejected" short={true} className="text-sm px-2" onClick={async () => await update("CANCELLATION_REJECTED")} showResult={true} />}

                            {data.actions["RETRY_POLICY_GENERATION"] && <CommonFormButton text="RETRY" short={true} className="text-sm px-2" onClick={async () => await update("RETRY_POLICY_GENERATION")} showResult={true} />}

                            {data.actions["MARK_FAILED"] && <CommonFormButton text="MARK FAILED" short={true} className="text-sm px-2" onClick={async () => await update("MARK_FAILED")} showResult={true} />}
                        </div>}

                        {Object.keys(data).map((field, i) =>
                            Array.isArray(data[field]) && <CommonTableKeyValue key={i} className="" heading={field}>
                                {data[field].map((row, j) =>
                                    <span key={row.key} rowclassname={row.class || ""} download={row.download || null} copy={row.copy ? row.value : false}>{row.value}</span>
                                )}
                            </CommonTableKeyValue>
                        )}

                        <CommonFormButton text="Close" className="md:hidden" isOutline={true} onClick={props.onClose} />
                    </div>
                }
            </div>
        </div>
    )
}

export default Details