import React, { useEffect, useState } from 'react'
import { CommonError, CommonFormButton, CommonLoader, CommonSummary, CommonTableKeyValue } from '../../../'
import MixPanel from '../../../../config/mixpanel'

const PaymentDetails = (props) => {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)

    useEffect(() => {
        const fetchDetails = async function () {
            const response = await props.detailAPI({ id: props.id })
            if (response.status === 200 && response.data.data) {
                setData(response.data.data)
                MixPanel.track(MixPanel.types.agent.transactions.payment_detail, { id: props.id })
            } else {
                setError(response.error || "API Error!")
            }
        }
        setData(null)
        fetchDetails()
    }, [props])

    return (
        <div className="z-20 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto shadow-2xl bg-gray-900 bg-opacity-50 flex flex-row-reverse" onClick={() => typeof props.onClose === "function" && props.onClose()}>
            <div className={"w-full md:w-2/3 lg:w-1/2 xl:w-1/3 bg-white flex flex-col slide-in-right " + ((error || !data) && "justify-center items-center")} onClick={(e) => e.stopPropagation()}>
                {data === null && error === null && <p className="text-center"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Loading...</p>}
                {data === null && error && <p className="text-center"><CommonError error={error} /></p>}
                {data &&
                    <div className="flex flex-col p-4 overflow-y-scroll gap-y-4">
                        <img src="/assets/icons/cross.svg" alt="close" className="h-5 pr-4 pb-2 inline-block self-start opacity-50 cursor-pointer" onClick={() => typeof props.onClose === "function" && props.onClose()} />
                        <CommonSummary data={[["PAYMENT COLLECTION ID", props.id, true], ["STATUS", data.status]]} />
                        {data.status_desc && <p className="text-xs text-center">{data.status_desc}</p>}

                        {Object.keys(data).map((field, i) =>
                            Array.isArray(data[field]) && <CommonTableKeyValue key={i} className="" heading={field}>
                                {data[field].map((row, j) =>
                                    <span key={row.key}>{row.value}</span>
                                )}
                            </CommonTableKeyValue>
                        )}

                        <CommonFormButton text="Close" className="md:hidden" isOutline={true} onClick={props.onClose} />
                    </div>
                }
            </div>
        </div>
    )
}

export default PaymentDetails