import React from 'react'
import PropTypes from 'prop-types'
import Radio from './radio'

const RadioWrapper = (props) => {
    return (
        <div className="border rounded-xl border-sk-blue py-3 px-6 has-[:invalid]:border-red flex flex-col">
            {props.label && <label htmlFor={props.id} className="text-sm block text-sk-blue font-semibold peer-invalid:text-red mb-0.5">{props.label}</label>}
            <Radio {...props} />
        </div>
    )
}

Radio.propTypes = {
    label: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    grid: PropTypes.number,
    inline: PropTypes.bool
}

export default RadioWrapper