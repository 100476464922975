import React from 'react'
import Login from './login'

const AgentLogin = () => {
    return (
        <Login base="/agent">
            <p className="text-2xl">Get ready to</p>
            <p className="text-3xl font-semibold">supercharge your business</p>
            <p className="text-xl font-medium mt-5 text-sk-blue">Agent Login</p>
        </Login>
    )
}

export default AgentLogin