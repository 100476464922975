import React, { useState, useEffect } from 'react'

const Pagination = (props) => {
    let [currentPage, setCurrentPage] = useState(props.currentPage || 1)
    let [displayPages, setDisplayPages] = useState(Array(Math.min(3, props.maxPages)).fill(0).map((x, i) => i + 1))

    function onPageChanged(val) {
        let page = currentPage
        if (val === 'prev') page = currentPage - 1
        else if (val === 'next') page = currentPage + 1
        else page = val
        setCurrentPage(page)
        if ("onPageChanged" in props && typeof props.onPageChanged === "function") props.onPageChanged(page)
    }

    useEffect(function () {
        let newDisplayPages = Array(Math.min(3, props.maxPages)).fill(0)
        if (currentPage === 1) newDisplayPages = newDisplayPages.map((x, i) => i + 1)
        else if (currentPage < props.maxPages) newDisplayPages = newDisplayPages.map((x, i) => currentPage + i - 1)
        else if (currentPage === props.maxPages) newDisplayPages = newDisplayPages.map((x, i) => props.maxPages - i).sort()
        setDisplayPages(newDisplayPages)
    }, [currentPage, props.maxPages])

    return (
        <div className="flex items-center *:mx-1 *:cursor-pointer *:text-xs *:select-none">
            {(currentPage > 1) && <span onClick={() => onPageChanged('prev')} className="text-sk-blue font-medium">Previous</span>}
            {displayPages.map(x =>
                <span key={x} onClick={() => onPageChanged(x)} className={"border rounded w-4 h-4 text-center " + (currentPage === x ? "border-sk-blue text-sk-blue" : "border-gray-300 text-gray-300")}>
                    {x}
                </span>
            )}
            {(currentPage < props.maxPages) && <span onClick={() => onPageChanged('next')} className="text-sk-blue font-medium">Next</span>}
        </div>
    )
}

export default Pagination