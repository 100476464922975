import React, { useCallback, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom";

import { CommonFormTextInput, CommonFormEmailInput, CommonFormButton, CommonError, CommonFormDateInput, CommonFormRadioWrapper, CommonPopup } from ".."
import MixPanel from "../../config/mixpanel"
import { HiCheckBadge } from "react-icons/hi2";

const requiredKeys = ["personal.name", "personal.pan", "personal.dob", "personal.gender", "personal.mobile", "personal.email", "personal.address", "personal.pincode", "personal.residence_ownership", "business.name", "business.pan", "business.type", "business.age", "business.gst_registered", "business.office_ownership"]

const WorkingCapital = () => {
    const [data, setData] = useState({})
    const [params, setParams] = useState({ "business.gst_registered": "Yes" })
    const [error, setError] = useState(null)
    const [popup, setPopup] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    const onChange = useCallback((k, v) => {
        setError(null)
        setData(prev => ({ ...prev, [k]: v }))
    }, [])

    useEffect(() => {
        let needToUpdate = false
        for (let k of ["id", "pan", "gst", "tenure", "dom_bookings", "intl_bookings"]) {
            const paramValue = searchParams.get(k)
            if (paramValue) {
                needToUpdate = true
                setParams(prev => ({ ...prev, [k]: paramValue }))
                if (k === "pan" || k === "gst") {
                    if (k === "pan") {
                        onChange("business.pan", paramValue)
                    } else if (k === "gst") {
                        setParams(prev => ({ ...prev, "business.gst_registered": "Yes" }))
                        onChange("business.gst_registered", "Yes")
                        onChange("business.gst", paramValue)
                    }
                } else {
                    onChange(k, paramValue)
                }
            }
        }
        if (needToUpdate) setSearchParams({})
    }, [searchParams, setSearchParams, onChange])

    const submit = async () => {
        setError(null)
        for (let key of requiredKeys) {
            if (!data[key]) return setError(`${key.split(".").map(x => x.charAt(0).toUpperCase() + x.substring(1)).join(" ")} is required.`)
        }
        const newLead = window.firebase.httpsCallable(window.firebase.functions, "workingCapital", { limitedUseAppCheckTokens: true })
        await newLead(data)
        MixPanel.track(MixPanel.types.landing.working_capital)
        setPopup(true)
    }

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/assets/scripts/firebase.js";
        script.type = "module"
        document.body.appendChild(script);
    }, [])

    return (
        <div className="flex flex-col-reverse lg:flex-row">
            <div className="w-full lg:w-1/2 xl:w-1/3 sk-background py-20 text-white flex flex-col items-center px-8 text-center md:text-left">
                <h3 className="text-center text-2xl font-semibold px-2 mb-2">Unlock Your Business Potential with Flexible Working Capital Solutions, <span className="text-sk-yellow">from SanKash</span></h3>
                <div className="flex flex-col gap-y-8 md:w-3/4 lg:w-full 2xl:w-3/4 mt-8">
                    <div className="flex gap-2">
                        <HiCheckBadge className="flex-none text-white h-6 w-6 mt-0.5 hidden md:inline-block" />
                        <div className="flex-1 flex flex-col gap-1">
                            <p className="text-sk-yellow text-xl font-semibold">Highest Approval Rate</p>
                            <p className="text-white font-medium">Increased chances on loan approval due to a diverse pool of lenders.</p>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <HiCheckBadge className="flex-none text-white h-6 w-6 mt-0.5 hidden md:inline-block" />
                        <div className="flex-1 flex flex-col gap-1">
                            <p className="text-sk-yellow text-xl font-semibold">Highest Loan Limits</p>
                            <p className="text-white font-medium">Access to higher loan amounts, tailored to the needs of travel agents.</p>
                        </div>
                    </div>
                    <div className="flex gap-2">
                        <HiCheckBadge className="flex-none text-white h-6 w-6 mt-0.5 hidden md:inline-block" />
                        <div className="flex-1 flex flex-col gap-1">
                            <p className="text-sk-yellow text-xl font-semibold">Seamless Digital Access</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex-1 flex flex-col pb-12">
                <img src="/assets/sankash-logo.svg" alt="Sankash" className="w-48 mr-5 self-end" />
                <div className="flex flex-col w-full self-center px-16 gap-4">
                    <p className="text-3xl font-medium my-4 lg:-mt-8 text-sk-blue col-span-full text-center lg:text-left">Working Capital</p>
                    <p className="text-lg font-medium">PERSONAL DETAILS</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-4 xl:gap-6 *:col-span-1">
                        <CommonFormTextInput id="sk-landing-working-capital-name" label="Name (As per PAN)" onChange={v => onChange("personal.name", v)} minLength="3" maxLength="50" />
                        <CommonFormTextInput id="sk-landing-working-capital-pan" label="PAN" onChange={v => onChange("personal.pan", v)} minLength="10" maxLength="10" />
                        <CommonFormDateInput id="sk-landing-working-capital-dob" label="Date of Birth (As per PAN)" onChange={v => onChange("personal.dob", v)} />
                        <CommonFormRadioWrapper label="Gender" id="sk-landing-working-capital-gender" options={[{ key: "Male", text: "Male" }, { key: "Female", text: "Female" }]} name="gender" onChange={v => onChange("personal.gender", v)} grid={2} />
                        <CommonFormTextInput id="sk-onboarding-working-capital-mobile" label="Mobile" type="tel" minLength="10" maxLength="10" onChange={v => onChange("personal.mobile", v)} />
                        <CommonFormEmailInput id="sk-onboarding-working-capital-email" label="Email" onChange={v => onChange("personal.email", v)} />
                        <CommonFormTextInput id="sk-onboarding-working-capital-address" label="Current Residence Address" onChange={v => onChange("personal.address", v)} maxLength="50" />
                        <CommonFormTextInput id="sk-onboarding-working-capital-pincode" label="Current Residence Pincode" onChange={v => onChange("personal.pincode", v)} type="number" min="100000" max="999999" />
                        <CommonFormRadioWrapper label="Residence Ownership" id="sk-landing-working-capital-residence-ownership" options={[{ key: "Rented", text: "Rented" }, { key: "Owned", text: "Owned" }]} name="residence-ownership" onChange={v => onChange("personal.residence_ownership", v)} grid={2} />
                    </div>
                    <p className="text-lg font-medium mt-4">BUSINESS DETAILS</p>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 3xl:grid-cols-3 gap-4 xl:gap-6 *:col-span-1">
                        <CommonFormTextInput id="sk-landing-working-capital-business-name" label="Business Name (As per PAN)" onChange={v => onChange("business.name", v)} minLength="3" maxLength="50" />
                        <CommonFormTextInput id="sk-landing-working-capital-business-pan" label="Business PAN" onChange={v => onChange("business.pan", v)} minLength="10" maxLength="10" value={data["business.pan"] || params["business.pan"] || ""} />
                        <CommonFormRadioWrapper label="Company Type" id="sk-landing-working-capital-business-type" options={["Solo Proprietorship", "Partnership", "Private Limited", "Other"].map(x => ({ key: x, text: x }))} name="business-type" onChange={v => onChange("business.type", v)} grid={2} />
                        <CommonFormRadioWrapper label="How old is your business?" id="sk-landing-working-capital-business-age" options={["4+ Years", "2-4 Years", "1-2 Years", "< 1 Year"].map(x => ({ key: x, text: x }))} name="business-age" onChange={v => onChange("business.age", v)} grid={2} />
                        <CommonFormRadioWrapper label="Is your business GST registered?" id="sk-landing-working-capital-business-gst-registered" options={["Yes", "No"].map(x => ({ key: x, text: x }))} name="gst-registered" onChange={v => onChange("business.gst_registered", v)} grid={2} defaultValue={params["business.gst_registered"] || ""} />
                        {data["business.gst_registered"] === "Yes" ? <CommonFormTextInput id="sk-landing-working-capital-number" label="GSTIN" minLength="15" maxLength="15" onChange={(v) => onChange("business.gst", v)} value={data["business.gst"] || params["business.gst"] || ""} /> : <p>&nbsp;</p>}
                        <CommonFormRadioWrapper label="Do you own your Office Address?" id="sk-landing-working-capital-business-address-ownership" options={["Owned", "Rented"].map(x => ({ key: x, text: x }))} name="business-address-ownership" onChange={v => onChange("business.office_ownership", v)} grid={2} />
                    </div>
                    {data["business.gst_registered"] === "No" && <p className="text-red text-xs" style={{ fontSize: "0.8em" }}>Are you sure? We need GST or bank statement to process your application.<br />Without that your application might get rejected.</p>}
                    {error && <CommonError error={error} className="col-span-full" />}
                    <CommonFormButton text="submit" onClick={submit} className="w-full xl:w-1/2 self-center mt-4" />
                    <div className="flex flex-row justify-center items-center gap-4">
                        <span className="text-sm">Powered By</span>
                        <img src="https://sankash.in/assets/images/indifi-logo.png" alt="indifi-logo" className="h-9 inline-block" />
                        <img src="https://www.mintifi.com/img/logo.svg" alt="mintifi-logo" className="h-8 inline-block" />
                    </div>
                </div>
            </div>
            {
                popup && <CommonPopup onOk={() => window.location.replace("https://sankash.in")} sticky={true}>
                    <p>Thank You! Your details have been submitted. Our team will reach out to you.</p>
                </CommonPopup>
            }
        </div >
    )
}

export default WorkingCapital