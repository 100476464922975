import React, { useState } from 'react'
import { CommonError, CommonLoader, CommonTable, CommonPagination, CommonSearch, CommonPopup, CommonFormCheckbox, CommonDropdown } from '../..'
import API from '../../../apis'
import Constants from '../../../config/constants'
import { BsThreeDotsVertical } from "react-icons/bs"
import { Link } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const ManageUsers = () => {
    let [error, setError] = useState(null)
    let [data, setData] = useState(null)
    let [detail, setDetail] = useState(null)
    let [numPages, setNumPages] = useState(null)
    let [currentPage, setCurrentPage] = useState(null)
    let [seed, setSeed] = useState(Date.now())

    let [permissions, setPermissions] = useState([])

    async function actionsHandler(item, action) {
        setError(null)
        let response
        if (action === "Enable User") {
            response = await API.backoffice.manage.users.update(item, { active: 1 })
        } else if (action === "Disable User") {
            response = await API.backoffice.manage.users.update(item, { active: 0 })
        } else if (action === "Update Permissions") {
            response = await API.backoffice.manage.users.update(item, { permissions })
        }
        if (response) {
            if (response.status === 200) {
                setSeed(Date.now())
                MixPanel.track(MixPanel.types.backoffice.user_management.update, { type: action })
            } else setError(response.error || "API Error!")
        }
    }

    async function tableClick(id) {
        setPermissions(data.data.filter(x => x.id === id)[0].permissions.split(", "))
        setDetail(id)
    }

    async function popupOk() {
        await actionsHandler(detail, "Update Permissions")
        setDetail(null)
        setPermissions([])
    }

    async function popupCancel() {
        setDetail(null)
        setPermissions([])
    }

    async function updatePermissions(type) {
        setPermissions(prev => {
            let newPermissions = [...prev]

            if (type === Constants.BACKOFFICE_USER_PERMISSIONS.ALL || (newPermissions.length === 1 && newPermissions[0] === Constants.BACKOFFICE_USER_PERMISSIONS.ALL)) {
                if (type !== Constants.BACKOFFICE_USER_PERMISSIONS.ALL) {

                } else if (newPermissions.indexOf(Constants.BACKOFFICE_USER_PERMISSIONS.ALL) > -1) {
                    newPermissions = []
                } else {
                    newPermissions = [Constants.BACKOFFICE_USER_PERMISSIONS.ALL]
                }
            } else {
                const find = newPermissions.indexOf(type)
                if (find > -1) {
                    newPermissions.splice(find, 1)
                } else {
                    newPermissions.push(type)
                }
            }
            return newPermissions
        })
    }

    return (
        <div className="flex flex-col">
            <div className="flex">
                <h6 className="font-semibold hamburger-spacing">Manage Users</h6>
                <CommonDropdown id="sk-more-options" dropdownId="sk-more-options-dropdown-menu" alignLeft={true}>
                    <BsThreeDotsVertical className="mt-1 ml-2" />
                    <Link to="/backoffice/more/admin/users/add">
                        <span className="block px-4 py-2 hover:bg-gray-100 select-none">Add New User</span>
                    </Link>
                </CommonDropdown>
            </div>
            <hr className="mt-3 text-gray" />
            <CommonSearch seed={seed} className="mt-4" context="bo_manage_users" hideStatus={true} defaultFilter={{ search: null, status: null }} setError={setError} setData={setData} getCount={API.backoffice.manage.users.count} getList={API.backoffice.manage.users.list} setNumPages={setNumPages} setCurrentPage={setCurrentPage} currentPage={currentPage} />
            <p className="mt-4 border-b border-gray-300"></p>
            {error && <CommonError error={error} className="my-4" />}
            {error === null && data === null && <p className="text-center mt-4"><CommonLoader className="h-6 text-gray-500 mr-2 inline-block" />Please wait...</p>}
            {data && <CommonTable className="mt-4" data={data} showDetails={tableClick} actionsHandler={actionsHandler} />}
            {data && numPages > 1 &&
                <div className="my-6 flex justify-end">
                    <CommonPagination currentPage={currentPage} maxPages={numPages} onPageChanged={setCurrentPage} />
                </div>
            }
            {detail && <CommonPopup onOk={popupOk} onCancel={popupCancel} showCancel={true} sticky={true}>
                <p>Update User Permissions</p>
                <div className="flex flex-col space-y-3 pl-6 pt-6">
                    {Object.keys(Constants.BACKOFFICE_USER_PERMISSIONS).map((x, i) =>
                        <div key={i} className="flex">
                            <CommonFormCheckbox className="flex-none w-4 h-4 mt-0.5 ml-2" checked={permissions.includes(x) || permissions.includes("ALL")} onChange={() => updatePermissions(x)} disabled={x === "ALL" ? false : permissions.length === 1 && permissions[0] === "ALL"} />
                            <span className={"flex-1 text-sm ml-2 text-left select-none " + (x === "ALL" ? false : permissions.length === 1 && permissions[0] === "ALL" ? "text-gray-500" : "text-black cursor-pointer")} onClick={() => updatePermissions(x)}>{x.split("_").join(" ")}</span>
                        </div>
                    )}
                </div>
            </CommonPopup>}
        </div>
    )
}

export default ManageUsers