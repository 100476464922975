import React, { useState } from 'react'
import { CommonFormButton, CommonFormTextInput, CommonFormSelect, CommonTooltip } from '..'
import { IoIosInformationCircleOutline } from "react-icons/io"
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'

const Search = (props) => {
    const defaultFilter = props.defaultFilter
    const [appliedFilters, setAppliedFilters] = useState(props.defaultFilter)
    const [filters, setFilters] = useState(props.defaultFilter)

    async function filtersChanged(k, v) {
        setFilters({ ...filters, [k]: v })
    }

    async function applyFilters() {
        if (!buttonEnable()) return
        const currentFilters = { ...filters }
        if (typeof props.onChange === "function") await props.onChange(currentFilters)
        setAppliedFilters(currentFilters)
    }

    function buttonEnable() {
        return (filters.status !== appliedFilters.status || filters.search !== appliedFilters.search) && (filters.search ? filters.search.length >= 3 : true)
    }

    function showReset() {
        return appliedFilters.search !== defaultFilter.search || appliedFilters.status !== defaultFilter.status
    }

    async function resetFilters() {
        setFilters(defaultFilter)
        document.getElementById("sk-search-open").value = ""

        if (typeof props.onChange === "function") await props.onChange(defaultFilter)
        setAppliedFilters(defaultFilter)
    }

    return (
        <div className="flex flex-col lg:flex-row gap-x-4 gap-y-4">
            <div className="w-full lg:w-1/3 px-0 relative mr-2">
                <CommonFormTextInput id="sk-search-open" placeholder="Search" showCrossIcon={true} onChange={v => filtersChanged("search", v)} value={defaultFilter.search} />
                <div className="absolute z-5 left-[calc(100%_+_2px)] top-0">
                    <CommonTooltip>
                        <IoIosInformationCircleOutline className="text-sk-blue" />
                        <ol className="w-full list-decimal p-2 ml-2 text-left">
                            <li>Minimum 3 characters are required to search.</li>
                            {!["bo_partners", "bo_agent", "bo_manage_users"].includes(props.context) && <li>Search results contain records from last 2 months.</li>}
                            {props.context === "application" && <li>Search using Application ID, Customer (First Name, Last Name, Mobile or Email).</li>}
                            {props.context === "application" && <li>For Application date, use format YYYY-MM-DD.</li>}
                            {props.context === "application" && <li>For Amount in a range, use a hyphen between min and max value like 200000-300000.</li>}
                            {props.context === "insurance" && <li>Search using Insurance Number, Passenger (Name, Name, Mobile or Email) or Policy Number.</li>}
                            {props.context === "insurance" && <li>Search Destination Type using Domestic or International.</li>}
                            {props.context === "insurance" && <li>Search Trip Type using Single or Annual.</li>}
                            {props.context === "insurance" && <li>For Invoice date, use format YYYY-MM-DD.</li>}
                            {props.context === "insurance" && <li>For Invoice Amount in a range, use a hyphen between min and max value like 2000-3000.</li>}
                            {props.context === "wallet" && <li>Search using Transaction ID.</li>}
                            {props.context === "wallet" && <li>For Transaction date, use format YYYY-MM-DD.</li>}
                            {props.context === "wallet" && <li>For Credit or Debit Amount in a range, use a hyphen between min and max value like 2000-3000.</li>}
                            {props.context === "bo_application" && <li>Search using Application ID, PSK ID, Customer (First Name, Last Name, Mobile or Email).</li>}
                            {props.context === "bo_application" && <li>For Application date, use format YYYY-MM-DD.</li>}
                            {props.context === "bo_application" && <li>For Amount in a range, use a hyphen between min and max value like 200000-300000.</li>}
                            {props.context === "bo_application" && <li>For Application Date Range, use colon symbol between two dates like 2024-02-01:2024-03-01. Results are inclusive of both dates.</li>}
                            {props.context === "bo_application" && <li>For Application Source, search using AGENT_PORTAL, ELIGIBILITY_LINK, CREATE_BOOKING and QR_CODE.</li>}
                            {props.context === "bo_insurance" && <li>Search using Insurance Number, PSK ID, Passenger (Name, Mobile or Email) or Policy Number.</li>}
                            {props.context === "bo_insurance" && <li>Search Destination Type using Domestic or International.</li>}
                            {props.context === "bo_insurance" && <li>Search Trip Type using Single or Annual.</li>}
                            {props.context === "bo_insurance" && <li>For Invoice date, use format YYYY-MM-DD.</li>}
                            {props.context === "bo_insurance" && <li>For Invoice Amount in a range, use a hyphen between min and max value like 2000-3000.</li>}
                            {props.context === "bo_insurance" && <li>For Invoice Date Range, use colon symbol between two dates like 2024-02-01:2024-03-01. Results are inclusive of both dates.</li>}
                            {props.context === "bo_partners" && <li>Search using Partner ID, Company Name, Mobile, Email, PAN No., GST No. or Bank Account Number.</li>}
                            {props.context === "bo_agent" && <li>Search using Agent ID, Partner ID, Name, Mobile or Email.</li>}
                            {props.context === "bo_manage_users" && <li>Search using User ID, Name, Mobile or Email.</li>}
                            {props.context === "" && <li></li>}
                        </ol>
                    </CommonTooltip>
                </div>
            </div>
            {!props.hideStatus && <div className="w-full lg:w-1/3">
                <CommonFormSelect id="sk-search-status" placeholder="Select Status" className="w-full" onChange={v => filtersChanged("status", v)} defaultValue={[filters.status, filters.status]} prefix="Status: ">
                    {props.statuses.map(x => <option key={x} value={x}>{x}</option>)}
                </CommonFormSelect>
            </div>}

            <CommonFormButton text="Go" className="min-w-20 px-2" isOutline={!buttonEnable()} short={true} onClick={applyFilters} disabled={!buttonEnable()} />
            {!isMobile && props.showExport && <CommonFormButton text="Export" className="min-w-20 px-2" isOutline={buttonEnable()} short={true} onClick={props.exportData} disabled={buttonEnable()} />}
            {showReset() && <CommonFormButton text="Reset" className="min-w-20 px-2" isOutline={true} short={true} onClick={resetFilters} />}
            <div className="flex-1 hidden lg:visible">

            </div>
        </div>
    )
}

Search.propTypes = {
    hideStatus: PropTypes.bool,
    showExport: PropTypes.bool,
    exportData: PropTypes.func,
    statuses: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    context: PropTypes.string,
}

export default Search