const postProcessing = {
    partnerSortOrder: async function (data, defaultPartnerSortOrder) {
        // create partner sort order
        const partnerSortOrder = []
        for (let x of defaultPartnerSortOrder) {
            if (x in data) partnerSortOrder.push(x)
        }
        return partnerSortOrder
    },
    flightSortOrder: async function (data, type, filters) {
        // find unique flights
        const uniqueFlights = {} // id to cheapest flight
        const uniqueAirlines = {}

        for (let partner in data) {
            for (let id in data[partner]) {
                const flight = data[partner][id]
                if (!(flight.onward.flight_name in uniqueAirlines)) uniqueAirlines[flight.onward.flight_name] = true

                // onward or return
                if (flight.type !== type) continue

                // filters - non stop
                if (filters.popularNonStop === true && (flight.onward.stops > 0 || flight?.return?.stops > 0)) continue

                // filters - flight number
                if (filters.searchFlightNumber && filters.searchFlightNumber.length > 2 && !flight.onward.flight_no.toString().includes(filters.searchFlightNumber) && (flight.return ? !flight.return.flight_no.toString().includes(filters.searchFlightNumber) : true)) continue

                // filters - airline
                if (filters.selectedAirlines?.length > 0 && filters.selectedAirlines.indexOf(flight.onward.flight_name) === -1 && (flight.return ? filters.selectedAirlines.indexOf(flight.return.flight_name) === -1 : true)) continue

                // filters - departure time
                if (filters.preferredDepartureTime && filters.preferredDepartureTime !== "all") {
                    const departure_time = flight.onward.departure.split(" ")[1].replace(":", "")
                    if (filters.preferredDepartureTime === "morning" && (departure_time < "0600" || departure_time > "1200")) continue
                    else if (filters.preferredDepartureTime === "afternoon" && (departure_time < "1200" || departure_time > "1800")) continue
                    else if (filters.preferredDepartureTime === "evening" && (departure_time < "1800")) continue
                    else if (filters.preferredDepartureTime === "night" && (departure_time > "0600")) continue
                }

                // filters - arrival time
                if (filters.preferredArrivalTime && filters.preferredArrivalTime !== "all") {
                    const arrival_time = flight.onward.arrival.split(" ")[1].replace(":", "")
                    if (filters.preferredArrivalTime === "morning" && (arrival_time < "0600" || arrival_time > "1200")) continue
                    else if (filters.preferredArrivalTime === "afternoon" && (arrival_time < "1200" || arrival_time > "1800")) continue
                    else if (filters.preferredArrivalTime === "evening" && (arrival_time < "1800")) continue
                    else if (filters.preferredArrivalTime === "night" && (arrival_time > "0600")) continue
                }

                // filters - duration
                if (filters.minDuration && !isNaN(filters.minDuration) && filters.minDuration * 1 > 0) {
                    if (flight.onward.duration_mins * 1 < filters.minDuration * 1) continue
                }
                if (filters.maxDuration && !isNaN(filters.maxDuration) && filters.maxDuration * 1 > 0) {
                    if (flight.onward.duration_mins * 1 > filters.maxDuration * 1) continue
                }

                if (flight.return) {
                    if (filters.minDuration && !isNaN(filters.minDuration) && filters.minDuration * 1 > 0) {
                        if (flight.return.duration_mins * 1 < filters.minDuration * 1) continue
                    }
                    if (filters.maxDuration && !isNaN(filters.maxDuration) && filters.maxDuration * 1 > 0) {
                        if (flight.return.duration_mins * 1 > filters.maxDuration * 1) continue
                    }
                }

                let fare = flight.fares[0]
                // filters - SME fares
                if (filters.popularSMEFares === true) {
                    fare = flight.fares.filter(x => x.fare_type.toLowerCase().includes("sme"))
                    if (fare.length === 0) continue
                    else fare = fare[0]
                }

                // filters - refundable
                if (filters.popularRefundable === true && flight.fares.every(x => !x.refund_type || x.refund_type === 0)) continue

                if (id in uniqueFlights) {
                    if (fare.customer_fare_raw < uniqueFlights[id]) uniqueFlights[id] = fare.customer_fare_raw
                } else {
                    uniqueFlights[id] = fare.customer_fare_raw
                }
            }
        }

        // create flight sort order
        const flightSortOrder = Object.entries(uniqueFlights).sort((a, b) => a[1] - b[1]).map(x => x[0])

        return { flightSortOrder, uniqueAirlines: Object.keys(uniqueAirlines).sort() }
    }
}

export default postProcessing