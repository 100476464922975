import React, { useState } from 'react'
import { CommonFormSelect, CommonFormTextInput, CommonFormDateInput, CommonFormTextArea, CommonFormButton, CommonPopup } from '../..'
import API from '../../../apis'
import { useNavigate } from 'react-router-dom'
import MixPanel from '../../../config/mixpanel'

const Insurance = () => {
    const [data, setData] = useState({})
    const [alert, setAlert] = useState(null)
    const navigate = useNavigate()

    const updateData = async function (key, value) {
        setData(prev => ({ ...prev, [key]: value }))
    }

    const submit = async function () {
        setAlert(null)
        const response = await API.insurance.groupInsurance(data)
        if (response.status === 200) {
            setAlert("Your group insurance request has been submitted.")
            MixPanel.track(MixPanel.types.agent.insurance.group_enquiry)
        } else {
            setAlert(response.error || "API Failed!")
        }
    }

    return (
        <div className="flex flex-col">
            <h6 className="font-semibold hamburger-spacing">Group Insurance Enquiry</h6>
            <hr className="mt-3 text-gray" />
            <div className="grid grid-cols-6 gap-4 py-4">

                <CommonFormSelect id="sk-ins-group-type" label="Type" className="col-span-full lg:col-span-2" onChange={(v) => updateData("type", v)}>
                    <option value="Single Trip">Single Trip</option>
                    <option value="Annual Multi Trip">Annual Multi Trip</option>
                </CommonFormSelect>

                <CommonFormSelect id="sk-ins-group-destination-type" label="Destination Type" className="col-span-full lg:col-span-2" onChange={(v) => updateData("destination_type", v)}>
                    <option value="Domestic">Domestic</option>
                    <option value="Worldwide with US & Canada">Worldwide with US & Canada</option>
                    <option value="Worldwide without US & Canada">Worldwide without US & Canada</option>
                </CommonFormSelect>

                <CommonFormTextInput className="col-span-full lg:col-span-2" id="sk-ins-group-agent-number" label="Agent Contact No." type="tel" minLength="10" maxLength="10" onChange={(v) => updateData("agency_contact", v)} />

                <CommonFormDateInput id="sk-ins-group-depart-date" label="Departure Date" className="col-span-full lg:col-span-2" onChange={(v) => updateData("departure_date", v)} />

                <CommonFormDateInput id="sk-ins-group-return-date" label="Return Date" className="col-span-full lg:col-span-2" onChange={(v) => updateData("return_date", v)} />

                <CommonFormTextInput id="sk-ins-group-num-pax" type="number" label="Number of Passengers" className="col-span-full lg:col-span-2" onChange={(v) => updateData("num_pax", v)} />

                <CommonFormTextInput id="sk-ins-group-pax-age-0-40" type="number" label="Passengers aged 0-40 years" className="col-span-full lg:col-span-2" onChange={(v) => updateData("num_pax_0_40", v)} />

                <CommonFormTextInput id="sk-ins-group-pax-age-40-60" type="number" label="Passengers aged 40-60 years" className="col-span-full lg:col-span-2" onChange={(v) => updateData("num_pax_40_60", v)} />

                <CommonFormTextInput id="sk-ins-group-pax-age-60-70" type="number" label="Passengers aged 60-70 years" className="col-span-full lg:col-span-2" onChange={(v) => updateData("num_pax_60_70", v)} />

                <CommonFormTextArea id="sk-ins-group-remarks" label="Remarks" className="col-span-full lg:col-span-3 lg:col-start-1" onChange={(v) => updateData("remarks", v)} maxLength="100" numRows="2" />

                <CommonFormButton className="col-span-full lg:col-span-3 lg:col-start-1" text="Submit" onClick={submit} />
                <p className="text-sm col-span-full">A batch of 5 policies to be issued for a group of max 20 adults</p>
            </div>
            {alert && <CommonPopup onOk={() => alert.includes("submitted") ? navigate("/agent/insurance") : setAlert(null)} onCancel={() => alert.includes("submitted") ? navigate("/agent/insurance") : setAlert(null)} icon={alert.includes("submitted") ? "/assets/icons/green-tick.svg" : ""}>
                <div>{alert}</div>
            </CommonPopup>}
        </div>
    )
}

export default Insurance